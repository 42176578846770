import React, { useEffect, useRef, useState } from 'react';
import Top from './top';
import LeftMenu, { TYPES } from './left_menu';
import RightMenu from './right_menu';
import Fabric from './fabric';
import FabricAttr from './fabric/index_attr';
import PatternAttr from './pattern/index_attr';
import LightAttr from './light/index_attr';
import PrintAttr from './print/index_attr';
import Pattern from './pattern';
import Bg from './bg';
import Part from './part';
import BgAttr from './bg/index_attr';
import Client3D from '../../lib/three';
import Context from './context';
import { Spin, Toast } from '@douyinfe/semi-ui';
import http from '../../lib/http';
import * as apis from '../../lib/api';
import OSS from 'ali-oss';
import * as cache from '../../lib/cache';
import * as tool from '../../lib/tool';
import ClientFabric from '../../lib/fabric';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const tempFs = {};

const Index = () => {
  const l = useLocation();
  const params = new URLSearchParams(l.search);
  const fabrciAction = useRef();
  const canvasAction = useRef();
  const printAction = useRef();
  const bgAction = useRef();
  const leftMenuAction = useRef();
  const bgAttrAction = useRef();
  const fabrciAttrAction = useRef();
  const canvasAttrAction = useRef();
  const topAction = useRef();
  const partAction = useRef();
  const lightAction = useRef();
  const [curMenu, setInnerCurMenu] = useState(0);
  const [curEditMode, setCurEditMode] = useState(0);
  const [threeClient, setThreeClient] = useState({});
  const [fabricClient, setFabricClient] = useState({});
  const [action3D, setAction3D] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState('加载中');
  const [curCode, setCurCode] = useState(''); //""是全部
  const [wonsize, setSonsize] = useState(0);
  const [ossClient, setOssClient] = useState();
  const [update, setUpdate] = useState(new Date().getTime());
  const [fabricAttr, setFabricAttr] = useState({});
  const [canvasAttr, setCanvasAttr] = useState({});
  const [lightAttr, setLightAttr] = useState(0);
  const [bgAttr, setBgAttr] = useState({});
  const [itemsAttr, setItemsAttr] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [fclients, setFclients] = useState({});
  const [meshs, setMeshs] = useState([]);
  const [designData, setDesignData] = useState({});
  const [resourceData, setResourceData] = useState({});
  const [controPosition, setControPosition] = useState();
  const [curActivityObject, setCurActivityObject] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [curBrush, setCurBrush] = useState('setPencilBrush');
  const [brushSize, setBrushSize] = useState(5);
  const [curColor, setCurColor] = useState({ r: 0, g: 0, b: 0, a: 1 });
  const [printCutparts, setPrintCutparts] = useState([]);
  const [isVip, setIsVip] = useState(false);
  const [walkVideoUrl, setWalkVideoUrl] = useState('');

  //历史操作步骤
  const setSteps = (stepData) => {
    let steps = cache.get(cache.STEPS) || [];
    let stepNum = cache.getStepNum();
    if (steps.length === 10) {
      steps.splice(0, 1);
      stepNum = 10;
    } else {
      stepNum++;
    }
    cache.set(cache.ATTR_DATA, stepData);
    steps.push(stepData);
    cache.set(cache.STEPS, steps);
    cache.set(cache.STEP_NUM, stepNum);
    setUpdate(new Date().getTime());
  };

  const initUserInfo = async () => {
    http.get(apis.USER_INFO).then((uinfo) => {
      setUserInfo(uinfo);
    });
  };

  //初始化oss
  const initOss = async () => {
    const ossdata = await http.get(apis.ALI_STS);
    const store = new OSS({
      region: process.env.REACT_APP_OSS_REGION,
      bucket: process.env.REACT_APP_OSS_BUCKET,
      accessKeyId: ossdata?.access_key_id,
      accessKeySecret: ossdata?.access_key_secret,
      stsToken: ossdata?.security_token,
      refreshSTSToken: async () => {
        const info = await http.get(apis.ALI_STS);
        return {
          accessKeyId: info?.access_key_id,
          accessKeySecret: info?.access_key_secret,
          stsToken: info?.security_token,
        };
      },
      refreshSTSTokenInterval: 300000,
    });
    setOssClient(store);
  };

  const getCanvasDom = async (code) => {
    let c = document.getElementById(code);
    if (!c) {
      await tool.sleep(100);
      return await getCanvasDom(code);
    }
    return c;
  };

  const startListionMap = async (code, fl = fabricClient, a3 = action3D) => {
    if (tempFs[code]) {
      let fclient = tempFs[code];
      if (curMenu === TYPES.DRAW) {
        fclient[curBrush](
          brushSize,
          `rgba(${curColor.r},${curColor.g},${curColor.b},${curColor.a})`
        );
      } else {
        fclient.cancelBrush();
      }
      return tempFs;
    }
    let cdom = await getCanvasDom(code);
    //console.log("document.getElementById(code)",cdom)
    const fclient = fl.loadCanvas(code);
    tempFs[code] = fclient;

    const tclient = a3.setMapCanvasTextureByName(code, cdom);
    //开启所有2D与3D同步监听
    fclient?.onCanvas((e, controPosition, event) => {
      if (event === 'after:render') {
        const acObj = fclient.getFabricCanvas().getActiveObject();
        if (acObj) {
          setCurActivityObject(acObj);
        } else {
          setCurActivityObject(null);
        }
        tclient.needsUpdate();
        setControPosition(controPosition);
      }
    });
    if (curMenu === TYPES.DRAW) {
      fclient[curBrush](
        brushSize,
        `rgba(${curColor.r},${curColor.g},${curColor.b},${curColor.a})`
      );
    } else {
      fclient.cancelBrush();
    }
    return tempFs;
  };

  const canvasRef = useRef();
  useEffect(() => {
    setIsVip(params.get('isVip') === '1');
    initOss();
    initUserInfo();
    //初始化fabricClient
    const fabricClient = new ClientFabric();
    setFabricClient(fabricClient);

    let client;
    setLoadingTxt('加载中');
    setLoading(true);

    http
      .get(`${apis.DESIGN_DETAIL}?id=${params.get('id')}`)
      .then(async (data) => {
        //初始化3DClient
        client = new Client3D({
          canvasDom: canvasRef?.current,
          canvasWidth: document.body.clientWidth,
          canvasHeight: document.body.clientHeight
        });
        setThreeClient(client);
        client.start();

        //如果有设计文件，优先使用设计文件模型
        let resource = {};
        if (data.path) {
          try {
            resource = await axios.get(
              process.env.REACT_APP_OSS_URL + data.path
            );
            resource = resource.data;
            data.model_url = resource.model_url;
          } catch (error) {
            Toast.error('加载资源文件出错');
            return;
          }
        }
        setDesignData(data);
        setResourceData(resource);
        let loadUrl = process.env.REACT_APP_OSS_URL + data.model_url
        if (data.FashionShowList) {
          // 查询是否有走秀数据
          data.FashionShowList.forEach(item => {
            if (item.resource.indexOf('.fbx') > -1) {
              loadUrl = process.env.REACT_APP_OSS_URL + item.resource
            }
            if (item.resource.indexOf('.mp4') > -1) {
              setWalkVideoUrl(process.env.REACT_APP_OSS_URL + item.resource)
            }
          })
        }
        let action3D = await client.loadModel(
          loadUrl,
          (p) => {},
          data.character_model_url
            ? process.env.REACT_APP_OSS_URL + data.character_model_url
            : '',
          data.character_maps_url
            ? process.env.REACT_APP_OSS_URL + data.character_maps_url
            : '',
        );
        setAction3D(action3D);

        //初始化所有fabrci句柄和3D句柄
        let codes = [];
        for (let i = 0; i < client?.modelObject?.children?.length; i++) {
          codes.push(client?.modelObject?.children[i].name);
        }
        codes = tool.filterCodes(data.model_items);
        client.setRenderCodes(codes);
        setMeshs(codes);
        // await tool.sleep(300)

        // for(let c=0;c<codes.length;c++){
        //     client.setModelMap(codes[c])
        // }

        // if(resource.camera){
        //     client.setCamera(resource.camera.x,resource.camera.y,resource.camera.z)
        // }

        // console.log("resource?.fabricAttr",resource?.fabricAttr)
        //如果没有面料，但有模型贴图
        if (!resource.fabricAttr && data.model_items) {
          resource.fabricAttr = {};
          for (let i = 0; i < data.model_items.length; i++) {
            let item = data.model_items[i];
            resource.fabricAttr[item.code] = {
              maps: item.maps,
              repeat: false,
              repeatNum: 1,
              repeatNumY: 1,
              transparent: false,
            };
          }
        }

        setLoadingTxt('加载设计中');
        let initStep = {};
        //清洗fabricAttr
        let fr = fabrciAction.current?.fullData(
          resource?.fabricAttr || {},
          codes
        );
        setFabricAttr(fr);
        await fabrciAction.current?.loader(fr);
        initStep.fabric = fr;
        //加载canvas数据
        resource.canvasAttr = resource?.canvasAttr || {
          canvas_width: 512,
          canvas_data: [],
        };
        setCanvasAttr(resource?.canvasAttr);

        for (let i = 0; i < resource.canvasAttr.canvas_data.length; i++) {
          let item = resource.canvasAttr.canvas_data[i];
          if (codes.find((s) => s === item.code)) {
            // console.log(item.code)
            await startListionMap(item.code, fabricClient, action3D);
          }
        }

        //setFclients(tempFs)
        setUpdate(new Date());
        // await tool.sleep(300)

        await canvasAction.current?.loader(resource?.canvasAttr, tempFs);
        initStep.pattern = resource?.canvasAttr;
        //加载背景数据
        let bgFullData = bgAction.current?.fullData(resource?.bgAttr || {});
        setBgAttr(bgFullData);
        await bgAction.current?.loader(bgFullData);
        initStep.bg = bgFullData;

        //加载部件数据
        let partFullData = partAction.current?.fullData(
          resource?.itemsAttr || {},
          codes
        );
        setItemsAttr(partFullData);

        await partAction.current?.loader(partFullData);
        initStep.itemsAttr = partFullData;

        //填充打印数据
        if (data.product_materials) {
          await printAction.current?.loader(JSON.parse(data.product_materials));
        }

        //添加阴影
        client.addShadow(client.modelObject.position.y);

        //加载灯光数据
        let lightInitAttrData = lightAction.current.fullData({ lightType: data.light_type })
        setLightAttr(lightInitAttrData)
        lightAction.current.loader({ lightType: data.light_type })

        setLoading(false);
        setSteps(initStep);

        //延时一下
        setTimeout(() => {
          setCurMenu(TYPES.FABRIC);
        }, 100);
      });

    const onresize = () => {
      client.setCanvasSize(
        document.body.clientWidth,
        document.body.clientHeight
      );
      setSonsize(new Date().getTime());
    };
    window.addEventListener('resize', onresize);

    return () => {
      client?.destroy();
      fabricClient?.destroy();
      cache.clearAllCache();
      window.removeEventListener('resize', onresize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuContentClose = (key) => {
    switch (key) {
      case TYPES.FABRIC:
        threeClient.setCanvasSize(
          document.body.clientWidth,
          document.body.clientHeight
        );
        break;
      default:
        break;
    }
    setInnerCurMenu(0);
    setCurEditMode(0);
  };

  const setCurMenu = (key) => {
    switch (key) {
      case TYPES.FABRIC:
        // if(key){
        //     threeClient.setCanvasSize(document.body.clientWidth - MenuPaddingLeft - MenuWidth, document.body.clientHeight - 60)
        // }
        break;
      default:
        break;
    }
    setInnerCurMenu(key);
    setCurEditMode(0);
  };

  const choiceItem = async (e) => {
    if (threeClient.outlinePass.selectedObjects.length !== 0) {
      let code = threeClient.outlinePass.selectedObjects[0].name;
      if (!meshs?.find((c) => c === code)) {
        return;
      }
      const fs = startListionMap(code);
      fs[code]?.discardActiveObject();
      setCurCode(code);
      setFclients(fs);

      setCurActivityObject(null);

      if (curMenu === TYPES.PRINT) {
        let cpValues = printAction.current?.getFormApi().getValues();
        cpValues.cutparts = cpValues.cutparts || [];
        let f = cpValues?.cutparts?.find((v) => v.code === code);
        setTimeout(() => {
          // document.getElementById("aabb").screenTop = 0
          window.location.href = `#print${code}`;
        }, 500);
        if (f) {
          return;
        }
        cpValues?.cutparts?.push({
          img: '',
          txt: '',
          code: code,
          price: undefined,
        });
        printAction.current?.loader(cpValues);
      }
    }
  };

  return (
    <Context.Provider
      value={{
        setCurMenu,
        curMenu,
        onMenuContentClose,
        curEditMode,
        setCurEditMode,
        threeClient,
        action3D,
        setLoadingTxt,
        setLoading,
        setCurCode,
        curCode,
        fabricAttr,
        setFabricAttr,
        setCanvasAttr,
        canvasAttr,
        wonsize,
        ossClient,
        update,
        setUpdate,
        setSteps,
        fclients: tempFs,
        controPosition,
        curActivityObject,
        fabricLoader: fabrciAction?.current?.loader,
        canvasLoader: canvasAction?.current?.loader,
        bgLoader: bgAction?.current?.loader,
        partLoader: partAction?.current?.loader,
        slideData: topAction?.current?.slideData,
        meshs,
        fabricClient,
        bgAttr,
        fabrciApi: fabrciAction?.current,
        setBgAttr,
        canvasAttrApi: canvasAttrAction?.current,
        fabrciAttrApi: fabrciAttrAction?.current,
        bgAttrApi: bgAttrAction?.current,
        partAttrApi: partAction?.current,
        leftMenuApi: leftMenuAction?.current,
        params,
        designData,
        resourceData,
        itemsAttr,
        setItemsAttr,
        userInfo,
        lightAttr,
        setLightAttr,
        setCurBrush,
        curBrush,
        brushSize,
        setBrushSize,
        curColor,
        setCurColor,
        printCutparts,
        setPrintCutparts,
        printApi: printAction?.current,
        walkVideoUrl
      }}
    >
      <Spin spinning={loading} tip={loadingTxt}>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
            fontWeight: 500,
          }}
        >
          <div
            style={{
              display: tool.isFullScreen() ? 'block' : 'none',
              position: 'fixed',
              width: 50,
              height: 50,
              background: '#cccccc',
              borderRadius: '50%',
              textAlign: 'center',
              lineHeight: '50px',
              color: '#FFFFFF',
              left: 0,
              right: 0,
              margin: 'auto',
              top: 50,
            }}
            onClick={() => tool.exitFullscreen()}
          >
            退出
          </div>
          <span style={{ display: tool.isFullScreen() ? 'none' : 'flex' }}>
            <Top isVip={isVip} action={topAction} />
            <LeftMenu isVip={isVip} action={leftMenuAction} />
            <RightMenu />
            <Bg isVip={isVip} action={bgAction} />
            <Part isVip={isVip} action={partAction} />
            <BgAttr action={bgAttrAction} />
            <Pattern isVip={isVip} action={canvasAction} />
            <PatternAttr action={canvasAttrAction} />
            <Fabric isVip={isVip} action={fabrciAction} />
            <FabricAttr isVip={isVip} action={fabrciAttrAction} />
            <LightAttr action={lightAction} />
            <PrintAttr action={printAction} />
          </span>
          <div onPointerUp={choiceItem}>
            <canvas className="displayAnimate" ref={canvasRef}></canvas>
          </div>
        </div>
      </Spin>
    </Context.Provider>
  );
};

export default Index;
