import styles from './index.module.css'
import { Input, Form,Empty, Divider, InputNumber,Button,Slider, Switch } from '@douyinfe/semi-ui';
import { IconCrossStroked, IconTick } from '@douyinfe/semi-icons';
import { IllustrationNoContent, IllustrationNoContentDark } from '@douyinfe/semi-illustrations';
import Animate from '../../../components/animate'
import C from '../context'
import { useContext, useEffect, useImperativeHandle, useRef } from 'react';
import {TYPES} from '../left_menu'
import Codes from '../codes'
import { cloneDeep } from 'lodash'
import { SketchPicker } from 'react-color';
import * as cache from '../../../lib/cache'
import {EDIT_MODE} from '../defined'
import md5 from 'md5';
import PrintPrice from '../../../components/print_price'

let firstLoad = false
const offsetNum = 26

const Index = (props) => {
    const ic = useContext(C)
    const attrDiv = useRef()
    const formApiRef = useRef()

    const loader = async (attr) => {
        formApiRef?.current?.setValues(attr)
    }

    // const confirm = async () => {
    //     try {
    //         let values = await formApiRef.current.validate()
    //         console.log(values)
    //     } catch (error) {
            
    //     }
    // }

    useImperativeHandle(props.action, () => ({
        loader,
        getFormApi: () => {
            return formApiRef?.current
        }
    })); 

    return (
        <Animate showType="flex" show={ic.curMenu === TYPES.PRINT} style={{
            position: "fixed",
            width: 213,
            right: 50,
            top: 60,
            bottom: 0,
            margin: "auto",
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <div style={{height: "96%",overflow:"hidden"}}>
                <div style={{
                    color: "#333333",
                    fontSize: 14,
                    padding: "5px 8px",
                    display:"flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <div>生产定价</div>
                    {/* <Button style={{height: 20, width: 20}} onClick={confirm} icon={<IconTick style={{fontSize: 12}} />}></Button>  */}
                </div>
                <div style={{
                    color: "#333333",
                    fontSize: 14,
                    padding: "5px 8px",
                    display:"flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <div style={{fontSize: 12, color: "#cccccc"}}>请在模型上点击选择版片</div>
                    <div style={{height: 12}}></div>
                </div>
                <div style={{
                    padding: "12px 8px",
                    overflow: "auto",
                    // height: "calc(50% - 26px - 60px)"
                }}>
                    <div className="hideScroll" style={{
                        height: document.body.clientHeight - (attrDiv?.current?.clientHeight || 0) - document.body.clientHeight*0.04 - 130,
                        overflow: "auto"
                    }}>
                        {
                            <>  
                                <div>
                                    <Form getFormApi={(formApi) => formApiRef.current = formApi}>
                                        {({ formState, values, formApi }) => (
                                            <>
                                                <Form.Input 
                                                    field='base_price' 
                                                    label="基础定价"
                                                    style={{ width: '100%' }} 
                                                    placeholder='请输入'
                                                    type="number"
                                                    suffix={"元"}
                                                    min={0.01}
                                                    rules={[
                                                        { required: true, message: '请输入' }
                                                    ]}
                                                />
                                                <PrintPrice
                                                    field='cutparts'
                                                    label='部位列表'
                                                    curCode={ic?.curCode}
                                                    threeClient={ic?.threeClient}
                                                    rules={[
                                                        { required: true, message: '请添加版片' }
                                                    ]}
                                                />
                                            </>
                                        )} 
                                    </Form>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Animate>
    )
}

export default Index