import http from '../../lib/http'
import * as apis from '../../lib/api'
import { useEffect, useRef, useState } from 'react'
import {Typography } from '@douyinfe/semi-ui';
import { cloneDeep } from 'lodash'
import * as tool from '../../lib/tool'
import PullList from '../../components/pull_list'
import Top from '../../components/top'
import { useNavigate } from "react-router-dom";
const { Title } = Typography;
const Index = () => {
    const n = useNavigate()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState({
        page: 1,
        page_size:20,
        category_id: -1,
        second_category_id:0,
        three_category_id:0,
        status: 1
    })
    const [amount, setamount] = useState({
        day_new_public_model_amount: 0 ,
        day_new_private_model_amount: 0,
        day_new_public_fabric_amount: 0,
        day_new_private_fabric_amount: 0
    })
    const [designRet, setDesignRet] = useState({list: []})
    const colorStyle = {
        color: '#FFFFFF',
        textAlign:'right'
    };
    useEffect(()=>{
        loadData(search, true)
        getAmount()
    },[])

    const loadData = async (s, refresh = false) => {
        try {
            let ret = await http.post(apis.DESIGN_VIP_LIST,s)
            if(refresh){
                setDesignRet(ret)
            }else{
                let cpData = cloneDeep(designRet)
                cpData.list = [...cpData.list, ...ret.list]
                setDesignRet(cpData)
            }
        } catch (error) {
            
        }
    }
    const getAmount = async () => {
        try {
            const data = await http.post(apis.COCKPIT_SHOWDATA);
            setamount(data)
        } catch (error) {

        }
    };
    const loadMore = async () => {
        let cpSearch = cloneDeep(search)
        cpSearch.page++
        setSearch(cpSearch)
        setLoading(true)
        await loadData(cpSearch, false)
        setLoading(false)
    }

    return (
        <div style={{padding: "0 24px",background:'#535061'}}>
            <div style={{display: "flex",alignItems: "center"}}>
                <div style={{marginRight: 24, flexShrink: 0, cursor: "pointer"}} onClick={() => window.history.back()}>
                    <div style={{textAlign: "center",color:"#fff"}}>
                        <i className="iconfont">&#xe6ff;</i>
                        <div>返回</div>
                    </div>
                </div>
                <Top isVip hideUser listApi={apis.DESIGN_CATEGORY_LIST} inputSearch = {async (v) => {
                    tool.processor.process(async ()=>{
                        let cpSearch = cloneDeep(search)
                        cpSearch.name = v
                        cpSearch.page = 1
                        setSearch(cpSearch)
                        setLoading(true)
                        await loadData(cpSearch, true)
                        setLoading(false)
                    })
                }} onSearch={async (s) => {
                    let cpSearch = cloneDeep(search)
                    let n = Object.assign(cpSearch, s)
                    setSearch(n)
                    await loadData(n, true)
                }} />
            </div>
            <div>
            <Title heading={2} style={colorStyle}> {`今日私库新增款式${amount.day_new_private_model_amount},新增面料${amount.day_new_private_fabric_amount}`}</Title>
                {
                    designRet.total === 0 ? <div style={{textAlign: "center"}}>暂无数据!~</div>:<PullList isVip
                        total={designRet.total} 
                        loading={loading} 
                        loadMore={loadMore} 
                        height={document.body.clientHeight - 80} 
                        data={designRet?.list}
                        showDownload
                        onItemClieck={(item)=>{
                            // n(`/design?id=${item.id}`)
                            window.location.href = `/design?id=${item.id}&isVip=1`
                        }}
                    />
                }
                
            </div>
        </div>
    )
}

export default Index