import styles from './index.module.css'
import { Input, Empty, Divider, InputNumber,Button,Slider, Switch } from '@douyinfe/semi-ui';
import { IconCrossStroked, IconTick } from '@douyinfe/semi-icons';
import { IllustrationNoContent, IllustrationNoContentDark } from '@douyinfe/semi-illustrations';
import Animate from '../../../components/animate'
import C from '../context'
import { useContext, useEffect, useImperativeHandle, useRef } from 'react';
import {TYPES} from '../left_menu'
import Codes from '../codes'
import { cloneDeep } from 'lodash'
import { SketchPicker } from 'react-color';
import * as cache from '../../../lib/cache'
import {EDIT_MODE} from '../defined'
import md5 from 'md5';
import * as tool from '../../../lib/tool'

let firstLoad = false

const Index = (props) => {
    const ic = useContext(C)
    const attrDiv = useRef()

    const setDisplacementScale = (v) => {
        if(!ic.curCode){
            const fabricAttr = cloneDeep(ic.fabricAttr || {})
            let cs = ic?.meshs
            for(let i=0;i<cs.length;i++){
                let item = cs[i]
                fabricAttr[item].displacementScale = v
                ic.threeClient.setDisplacementMapAttr(item, v, ic?.fabricAttr[item]?.displacementBias)
            }
            fabricAttr[""].displacementScale = v
            ic.setFabricAttr(fabricAttr)
        }else{
            ic.threeClient.setDisplacementMapAttr(ic.curCode, v, ic?.fabricAttr[ic.curCode]?.displacementBias)
            setFabricAttr("displacementScale",v)
        }
    }

    const setDisplacementBias = (v) => {
        if(!ic.curCode){
            const fabricAttr = cloneDeep(ic.fabricAttr || {})
            let cs = ic?.meshs
            for(let i=0;i<cs.length;i++){
                let item = cs[i]
                ic.threeClient.setDisplacementMapAttr(item, ic?.fabricAttr[item]?.displacementScale,v)
                fabricAttr[item].displacementBias = v
            }
            fabricAttr[""].displacementBias = v
            ic.setFabricAttr(fabricAttr)
        }else{
            ic.threeClient.setDisplacementMapAttr(ic.curCode, ic?.fabricAttr[ic.curCode]?.displacementScale, v)
            setFabricAttr("displacementBias",v)
        }
    }

    const setMapOffsetX = (v) => {
        if(!ic.curCode){
            const fabricAttr = cloneDeep(ic.fabricAttr || {})
            let cs = ic?.meshs
            for(let i=0;i<cs.length;i++){
                let item = cs
                fabricAttr[item].offsetX = v
                ic.threeClient.setMapOffset(item, v, ic?.fabricAttr[item]?.offsetY || 0)
            }
            fabricAttr[""].offsetX = v
            ic.setFabricAttr(fabricAttr)
        }else{
            ic.threeClient.setMapOffset(ic.curCode, v, ic?.fabricAttr[ic.curCode]?.offsetY || 0)
            setFabricAttr("offsetX",v)
        }
    }

    const setMapOffsetY = (v) => {
        if(!ic.curCode){
            const fabricAttr = cloneDeep(ic.fabricAttr || {})
            let cs = ic?.meshs
            for(let i=0;i<cs.length;i++){
                let item = cs[i]
                ic.threeClient.setMapOffset(item, ic?.fabricAttr[item]?.offsetX || 0,v)
                fabricAttr[item].offsetY = v
            }
            fabricAttr[""].offsetY = v
            ic.setFabricAttr(fabricAttr)
        }else{
            ic.threeClient.setMapOffset(ic.curCode, ic?.fabricAttr[ic.curCode]?.offsetX || 0, v)
            setFabricAttr("offsetY",v)
        }
    }
    
    const setFabricAttr = (key,v) => {
        const fabricAttr = cloneDeep(ic.fabricAttr || {})
        if(!fabricAttr[ic.curCode]){
            fabricAttr[ic.curCode] = {}
        }
        fabricAttr[ic.curCode][key] = v
        ic.setFabricAttr(fabricAttr)
    }


    const editDisabled = () => {
        try {
            return !ic?.threeClient?.hasScene()
        } catch (error) {
            return true
        }
    }

    const confirm = () => {
        let attrData = cloneDeep(cache.get(cache.ATTR_DATA))
        if(md5(JSON.stringify(attrData.bg)) !== md5(JSON.stringify(ic.bgAttr))){
            attrData.bg = ic.bgAttr
            ic.setSteps(attrData)
        }
        ic.onMenuContentClose(TYPES.BAG)
    }

    useImperativeHandle(props.action, () => ({
        confirm,
    }));

    const cancel = async (e) => {
        e.preventDefault()
        let attrData = cache.get(cache.ATTR_DATA)
        if(md5(JSON.stringify(attrData.bg)) !== md5(JSON.stringify(ic.bgAttr))){
            ic.setLoading(true)
            ic.setLoadingTxt("加载中...")
            ic.setBgAttr(attrData.bg)
            await ic.bgLoader(attrData.bg)
            ic.setLoading(false)
        }
        ic.onMenuContentClose(TYPES.BAG)
    }

    return (
        <Animate showType="flex" show={false} style={{
            position: "fixed",
            width: 213,
            right: 50,
            top: 60,
            bottom: 0,
            margin: "auto",
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <div style={{height: "96%",overflow:"hidden"}}>
                <div style={{
                    color: "#333333",
                    fontSize: 14,
                    padding: "5px 8px",
                    display:"flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Button style={{height: 20, width: 20}} onClick={cancel} icon={<IconCrossStroked style={{fontSize: 12}} />}></Button>
                    <div>场景调节</div>
                    <Button style={{height: 20, width: 20}} onClick={confirm} icon={<IconTick style={{fontSize: 12}} />}></Button> 
                </div>
                <div style={{
                    padding: "12px 8px",
                    overflow: "auto",
                    // height: "calc(50% - 26px - 60px)"
                }}>
                    <div className='hideScroll' style={{
                        height: document.body.clientHeight - (attrDiv?.current?.clientHeight || 0) - document.body.clientHeight*0.04 - 190,
                        overflow: "auto"
                    }}>
                        {
                            !editDisabled() ? <>
                                <div>
                                    <div style={{fontSize: 12}}>深度：</div>
                                    <div>
                                        <Slider value={ic?.bgAttr?.cameraFov} step={1} min={1} max={200} onChange={(v) => {
                                            let bgAttr = cloneDeep(ic?.bgAttr)
                                            bgAttr.cameraFov = v
                                            ic.setBgAttr(bgAttr)
                                        }} onAfterChange={(v)=>{
                                            let attr = ic.bgAttr
                                            tool.processor.process(() => {
                                                ic.bgLoader(attr)
                                            })
                                        }} />
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: 12}}>放大倍数：</div>
                                    <div>
                                        <Slider value={ic?.bgAttr?.cameraZoom} step={0.1} min={0.1} max={20} onChange={(v) => {
                                            let bgAttr = cloneDeep(ic?.bgAttr)
                                            bgAttr.cameraZoom = v
                                            ic.setBgAttr(bgAttr)
                                        }} onAfterChange={(v)=>{
                                            let attr = ic.bgAttr
                                            tool.processor.process(() => {
                                                ic.bgLoader(attr)
                                            })
                                        }} />
                                    </div>
                                </div>
                            </>:<div style={{textAlign: "center", fontSize: 12, paddingTop: 20}}>先在左选择背景</div>
                        }
                    </div>

                    
                </div>
            </div>
        </Animate>
    )
}

export default Index