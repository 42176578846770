import styles from './index.module.css';
import C from '../context';
import { useContext, useRef } from 'react';
import http from '../../../lib/http';
import * as apis from '../../../lib/api';
import md5 from 'md5';
import { Toast } from '@douyinfe/semi-ui';
import axios from 'axios';
import * as tool from '../../../lib/tool';
import { Modal } from '@douyinfe/semi-ui';

export const TYPES = {
  FABRIC: 1,
  PATTERN: 2,
  DRAW: 3,
  BAG: 4,
  PART: 5,
};

const MENUS = [
  {
    icon: '&#xe656;',
    name: '视频',
    key: 1,
  },
  // {
  //     icon: "&#xe600;",
  //     name: "分享",
  //     key: 2
  // },
  // {
  //     icon: "&#xe738;",
  //     name: "下单",
  //     key: 3
  // }
];

const Index = (props) => {
  const ic = useContext(C);

  const getFontSize = (v) => {
    if (v.key === TYPES.FABRIC) {
      return 19;
    }
    if (v.key === TYPES.PART) {
      return 38;
    }
    return 22;
  };

  const loopTransVideoSuccess = async (path) => {
    ic.setLoadingTxt('转码中，请耐心等待...');
    await tool.sleep(1000);
    try {
      return await axios.get(path, { responseType: 'blob' });
    } catch (error) {
      return await loopTransVideoSuccess(path);
    }
  };

  const choiceMenu = async (e, v) => {
    e.preventDefault();
    if (v.key === 1) {
      if (!window.MediaRecorder.isTypeSupported('video/webm')) {
        Toast.info('抱歉，该设备不支持录制视频');
        return;
      }
      ic.setLoadingTxt('视频录制中，不要关闭页面');
      ic.setLoading(true);
      // if(ic?.resourceData?.camera){
      //     ic.threeClient.setCamera(ic?.resourceData?.camera.x,ic?.resourceData?.camera.y,ic?.resourceData?.camera.z)
      // }else{
      //     ic.threeClient.setContentCenter(ic.threeClient.modelObject)
      // }
      ic.threeClient.rotation(true);

      // 设置mediarecoder的参数，这里的codec为H264
      var track = {
        mimeType: 'video/webm;codecs=vp9',
        videoBitsPerSecond: 8000000,
      };
      // 创建一个mediarecorder对象
      var mediaRecorder = new MediaRecorder(
        ic.threeClient.getCanvasDom().captureStream(),
        track
      );
      // 事件录制开始
      mediaRecorder.ondataavailable = async function (e) {
        if (e.data && e.data.size > 0) {
          ic.setLoadingTxt('正在上传视频中，不要关闭页面');
          const uuid = await http.get(apis.UUID);
          const filename = `${md5(uuid)}.webm`;
          const data = await ic.ossClient.put(
            process.env.REACT_APP_OSS_BUCKET_DIR + '/' + filename,
            e.data
          );
          if (!data.name) {
            Toast.error('生成失败，请重试');
            ic.setLoading(false);
          }
          let param = {
            input_path: data.name,
            out_path: `${process.env.REACT_APP_OSS_BUCKET_DIR}/${md5(
              uuid
            )}.mp4`,
          };
          //转码
          try {
            await http.post(
              `${apis.VIDEO_TRANS}?input_path=${param.input_path}&out_path=${param.out_path}`
            );
          } catch (error) {
            Toast.error('生成失败，请重试');
            ic.setLoading(false);
          }
          let videoRet = await loopTransVideoSuccess(
            process.env.REACT_APP_OSS_URL + param.out_path
          );

          Modal.success({
            bodyStyle: { marginLeft: 0 },
            title: '视频生成成功',
            okText: '下载',
            cancelText: '复制链接',
            content: (
              <div>
                <div>
                  <video
                    controls
                    style={{ width: '100%' }}
                    src={process.env.REACT_APP_OSS_URL + param.out_path}
                  ></video>
                </div>
              </div>
            ),
            onCancel: () => {
              tool.copyToClip(process.env.REACT_APP_OSS_URL + param.out_path);
              Toast.success('复制成功');
            },
            onOk: async () => {
              tool.saveAs(videoRet.data, `小猫耳${new Date()}.mp4`);
            },
          });
          ic.setLoading(false);
        }
      };
      //调用start函数开始录制
      mediaRecorder.start();
      //5s之后停止录制
      setTimeout(function () {
        ic.threeClient.rotation(false);
        mediaRecorder.stop();
      }, 12000);
    }
  };

  return (
    <div className={styles.box}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '96%',
          justifyContent: 'flex-start',
          display: props?.display === 'none' ? 'none' : 'block'
        }}
      >
        <div style={{ height: '2%' }}></div>
        <div>
          {MENUS.map((v) => (
            <div
              key={v.key}
              className={styles.boxItem}
              style={{ marginBottom: 5 }}
              onClick={(e) => choiceMenu(e, v)}
            >
              <div className={styles.boxItemIconBox}>
                <i
                  style={{ fontSize: getFontSize(v) }}
                  className="iconfont"
                  dangerouslySetInnerHTML={{ __html: v.icon }}
                ></i>
              </div>
              <div style={{ fontSize: 12 }}>{v.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
