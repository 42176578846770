import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './router/index';
import 'cropperjs/dist/cropper.css';
import reportWebVitals from './reportWebVitals';
// document.body.setAttribute('theme-mode', 'dark');
// window.setMode('dark');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
document.addEventListener('contextmenu', function (e) {
  e.preventDefault(); // 阻止默认的右键菜单
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
