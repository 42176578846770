import styles from './index.module.css'
import { Input, Empty, Divider, InputNumber,TabPane,Slider, Toast, Button, Select } from '@douyinfe/semi-ui';
import { IconCrossStroked, IconTick } from '@douyinfe/semi-icons';
import { IllustrationNoContent, IllustrationNoContentDark } from '@douyinfe/semi-illustrations';
import Animate from '../../../components/animate'
import C from '../context'
import { useContext, useEffect, useState, useRef, useImperativeHandle } from 'react';
import {TYPES} from '../left_menu'
import Codes from '../codes'
import { cloneDeep } from 'lodash'
import { SketchPicker } from 'react-color';
import * as cache from '../../../lib/cache'
import {EDIT_MODE} from '../defined'
import patternClose from '../../../static/img/pattern_close2.png'
import patternScale from '../../../static/img/pattern_scale2.png'
import patternRote from '../../../static/img/rote.png'
import HuakuangUp from '../../../static/img/huakuang_up.png'
import HuakuangDown from '../../../static/img/huhuang_down.png'
import Brush1 from '../../../static/img/brush1.png'
import Brush2 from '../../../static/img/brush2.png'
import Brush3 from '../../../static/img/brush3.png'
import * as tool from '../../../lib/tool';
import md5 from 'md5';
import loadFont from '../../../lib/font'
import { json } from 'react-router-dom';

let firstLoad = false

const fontList = [
    {
        "name":"默认字体",
        "fontFamily": "Times New Roman"
    },
    {
        "name":"汉仪超粗",
        "fontFamily": "font48",
        "url": "files/0250f4243a4677398cee66c6e8be28df.ttf"
    }
]

const brushs = [
    {
        key: "setPencilBrush",
        name: "铅笔",
        img: Brush1
    },
    {
        key: "setCircleBrush",
        name: "圆圈",
        img: Brush2
    },
    {
        key: "setSprayBrush",
        name: "喷雾",
        img: Brush3
    },
    {
        key: "setErase",
        name: "橡皮",
        // img: Brush3
    }
]

const Index = (props) => {
    const ic = useContext(C)
    const attrDiv = useRef()
    const [fontFamily ,setFontFamily] = useState("Times New Roman")
 
    
    const [curTxt, setCutTxt] = useState("")
    
    
    const confirm = () => {
        let attrData = cloneDeep(cache.get(cache.ATTR_DATA))
        if(md5(JSON.stringify(attrData.pattern.canvas_data || [])) !== md5(JSON.stringify(ic.fabricClient.allToJson()))){
            attrData.pattern.canvas_data = ic.fabricClient.allToJson()
            ic.setSteps(attrData)
            ic.setCanvasAttr(attrData.pattern)
        } 
        ic.onMenuContentClose(TYPES.PATTERN)
    }

    const cancel = async (e) => {
        e.preventDefault()
        let attrData = cache.get(cache.ATTR_DATA)
        ic.setLoading(true)
        ic.setLoadingTxt("加载中...")
        //回退数据
        ic.setCanvasAttr(attrData.pattern)
        await ic.canvasLoader(attrData.pattern)
        ic.setLoading(false)
        ic.onMenuContentClose(TYPES.PATTERN)
    }

    //删除纹样
    const delPattern = () => {
        const obj = ic.fclients[ic.curCode].getFabricCanvas().getActiveObject()
        if (obj) {
            ic.fclients[ic.curCode]?.getFabricCanvas().remove(obj)
            ic.fclients[ic.curCode]?.getFabricCanvas().renderAll()
        }
    }

    const txtChange = (v) => {
        setCutTxt(v)
    }

    const addTxt = () => {
        const canvas =  ic.fclients[ic.curCode]
        const txtBox = canvas.addText(curTxt, fontFamily, `rgba(${ic.curColor.r},${ic.curColor.g},${ic.curColor.b},${ic.curColor.a})`)
        if(fontFamily !== "Times New Roman"){
            txtBox.set("font_family_url", process.env.REACT_APP_OSS_URL + fontList.find(j => j.fontFamily === fontFamily).url)
        }
    }

    // useEffect(()=>{
    //     console.log(ic?.curActivityObject?.type)
    // },[ic.curActivityObject])

    //选择字体
    const choiceFont = async (v) => {
        ic.setLoadingTxt("加载字体中")
        ic.setLoading(true)
        try {
            if(v !== "Times New Roman"){
                await loadFont(v, process.env.REACT_APP_OSS_URL + fontList.find(j => j.fontFamily === v).url)
            }
            if(ic?.curActivityObject?.type === "textbox"){
                ic.curActivityObject.fontFamily = v
                ic.fclients[ic.curCode].getFabricCanvas().renderAll()
                if(v !== "Times New Roman"){
                    ic.curActivityObject.set("font_family_url", process.env.REACT_APP_OSS_URL + fontList.find(j => j.fontFamily === v).url)
                }
            }
            setFontFamily(v)
        } catch (error) {
            Toast.error("字体加载失败")
        }
        ic.setLoading(false)
        // previewTextObject.fontFamily = `font${v.id}`
        // previewTextObject.set("font_family_url", process.env.REACT_APP_OSS_URL + v.path)
        // tc.curCanvasClient.getFabricCanvas().renderAll()
    }

    const choiceBrush = (v) => {
        ic?.setCurBrush(v)
        ic.fclients[ic.curCode][v](ic?.brushSize, `rgba(${ic.curColor.r},${ic.curColor.g},${ic.curColor.b},${ic.curColor.a})`)
    }

    const colorChange = (e) => {
        if(ic?.curActivityObject?.type === "textbox"){
            ic.curActivityObject.set({ fill: `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})` })
            ic.fclients[ic.curCode].getFabricCanvas().renderAll()
        }
        if(ic.curMenu === TYPES.DRAW){
            ic.fclients[ic.curCode][ic?.curBrush](ic?.brushSize, `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`)
        }
        ic.setCurColor(e.rgb)
    }

    const brushSizeChange = (v) => {
        ic?.setBrushSize(v)
        ic.fclients[ic.curCode][ic?.curBrush](v, `rgba(${ic.curColor.r},${ic.curColor.g},${ic.curColor.b},${ic.curColor.a})`)
    }

    const repeat = () => {
        if(ic.curActivityObject){
            ic.fabricClient.repeat(ic.curActivityObject,ic.curCode)
        }
    }

    /** 复制图案 */
    const handleCopyImages = () => {
        const fabricData = ic.fabricClient?.getJsonData()
        const curCode = ic.curCode
        if (fabricData && curCode && fabricData[curCode]) {
            const saveImage = fabricData[curCode].objects;
            if (saveImage.length) {
                const saveImageStr = JSON.stringify(saveImage)
                tool.copyToClip(saveImageStr);
                Toast.success('复制成功');
            } else {
                Toast.warning('编辑器内没有图案！');
            }
        } else {
            Toast.warning('编辑器内没有图案！');
        }
    }

    /** 粘贴图案 */
    const handlePasteImages = async () => {
        try {
            const clipText = await navigator.clipboard.readText();
            const clipObjects = JSON.parse(clipText)
            const fabricClient = ic.fabricClient
            const curCode = ic.curCode
            const fabricData = ic.fabricClient?.getJsonData()
            const canvas = fabricClient.canvaes[curCode]
            const filterClipObjects = clipObjects.filter(item => item.type === 'image')
            const cloneClipObjects = cloneDeep(filterClipObjects)
            cloneClipObjects.forEach((item, index) => {
                cloneClipObjects[index].left = item.left + 10
            })
            fabricData[curCode].objects.push(...cloneClipObjects)
            ic.fabricClient.loadFromJSON(canvas, fabricData[curCode])
        } catch (err) {
            Toast.warning('请先复制正确的图案格式！');
        }
    }

    useImperativeHandle(props.action, () => ({
        confirm,
    }));

    return (
        <Animate showType="flex" show={ic.curMenu === TYPES.PATTERN || ic.curMenu === TYPES.DRAW} style={{
            position: "fixed",
            width: 300,
            right: 50,
            top: 60,
            bottom: 0,
            margin: "auto",
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <div style={{height: "96%",overflow:"hidden"}}>
                <div style={{
                    color: "#333333",
                    fontSize: 14,
                    padding: "5px 8px",
                    display:"flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Button style={{height: 20, width: 20}} onClick={cancel} icon={<IconCrossStroked style={{fontSize: 12}} />}></Button>
                    <div>编辑定位花</div>
                    <Button style={{height: 20, width: 20}} onClick={confirm} icon={<IconTick style={{fontSize: 12}} />}></Button> 
                </div>
                <div style={{
                    padding: "12px 8px",
                    overflow: "auto",
                    // height: "calc(50% - 26px - 60px)"
                }}>
                    {/* <span>部位选择</span>
                    <Divider margin='12px'/> */}
                    <div className="hideScroll" ref={attrDiv} style={{maxHeight: 200, overflow: "auto"}}>
                        <Codes allHide={true} rowCount={4} pr={14} />
                    </div>
                    <Divider margin='12px' align='center'>
                        {
                            ic.curCode === "" ? "编辑":`正在编辑${ic.curCode}`
                        }
                    </Divider>
                    <div className='hideScroll' style={{
                        height: document.body.clientHeight - (attrDiv?.current?.clientHeight || 0) - document.body.clientHeight*0.04 - 190,
                        overflowY: "auto",
                        overflowX: "hidden",
                        position: "relative"
                    }}>
                        
                        
                        <div style={{height: 12}}></div>
                        <div style={{textAlign: "center", fontSize: 12, paddingTop: 20, display: ic.curCode === "" ? "block":"none"}}>请先选择一个部位</div>
                        {
                            <div style={{display: ic.curCode === "" ? "none":"block"}}> 
                                {/* <img src={HuakuangUp} style={{display: "block", margin: "0 auto", width: 100}} /> */}
                                <div>
                                    <Button style={{ marginRight: 8 }} onClick={repeat}>平铺</Button>
                                    <Button style={{ marginRight: 8 }} onClick={handleCopyImages}>复制图案</Button>
                                    <Button onClick={handlePasteImages}>粘贴图案</Button>
                                </div>
                                <div style={{height: 12}}></div>
                                <div style={{
                                    position: "absolute", 
                                    width: 276.48, 
                                    height: 276.48, 
                                    overflow:"hidden",
                                    boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
                                    left: 3
                                }}>
                                    <div style={{width: 512, height:512, transform: "scale(0.54) translate(-218.5px, -220px)", position: "relative", overflow: "hidden"}}>
                                        {
                                            ic.meshs?.map(v => (
                                                <div style={{display: ic.curCode === v ? "block":"none"}} key={v}>
                                                    <canvas id={v}></canvas>
                                                </div>
                                            ))
                                        }
                                        {
                                            (ic.controPosition && ic.curActivityObject) && <>
                                                <img src={patternClose} onClick={delPattern} style={{ height: 60, width: 60, zIndex: 9, position: 'absolute', ...ic?.controPosition?.tl_position }} alt="" />
                                                <img src={patternScale} style={{ pointerEvents: "none", height: 60, width: 60, zIndex: 9, position: 'absolute', ...ic?.controPosition?.br_position }} alt="" />
                                                <img src={patternRote} style={{ pointerEvents: "none", height: 60, width: 60, zIndex: 9, position: 'absolute', ...ic?.controPosition?.tr_position }} alt="" />
                                                {/* <img src={xscaleIcon} style={{ pointerEvents: "none", height: 19, width: 19, zIndex: 9, position: 'absolute', ...ic?.controPosition?.ml_position }} alt="" />
                                                <img src={xscaleIcon} style={{ pointerEvents: "none", height: 19, width: 19, zIndex: 9, position: 'absolute', ...ic?.controPosition?.mr_position }} alt="" /> */}
                                            </>
                                        } 
                                    </div>
                                </div>
                                <div style={{height: 277.48}}></div>
                                <img src={HuakuangDown} style={{display: "block", margin: "0 auto", width: 150}} />
                            </div>
                        }
                        <div style={{display: ic.curMenu === TYPES.DRAW ? "block":"none"}}>
                            <div style={{display: ic.curCode === "" ? "none":"block"}}>
                                <div>
                                    <div style={{fontSize: 12, marginBottom: 12}}>笔刷：</div>
                                    <div>
                                        <Select onChange={choiceBrush} value={ic?.curBrush} style={{width: "100%", height: 88}}>
                                            {
                                                brushs.map(v => (
                                                    <Select.Option key={v.key} value={v.key}>
                                                        <>
                                                            <div>{v.name}</div>
                                                            {
                                                                v.img && <img style={{width: 88}} src={v.img} />
                                                            }
                                                        </>
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div style={{height: 12}}></div>
                                <div>
                                    <div style={{fontSize: 12}}>笔刷大小：</div>
                                    <div>
                                        <Slider min={5} max={100} value={ic?.brushSize} onChange={brushSizeChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display: ic.curMenu === TYPES.DRAW ? "none":"block"}}>
                            <div style={{display: ic.curCode === "" ? "none":"block"}}>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{width: 210}}><Input style={{fontFamily}} onChange={txtChange} showClear placeholder="输入文字" /></div>
                                    <Button onClick={addTxt} disabled={!curTxt} style={{width: 60}}>添加</Button>
                                </div>
                                <div style={{height: 12}}></div>
                                <div>
                                    <Select onChange={choiceFont} value={fontFamily} style={{width: "100%"}} placeholder="选择字体">
                                        {
                                            fontList.map(v => (
                                                <Select.Option key={v.fontFamily} value={v.fontFamily}>{v.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div style={{display: ic.curCode === "" ? "none":"block"}}>
                            <div style={{fontSize: 12, marginBottom: 12, marginTop: 12}}>选择颜色：</div>
                            <SketchPicker color={ic.curColor} onChange={colorChange} style={{background: "none", width: "auto"}} />
                        </div>
                    </div>

                    
                </div>
            </div>
        </Animate>
    )
}

export default Index