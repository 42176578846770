/** External Dependencies */
import Custom from '@scaleflex/icons/custom';
import Square from '@scaleflex/icons/square';
import Landscape from '@scaleflex/icons/landscape';
import Portrait from '@scaleflex/icons/portrait';
import CropClassicTv from '@scaleflex/icons/crop-classic-tv';
import CropCinemaScope from '@scaleflex/icons/crop-cinema-scope';
import ImageOutline from '@scaleflex/icons/image-outline';

/** Internal Dependencies */
import { CUSTOM_CROP, ELLIPSE_CROP, ORIGINAL_CROP } from '../../../utils/constants';
import toPrecisedFloat from '../../../utils/toPrecisedFloat';

export const DEFAULT_CROP_PRESETS = [
  {
    titleKey: 'custom',
    ratio: CUSTOM_CROP,
    icon: Custom,
    hide: ({ lockCropAreaAt } = {}) => lockCropAreaAt,
  },
  {
    titleKey: 'original',
    ratio: ORIGINAL_CROP,
    icon: ImageOutline,
  },
  {
    titleKey: '正方形',
    icon: Square,
    ratio: toPrecisedFloat(1 / 1),
    descriptionKey: '1:1',
  },
  {
    titleKey: 'portrait',
    descriptionKey: '2:3',
    ratio: toPrecisedFloat(2 / 3),
    icon: Portrait,
  },
  {
    titleKey: 'landscape',
    descriptionKey: '3:2',
    ratio: toPrecisedFloat(3 / 2),
    icon: Landscape,
  },
  {
    titleKey: 'portrait',
    descriptionKey: '3:4',
    ratio: toPrecisedFloat(3 / 4),
    icon: Portrait,
  },
  {
    titleKey: 'classicTv',
    descriptionKey: '4:3',
    ratio: toPrecisedFloat(4 / 3),
    icon: CropClassicTv
  },
  {
    titleKey: 'portrait',
    descriptionKey: '9:16',
    ratio: toPrecisedFloat(9 / 16),
    icon: Portrait,
  },
  {
    titleKey: 'landscape',
    descriptionKey: '16:9',
    ratio: toPrecisedFloat(16 / 9),
    icon: Landscape,
  },
  {
    titleKey: 'cinemascope',
    descriptionKey: '21:9',
    ratio: toPrecisedFloat(21 / 9),
    icon: CropCinemaScope
  },  
  // {
  //   titleKey: 'ellipse',
  //   ratio: ELLIPSE_CROP,
  //   icon: Ellipse,
  // },
];
