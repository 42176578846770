import http from '../../lib/http';
import * as apis from '../../lib/api';
import { useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import * as tool from '../../lib/tool';
import PullList from '../../components/pull_list';
import Top from '../../components/top';
import { useNavigate } from 'react-router-dom';
import { Carousel, Typography, Space } from '@douyinfe/semi-ui';
import Logo from '../../static/img/logo.png';
const { Title, Paragraph } = Typography;

const Index = () => {
  const n = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    page: 1,
    page_size: 20,
    category_id: -1,
    second_category_id: 0,
    three_category_id: 0,
  });
  const [amount, setamount] = useState({
    day_new_public_model_amount: 0,
    day_new_private_model_amount: 0,
    day_new_public_fabric_amount: 0,
    day_new_private_fabric_amount: 0,
  });
  const [designRet, setDesignRet] = useState({ list: [] });

  useEffect(() => {
    loadData(search, true);
    getAmount();
  }, []);

  const style = {
    width: '100%',
    height: '400px',
  };

  const titleStyle = {
    position: 'absolute',
    top: '100px',
    left: '100px',
    right: '100px',
    color: '#1C1F23',
  };
  const textStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  };
  const colorStyle = {
    color: '#1C1F23',
  };

  const renderLogo = () => {
    return <img src={Logo} alt="semi_logo" style={{ width: 87 }} />;
  };

  const imgList = [
    'https://lf3-static.bytednsdoc.com/obj/eden-cn/hjeh7pldnulm/SemiDocs/bg-1.png',
    // 'https://lf3-static.bytednsdoc.com/obj/eden-cn/hjeh7pldnulm/SemiDocs/bg-2.png',
    // 'https://lf3-static.bytednsdoc.com/obj/eden-cn/hjeh7pldnulm/SemiDocs/bg-3.png',
  ];

  const textList = [
    [
      '猫耳3D数字库',
      '从 Normal Design，到 Any Design',
      '快速落地你的设计想法，并应用在实际生产中',
    ],
    // ['Semi 物料市场', '面向业务场景的定制化组件，支持线上预览和调试', '内容由 Semi Design 用户共建'],
    // ['Semi Pro (开发中)', '基于 40+ 真实组件代码设计', '海量页面模板前端代码一键转'],
  ];

  const getAmount = async () => {
    try {
      const data = await http.post(apis.COCKPIT_SHOWDATA);
      setamount(data);
      //window.location.replace("/")
    } catch (error) {}
  };
  const loadData = async (s, refresh = false) => {
    try {
      let ret = await http.post(apis.DESIGN_LIST, s);
      if (refresh) {
        setDesignRet(ret);
      } else {
        let cpData = cloneDeep(designRet);
        cpData.list = [...cpData.list, ...ret.list];
        setDesignRet(cpData);
      }
    } catch (error) {}
  };

  const loadMore = async () => {
    let cpSearch = cloneDeep(search);
    cpSearch.page++;
    setSearch(cpSearch);
    setLoading(true);
    await loadData(cpSearch, false);
    setLoading(false);
  };

  return (
    <div style={{ padding: '0 24px' }}>
      <Top
        inputSearch={async (v) => {
          tool.processor.process(async () => {
            let cpSearch = cloneDeep(search);
            cpSearch.name = v;
            cpSearch.page = 1;
            setSearch(cpSearch);
            setLoading(true);
            await loadData(cpSearch, true);
            setLoading(false);
          });
        }}
        onSearch={async (s) => {
          let cpSearch = cloneDeep(search);
          let n = Object.assign(cpSearch, s);
          setSearch(n);
          await loadData(n, true);
        }}
      />

      <div>
        {designRet.total === 0 ? (
          <div style={{ textAlign: 'center' }}>暂无数据!~</div>
        ) : (
          <PullList
            total={designRet.total}
            loading={loading}
            banner={
              <>
                <Carousel autoPlay={false} style={style} theme="dark">
                  {imgList.map((src, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundSize: 'cover',
                          backgroundImage: `url(${src})`,
                        }}
                      >
                        <Space
                          vertical
                          align="start"
                          spacing="medium"
                          style={titleStyle}
                        >
                          {renderLogo()}
                          <div style={textStyle}>
                            <Title heading={2} style={colorStyle}>
                              {textList[index][0]}
                            </Title>
                            {/* <Title heading={2} style={colorStyle}>
                              {' '}
                              {`今日公库新增款式${amount.day_new_public_model_amount},新增面料${amount.day_new_public_fabric_amount}`}
                            </Title> */}
                          </div>
                          <Space vertical align="start">
                            <Paragraph style={colorStyle}>
                              {textList[index][1]}
                            </Paragraph>
                            <Paragraph style={colorStyle}>
                              {textList[index][2]}
                            </Paragraph>
                          </Space>
                        </Space>
                      </div>
                    );
                  })}
                </Carousel>
                <h3 style={{ color: '#111111' }}>数字库</h3>
              </>
            }
            loadMore={loadMore}
            height={document.body.clientHeight - 80}
            data={designRet?.list}
            onItemClieck={(item) => {
              // n(`/design?id=${item.id}`)
              window.location.href = `/design?id=${item.id}`;
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Index;
