
import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

const Index = () => {
    const l = useLocation()

    useEffect(()=>{
        const params = new URLSearchParams(l.search)
        localStorage.setItem("token",params.get("token"))
        setTimeout(()=>{
            if(params.get("from") === "design"){
                window.location.replace(`/design?id=${params.get("id")}`)
                return
            }
            window.location.replace(`/design?id=${params.get("id")}&publish=1`)
        },1000)
    },[])
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        }}>
            <h3>正在自动登录至设计工作台...</h3>
        </div>
    )
}

export default Index