import {
  Input,
  Tabs,
  TabPane,
  Button,
  Spin,
  Modal,
  Form,
  Banner,
  Toast,
} from '@douyinfe/semi-ui';
import { IconSearch, IconSidebar, IconExpand } from '@douyinfe/semi-icons';
import {
  useRef,
  useState,
  useEffect,
} from 'react';
import { cloneDeep } from 'lodash';
import * as apis from '../../../lib/api';
import http from '../../../lib/http';
import Tab from '../../../components/tab';
import Upload from '../../../components/upload';
import * as tool from '../../../lib/tool';
import InfiniteScroll from 'react-infinite-scroller';

let dataLoaded = false;

const cacheData = {
  category: [],
  pubCategory: [],
  categoryTab: '2',
  curCategory: 0,
  data: { list: [] },
  search: { page: 1, page_size: 20, status: 1, category_id: 0, isPub: '1' },
};

const ChartletList = (props) => {
  const [category, setCategory] = useState(cacheData.category);
  const [selfCategory, setSelfCategory] = useState([]);
  const [secondCategory, setSecondCategory] = useState([]);
  const [threeCategory, setThreeCategory] = useState([]);
  const [categoryTab, setCategoryTab] = useState(cacheData.categoryTab);
  const [curCategory, setCurCategory] = useState(0);
  const [curSecconCategory, setCurSecconCategory] = useState(0);
  const [curThreeCategory, setCurThreeCategory] = useState(0);
  const [data, setData] = useState(cacheData.data);
  const [search, setSearch] = useState(cacheData.search);
  const [loading, setLoading] = useState(true);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false)
  const [scrollHeight, setScrollHeight] = useState(462)
  const categoryDiv = useRef();
  const formApiRef = useRef();
  const scrollParentRef = useRef();

  const categoryChange = async (v, level = 'category_id') => {
    if (scrollParentRef.current) {
      scrollParentRef.current.scrollTop = 0;
    }
    let cpSearch = cloneDeep(search);
    cpSearch.page = 1;
    cpSearch[level] = v;
    if (level === 'category_id') {
      cpSearch.second_category_id = 0;
      cpSearch.three_category_id = 0;
      setCurSecconCategory(0);
      setCurThreeCategory(0);
      setThreeCategory([]);
      http
        .post(apis.PC_CHARTLET_CATEGORY_LIST, {
          status: 1,
          super_id: v,
          level: 2,
          is_pub: parseInt(categoryTab),
        })
        .then((r) => {
          setSecondCategory(r);
        });
    }
    if (level === 'second_category_id') {
      cpSearch.three_category_id = 0;
      setCurThreeCategory(0);
      http
        .post(apis.PC_CHARTLET_CATEGORY_LIST, {
          status: 1,
          super_id: v,
          level: 3,
          is_pub: parseInt(categoryTab),
        })
        .then((r) => {
          setThreeCategory(r);
        });
    }
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    await loadData(cacheData.search, true);
    if (level === 'category_id') {
      setCurCategory(v);
    }
    if (level === 'second_category_id') {
      setCurSecconCategory(v);
    }
    if (level === 'three_category_id') {
      setCurThreeCategory(v);
    }
  };

  useEffect(() => {
    let timer = null
    if (category.length || secondCategory.length || threeCategory.length) {
      setTimeout(() => {
        setScrollHeight(615 - categoryDiv?.current?.clientHeight || 0)
      }, 300)
    } else {
      setScrollHeight(462)
    }
    return clearTimeout(timer)
  }, [category, secondCategory, threeCategory])

  const chooseChartlet = async (v) => {
    props.onSelect(process.env.REACT_APP_OSS_URL + v.path)
  };

  const loadData = async (s, refresh = false) => {
    setLoading(true);
    try {
      const d = await http.post(apis.PC_CHARTLET_LIST, s);
      if (refresh) {
        setData(d);
        cacheData.data = d;
      } else {
        let cpData = cloneDeep(data);
        cpData.list = [...cpData.list, ...d.list];
        setData(cpData);
        cacheData.data = cpData;
      }
    } catch (error) {}
    setLoading(false);
  };

  const tabChange = async (v) => {
    if (scrollParentRef.current) {
      scrollParentRef.current.scrollTop = 0;
    }
    setCurCategory(-1);
    setCurSecconCategory(0);
    setCurThreeCategory(0);
    setSecondCategory([]);
    setThreeCategory([]);
    setCategoryTab(v);
    const s = {
      page: 1,
      page_size: 20,
      status: 1,
      category_id: 0,
      second_category_id: 0,
      three_category_id: 0,
      name: search.name,
      is_pub: parseInt(v),
    };
    setSearch(s);
    cacheData.search = s;
    await loadData(cacheData.search, true);
    //获取分类
    const cdata = await http.post(apis.PC_CHARTLET_CATEGORY_LIST, {
      status: 1,
      is_pub: parseInt(v),
    });
    setCategory(tool.handleLevelData(cdata));
  };

  const onInputSearch = async (val) => {
    let cpSearch = cloneDeep(search);
    cpSearch.page = 1;
    cpSearch.name = val;
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    await loadData(cacheData.search, true);
  };

  const loadMore = async () => {
    let cpSearch = cloneDeep(search);
    cpSearch.page++;
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    await loadData(cacheData.search, false);
  };

  useEffect(() => {
    dataLoaded = true;
      tabChange('1');
      http
        .post(apis.PC_CHARTLET_CATEGORY_LIST, { status: 1, is_pub: 2 })
        .then((cdata) => {
          setSelfCategory(tool.handleLevelData(cdata));
        });
  }, []);

  const handleOk = async () => {
    setLoading(true);
    try {
      const data = await formApiRef.current.validate();
      await http.post(apis.PC_CHARTLET_CREATE, {
        name: data.name,
        path: data.path,
        tags: data?.tags?.join(),
        category_id: data.category_id[0] ? data.category_id[0] : 0,
        second_category_id: data.category_id[1] ? data.category_id[1] : 0,
        three_category_id: data.category_id[2] ? data.category_id[2] : 0,
      });
      setUploadVisible(false);
      Toast.success('新建成功');
      tabChange('2');
      chooseChartlet({ path: data.path });
    } catch (error) {
      //console.log(error)
    }
    setLoading(false);
  };

  const handleCancel = async () => {
    setUploadVisible(false);
  };

  const readyUpload = () => {
    setUploadVisible(true);
  };

  return (
    <>
      { !isCollapse ? <Button style={{ position: 'absolute', right: '300px', color: '#888' }}  icon={<IconSidebar />} onClick={() => {
        setIsCollapse(true)
        props.onCollapse(true)
      }}>收起</Button>
        :<Button style={{ position: 'absolute', right: '0px', color: '#888' }}   icon={<IconExpand />} onClick={() => {
          setIsCollapse(false)
          props.onCollapse(false)
        }}>展开</Button>}
      <div
        style={{
          width: "300px",
          height: "703px",
          overflowY: "auto",
          boxShadow: "#cbd3da -6px 8px 12px 0px",
          position: "absolute",
          right: isCollapse ? '-300px' : 0,
          background: "#fff",
          opacity: 1,
          zIndex: 99,
        }}
      >
        <Modal
          title="贴图上传"
          visible={uploadVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          closeOnEsc={true}
        >
          <div
            className="hideScroll"
            style={{ maxHeight: '50vh', overflow: 'auto' }}
          >
            <Banner
              type="warning"
              description="上传的贴图都将默认存入私库！！！"
            />
            <Form
              getFormApi={(formApi) => (formApiRef.current = formApi)}
              autoComplete="off"
              style={{ width: '100%' }}
            >
              {({ formState, values, formApi }) => (
                <>
                  <Form.Input
                    field="name"
                    label="贴图名称"
                    style={{ width: '100%' }}
                    placeholder="请输入"
                  />
                  <Form.Cascader
                    field="category_id"
                    label="所属分类"
                    showClear
                    style={{ width: '100%' }}
                    treeData={selfCategory}
                    placeholder="请选择"
                    rules={[{ required: true, message: '请选择' }]}
                  />
                  <Form.Select
                    label="标签"
                    placeholder="请输入"
                    field="tags"
                    style={{ width: '100%' }}
                    optionList={[]}
                    allowCreate={true}
                    multiple={true}
                    filter={true}
                  />
                  <Upload
                    field="path"
                    label="贴图"
                    rules={[{ required: true, message: '请上传' }]}
                  />
                </>
              )}
            </Form>
          </div>
        </Modal>
        <Spin spinning={loading}>
          <div
            style={{
              padding: '8px 0 8px 8px',
            }}
          >
            <div>
              <Input
                onChange={onInputSearch}
                placeholder="搜索"
                suffix={<IconSearch />}
                showClear
                className={props.isVip?'semi-always-dark':''} 
                style={
                  {
                    background:props.isVip?'#282735':'',
                    color:props.isVip? "#6D6A78":'',
                  }
                }
              ></Input>
            </div>
            <div ref={categoryDiv}>
              <Tabs type="line" value={categoryTab} onChange={tabChange}>
                <TabPane tab="公库" itemKey="1">
                  <div style={{ height: 12 }}></div>
                  <div style={{ fontSize: 12 }}>一级分类</div>
                  <div style={{ height: 12 }}></div>
                  <Tab
                    isVip={props.isVip}
                    data={[{ id: -1, name: '全部', super_id: 0 }, ...category]}
                    value={curCategory}
                    onChange={(v) => categoryChange(v, 'category_id')}
                  />
                  {secondCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>二级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...secondCategory,
                        ]}
                        value={curSecconCategory}
                        onChange={(v) =>
                          categoryChange(v, 'second_category_id')
                        }
                      />
                    </>
                  )}
                  {threeCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>三级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...threeCategory,
                        ]}
                        value={curThreeCategory}
                        onChange={(v) => categoryChange(v, 'three_category_id')}
                      />
                    </>
                  )}
                </TabPane>
                <TabPane tab="私库" itemKey="2">
                  <div style={{ height: 12 }}></div>
                  <div style={{ fontSize: 12 }}>一级分类</div>
                  <div style={{ height: 12 }}></div>
                  <Tab
                    isVip={props.isVip}
                    data={[{ id: -1, name: '全部', super_id: 0 }, ...category]}
                    value={curCategory}
                    onChange={(v) => categoryChange(v, 'category_id')}
                  />
                  {secondCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>二级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...secondCategory,
                        ]}
                        value={curSecconCategory}
                        onChange={(v) =>
                          categoryChange(v, 'second_category_id')
                        }
                      />
                    </>
                  )}
                  {threeCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>三级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...threeCategory,
                        ]}
                        value={curThreeCategory}
                        onChange={(v) => categoryChange(v, 'three_category_id')}
                      />
                    </>
                  )}
                </TabPane>
              </Tabs>
            </div>
            <div>
              <div
                className="hideScroll"
                style={{
                  height: scrollHeight,
                  overflow: 'auto',
                  position: 'relative',
                }}
              >
                {!data.list || (data.list && data.list.length === 0) ? (
                  <div
                    style={{ textAlign: 'center', paddingTop: 5, fontSize: 12 }}
                  >
                    暂无贴图~
                  </div>
                ) : (
                  <InfiniteScroll
                    // initialLoad={false} // 不让它进入直接加载
                    // pageStart={1} // 设置初始化请求的页数
                    loadMore={() => {
                      if (!loading) {
                        loadMore();
                      }
                    }}
                    useWindow={false}
                    hasMore={data?.total !== data?.list?.length}
                    getScrollParent={() => scrollParentRef.current}
                    loader={
                      <div style={{ textAlign: 'center' }} key={0}>
                        加载中...
                      </div>
                    }
                  >
                    {data?.list?.map((v, k) => (
                      <div
                        key={v.id}
                        style={{
                          cursor: 'pointer',
                          display: 'inline-block',
                          marginRight: (k + 1) % 4 === 0 ? 0 : 5,
                          marginBottom: 5,
                          // float: "left",
                          // width: 62
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          chooseChartlet(v);
                        }}
                      >
                        <img
                          style={{
                            width: 65,
                            height: 65,
                            border: 'solid 1px rgba(255,255,255,0.8)',
                            borderRadius: 5,
                          }}
                          src={
                            process.env.REACT_APP_OSS_URL +
                            v.path +
                            '?x-oss-process=image/resize,w_100'
                          }
                        />
                      </div>
                    ))}
                    {data.list.length === data.total && (
                      <div
                        style={{
                          textAlign: 'center',
                          paddingTop: 5,
                          fontSize: 12,
                        }}
                      >
                        没有更多了~
                      </div>
                    )}
                  </InfiniteScroll>
                )}
              </div>
              <>
                <div style={{ height: 8 }}></div>
                <Button
                  onClick={readyUpload}
                  style={{
                    // position: "absolute",
                    width: '100%',
                    bottom: -30,
                    left: 0,
                    fontSize: 12,
                    background: props.isVip ? '#282735' : '',
                  }}
                >
                  上传贴图
                </Button>
              </>
            </div>
          </div>
        </Spin>
      </div>
    </>
    
  );
};

export default ChartletList;
