/** External Dependencies */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Crop as CropIcon } from '@scaleflex/icons/crop';

/** Internal Dependencies */
import { useStore } from '../../../hooks';
import ToolsBarItemButton from '../../ToolsBar/ToolsBarItemButton';
import { TOOLS_IDS } from '../../../utils/constants';
import { StyledToolsBarItemButtonLabel } from '../../ToolsBar/ToolsBar.styled';
import CropPresetsOption from './CropPresetsOption';

const Crop = ({ selectTool, isSelected }) => {
  const state = useStore();
  const { config: { adjustRadiusBtn } } = state
  const { config, t } = useStore();
  const [anchorEl, setAnchorEl] = useState();

  const selectToolAndShowPresets = (toolId, e) => {
    selectTool(toolId);
    setAnchorEl(e.currentTarget);
  };

  const closeCropPresets = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <ToolsBarItemButton
        className="FIE_crop-tool"
        id={TOOLS_IDS.CROP}
        Icon={CropIcon}
        onClick={selectToolAndShowPresets}
        isSelected={isSelected}
      >
        {!config[TOOLS_IDS.CROP].noPresets ? (
          <CropPresetsOption anchorEl={anchorEl} onClose={closeCropPresets} />
        ) : (
          <StyledToolsBarItemButtonLabel className="FIE_crop-tool-label">
            {t('cropTool')}
          </StyledToolsBarItemButtonLabel>
        )}
      </ToolsBarItemButton>
      {adjustRadiusBtn || null}
    </div>
  );
};

Crop.defaultProps = {
  isSelected: false,
};

Crop.propTypes = {
  selectTool: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default Crop;
