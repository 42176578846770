/** External Dependencies */
import React, { useRef, useState } from 'react';

/** Internal Dependencies */
import { Spin } from '@douyinfe/semi-ui';
import { useAnnotation, useStore } from '../../hooks';
import { FEEDBACK_STATUSES, TOOLS_IDS } from '../../utils/constants';
import { SET_FEEDBACK } from '../../actions';
import ChartletList from './ChartletList'

const ADDED_IMG_SPACING_PERCENT = 0.15;

const ChartletListWrapper = ({ onCollapse }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    shownImageDimensions,
    dispatch,
    adjustments: { crop = {} },
    t,
    config = {},
  } = useStore();

  const { isVip } = config

  const [image, saveImage, addNewImage] = useAnnotation(
    {
      name: TOOLS_IDS.IMAGE,
      opacity: 0.7,
    },
    false,
  );

  const requestedImgsCount = useRef(0);

  const addImgScaled = (loadedImg) => {
    const layerWidth = crop.width || shownImageDimensions.width;
    const layerHeight = crop.height || shownImageDimensions.height;
    const layerCropX = crop.x || 0;
    const layerCropY = crop.y || 0;

    const newImgRatio = Math.min(
      1,
      layerWidth /
        (loadedImg.width + loadedImg.width * ADDED_IMG_SPACING_PERCENT),
      layerHeight /
        (loadedImg.height + loadedImg.height * ADDED_IMG_SPACING_PERCENT),
    );

    addNewImage({
      image: loadedImg,
      x: layerCropX + layerWidth / 2 - (loadedImg.width * newImgRatio) / 2,
      y: layerCropY + layerHeight / 2 - (loadedImg.height * newImgRatio) / 2,
      width: loadedImg.width * newImgRatio,
      height: loadedImg.height * newImgRatio,
    });
  };

  const hideLoaderAfterDone = (filesLength) => {
    requestedImgsCount.current += 1;
    if (requestedImgsCount.current === filesLength) {
      requestedImgsCount.current = 0;
      setIsLoading(false);
    }
  };

  const setFeedback = (msg) => {
    dispatch({
      type: SET_FEEDBACK,
      payload: {
        feedback: {
          message: msg,
          status: FEEDBACK_STATUSES.WARNING,
        },
      },
    });
  };

  const importImgFromGallery = (imgUrl) => {
    console.log('imgUrl', imgUrl)
    setIsLoading(true);
    const img = new Image();
    img.onload = () => {
      addImgScaled(img);
      hideLoaderAfterDone(1);
    };
    img.onerror = () => {
      setFeedback(t('uploadImageError'));
      hideLoaderAfterDone(1);
    };
    img.width = 200
    img.height = 200
    img.crossOrigin = 'Anonymous';
    img.src = imgUrl;
  };
  const onCollapseEvent = (collapse) => {
    onCollapse && onCollapse(collapse)
  }

  return (
    <Spin spinning={isLoading} tip="正在添加中...">
      <ChartletList
        isVip={isVip}
        onSelect={importImgFromGallery}
        onCollapse={onCollapseEvent}
      />
    </Spin>
  );
};

export default ChartletListWrapper;
