export const LOGIN = `/client/er/open/login`
export const USER_INFO = `/merchant/client/api/user/info`
export const USER_LOGOUT = `/merchant/client/api/user/logout`
export const FABRIC_CATEGORY_LIST = `/merchant/client/api/fabric/category/list`
export const FABRIC_LIST = `/merchant/client/api/fabric/list`
export const FABRIC_CREATE = `/merchant/client/api/fabric/create`
export const PATTERN_CATEGORY_LIST = `/merchant/client/api/pc/pattern/category/list`
export const PATTERN_LIST = `/merchant/client/api/pc/pattern/list`
export const PATTERN_CREATE = `/merchant/client/api/pc/pattern/create`

export const MODEL_DETAIL = `/merchant/client/api/model/detail`

export const SCENE_CATEGORY_LIST = `/merchant/client/api/scene/category/list`
export const SCENE_LIST = `/merchant/client/api/scene/list`
export const SCENE_CREATE = `/merchant/client/api/scene/create`
export const H5_DESIGN_DETAIL = `/merchant/h5/design/detail`
export const DESIGN_DETAIL = `/merchant/client/api/design/detail`
export const DESIGN_LIST = `/merchant/client/api/design/list`
export const DESIGN_VIP_LIST = `/merchant/client/api/design/vip/list`
export const DESIGN_ADMIN_CREATE = `/merchant/client/api/admin/design/create`
export const DESIGN_CREATE = `/merchant/client/api/vip/design/create`
export const DESIGN_ADMIN_CATEGORY_LIST = `/merchant/client/api/design/category/admin/list`
export const DESIGN_CATEGORY_LIST = `/merchant/client/api/design/category/list`
export const CHANGE_PASSWORD = `/merchant/client/api/password/change`

/** 修改设计备注 */
export const UPDATE_DESIGN_DESCRIBE = `/merchant/client/api/design/describe/update`


export const ALI_STS = `/merchant/client/api/sts/token`
export const VIDEO_TRANS = `/merchant/client/api/video/trans`
export const UUID = `/uuid`
export const COCKPIT_SHOWDATA=`/merchant/client/api/pc/cockpit/showdata`

/** 贴图管理 */
export const PC_CHARTLET_CATEGORY_LIST = `/merchant/client/api/pc/chartlet/category/list`
export const PC_CHARTLET_LIST = `/merchant/client/api/pc/chartlet/list`
export const PC_CHARTLET_CREATE = `/merchant/client/api/pc/chartlet/create`

/** 申请时装秀 */
export const fashionShowApplyApi = `/merchant/client/api/fashionShow/apply`





