import { Slider,  withField, Switch, Form } from '@douyinfe/semi-ui';
import { useState } from 'react'
import { cloneDeep } from 'lodash'

const Index = (props) => {
    const {value = {}, ...rest} = props
    return (
        <Form.Slot
            {...rest}
        >
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{paddingBottom: 5}}>平铺模式：</div>
                <div>
                    <Switch checked={value.repeat} onChange={e => {
                        let cpValue = cloneDeep(value)
                        cpValue.repeat = e
                        props.onChange(cpValue)
                    }} />
                </div>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{paddingBottom: 5}}>透明模式：</div>
                <div>
                    <Switch checked={value.transparent} onChange={e => {
                        let cpValue = cloneDeep(value)
                        cpValue.transparent = e
                        props.onChange(cpValue)
                    }} />
                </div>
            </div>
            <div style={{display: value.repeat ? "flex":"none", alignItems: "center"}}>
                <div>缩放：</div>
                <div>
                    <Slider marks={{ 0: '0', 10: '10', 20: '20', 30: '30', "-10": '-10',"-20": '-20',"-30": '-30'}} style={{width: 300}} value={value.repeatNum-26} min={-30} max={30} onChange={(v) => {
                        let cpValue = cloneDeep(value)
                        cpValue.repeatNum = v+26
                        cpValue.repeatNumY = v+26
                        props.onChange(cpValue)
                    }} />
                </div>
            </div>
            <div style={{display: "none", alignItems: "center"}}>
                <div>Y轴缩放：</div>
                <div>
                    <Slider style={{width: 300}} value={value.repeatNumY} min={0} max={20} onChange={(v) => {
                        let cpValue = cloneDeep(value)
                        cpValue.repeatNumY = v
                        props.onChange(cpValue)
                    }} />
                </div>
            </div>
        </Form.Slot>
    )
}

export default withField(Index, { valueKey: 'value', onKeyChangeFnName: 'onChange' })