// import * as THREE from 'three/build/three.module.js';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { cloneDeep, isEmpty } from 'lodash';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

class Client3D {
  constructor(s) {
    this.zoomMax = 520;
    this.zoomMin = 50;
    this.lastZoom = 0;
    this.firstDistance = 0;
    this.isDragging = false;
    this.previousMousePosition = {
      x: 0,
      y: 0,
    };
    this.currentRotation = new THREE.Euler(0, 0, 0, 'XYZ');
    this.contextmenuDown = false;
    this.cachePosition = {};
    this.container = new THREE.Object3D();
    // 动画帧
		this.animationFrame = null;
    //模型动画对象
		this.animationMixer = null;
    this.animationClock = new THREE.Clock();
    this.modelAnimation = null
    this.animateClipAction = null
    // 动画循环方式枚举
		this.loopMap = null
    // 动画循环方式枚举
		this.loopMap = {
			LoopOnce: THREE.LoopOnce,
			LoopRepeat: THREE.LoopRepeat,
			LoopPingPong: THREE.LoopPingPong
		}
    this.modelObject = null;
    /** 模特模型 */
    this.characterModelObject = null
    this.bakGeometrys = {};
    this.designObject3Dchilds = {};
    // 设置旋转速度和方向
    // this.speed = 0.025;
    this.speed = 0.035;
    this.direction = 1;
    this.rotationOpen = false;
    this.rotationOpenHover = false;
    this.modelFormat = '';
    this.rafId = 0;
    this.walkId = null; // 用于存储动画帧的ID
    this.isStopNeedsUpdate = false;
    this.canvasDom = s.canvasDom;
    this.canvasWidth = s.canvasWidth || 300;
    this.canvasHeight = s.canvasHeight || 300;
    this.object3Dchilds = {};
    this.onBeforeCompiles = {};
    this.raycaster = new THREE.Raycaster();
    this.outlinePassTimer = null;
    this.modelType = '';
    this.renderCodes = [];
    this.mapLoaderCache = {};
    this.playgroudMeshDirLight = null;
    /** 灯光对象 环境光 */
    this.ambientLight = null;
    /** 灯光对象 方向光 */
    this.directionalLightFront = null;
    this.directionalLightback = null;
    this.onOutlinePassSelectedObjects = s.onOutlinePassSelectedObjects;
    this.fabricMaps = [
      'map',
      'normalMap',
      'specularMap',
      'aoMap',
      'displacementMap',
      'roughnessMap',
      'metalnessMap',
    ]; //面料固定的5种贴图
    this.initScene();
    this.initCamera();
    this.initLight();
    this.initRender();
    this.initComposer();
  }

  //初始化相机
  initCamera() {
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.canvasWidth / this.canvasHeight,
      1,
      1000
    );
    this.camera.aspect = this.canvasWidth / this.canvasHeight;
    this.camera.zoom = 1.5;
    this.camera.updateProjectionMatrix();
    this.scene.add(this.camera);
  }

  //设置摄像机参数
  setCameraParam(fov = 45, zoom = 1.5) {
    this.camera.fov = fov;
    this.camera.zoom = zoom;
    this.camera.updateProjectionMatrix();
  }

  //获取canvasdom
  getCanvasDom() {
    return this.render?.domElement;
  }

  //清除场景
  getCameraPosition() {
    return this.camera.position;
  }

  setSceneBackgroundBlurriness(num = 0) {
    this.scene.backgroundBlurriness = num;
  }
  initLight() {
    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    this.ambientLight.castShadow = false;
    this.scene.add(this.ambientLight);

    this.directionalLightFront = new THREE.DirectionalLight(0xffffff, 0.6);
    this.directionalLightFront.position.set(1, 1, 1);
    this.directionalLightFront.castShadow = false;
    this.scene.add(this.directionalLightFront);

    this.directionalLightback = new THREE.DirectionalLight(0xffffff, 0.6);
    this.directionalLightback.position.set(-1, -1, -1);
    this.directionalLightback.castShadow = false;
    this.scene.add(this.directionalLightback);
  }

  adjustLightShadow(light) {
    light.castShadow = true;
    const kShadowSize = 2048;
    const kFov = 60;
    const kFar = 700;
    const kNear = 0.1;
    const kD = 1300;
    const kTop = kD;
    const kLeft = -kD;
    const kRight = kD;
    const kBottom = -kD;
    light.shadow.mapSize.set(kShadowSize, kShadowSize);
    light.shadow.bias = -0.002;
    let shadowCamera = light.shadow.camera;
    shadowCamera.fov = kFov;
    shadowCamera.far = kFar;
    shadowCamera.near = kNear;
    shadowCamera.left = kLeft;
    shadowCamera.top = kTop;
    shadowCamera.bottom = kBottom;
    shadowCamera.right = kRight;
    shadowCamera.zoom = 3;
  }

  //设置场景
  setSceneBg(path) {
    // this.scene.background = new THREE.Color(0x000000);
    return new Promise((resolve, reject) => {
      let texture = new THREE.TextureLoader().load(
        path,
        () => {
          //texture.mapping = THREE.EquirectangularReflectionMapping;
          // console.log("texture",texture)
          this.scene.background = texture;
          // this.scene.environment = texture;
          resolve();
        },
        () => {},
        () => {
          reject();
        }
      );
    });
  }

  //移除阴影
  removeShadow() {
    if (this.playgroudMeshDirLight) {
      this.scene.remove(this.playgroudMeshDirLight, this.playgroudMesh);
    }
  }

  //添加阴影
  addShadow(y = -0.3) {
    // this.scene.background = new THREE.Color(0xC8C8C8)
    // this.scene.fog = new THREE.Fog(0xC8C8C8, 2, 1200);
    // console.log("添加阴影======================")
    // this.playgroudMeshDirLight = new THREE.PointLight(0xffffff, 0.05);
    // this.playgroudMeshDirLight.position.set(0, 10, 0);
    // this.playgroudMeshDirLight.castShadow = true;
    // this.playgroudMeshDirLight.shadow.mapSize.width = 800;
    // this.playgroudMeshDirLight.shadow.mapSize.height = 800;
    // window.playgroudMeshDirLight = this.playgroudMeshDirLight
    // this.scene.add(this.playgroudMeshDirLight)
    // const playgroudMeshDirLight = new THREE.DirectionalLight(0xffffff, 0.3);
    // playgroudMeshDirLight.position.set(-46, 338, -15);
    // // playgroudMeshDirLight.castShadow = true;
    // // playgroudMeshDirLight.shadow.mapSize.width = 1200;
    // // playgroudMeshDirLight.shadow.mapSize.height = 1200;
    // this.scene.add(playgroudMeshDirLight)
    // this.playgroudMeshDirLight = new THREE.PointLight(0xffffff, 100);
    // this.playgroudMeshDirLight.position.set(0, 0, 0);
    // this.playgroudMeshDirLight.castShadow = true;
    // this.playgroudMeshDirLight.shadow.mapSize.width = 1200;
    // this.playgroudMeshDirLight.shadow.mapSize.height = 1200;
    // window.playgroudMeshDirLight = this.playgroudMeshDirLight
    // this.scene.add(this.playgroudMeshDirLight)
    // this.playgroudMesh = new THREE.Mesh(new THREE.PlaneGeometry(800, 800), new THREE.MeshPhongMaterial({ color: 0xffffff, side: THREE.DoubleSide, aoMapIntensity: 0 }));
    // this.playgroudMesh.rotation.x = - Math.PI / 2;
    // this.playgroudMesh.receiveShadow = true;
    // this.playgroudMesh.position.y = y
    // this.playgroudMesh.position.z = -100;
    // this.camera.add(this.playgroudMesh);
  }

  clearSceneBg() {
    this.scene.background = new THREE.Color(0xffffff);
  }

  //初始化场景
  initScene() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xffffff);

    //this.scene.background = null
    // this.scene.fog = new THREE.Fog(0xffffff, 1, 6);

    // const dirLight = new THREE.DirectionalLight(0xffffff, 0.1);
    // dirLight.position.set(0, 0, 0);
    // dirLight.shadow.mapSize = new THREE.Vector2(2500, 2500)
    // dirLight.castShadow = true;
    // // dirLight.shadow.camera.top = 180;
    // // dirLight.shadow.camera.bottom = - 100;
    // // dirLight.shadow.camera.left = - 120;
    // // dirLight.shadow.camera.right = 120;
    // this.scene.add(dirLight);

    // const grid: any = new THREE.GridHelper(5, 30, 0x000000, 0x000000);
    // grid.position.y = -0.3
    // grid.material.opacity = 1;
    // grid.material.transparent = true;
    // this.scene.add(grid);

    // const size = 5;
    // const divisions = 10;

    // const gridHelper = new THREE.GridHelper(size, divisions);
    // this.scene.add(gridHelper);
  }

  //设置场景透明
  setSceneTransparent() {
    //this.scene.background = new THREE.Color().setAlpha(0);
    this.scene.background = null;
    // this.render.setClearColor(0xffffff,0.5)
    // this.scene.environment = null
    // this.scene.backgroundBlurriness = 0
  }

  //恢复场景
  recoverSceneTransparent() {
    this.scene.background = new THREE.Color(0xffffff);
    //this.render.alpha = false
    // this.render.setClearColor(0xFFFFFF, 1);
  }

  //初始化渲染
  initRender() {
    this.render = new THREE.WebGLRenderer({
      canvas: this.canvasDom,
      preserveDrawingBuffer: true,
      antialias: true,
      alpha: true,
    });
    // window.devicePixelRatio
    this.render.setPixelRatio(window.devicePixelRatio);
    this.render.setSize(this.canvasWidth, this.canvasHeight);
    this.render.shadowMap.enabled = true;
    this.render.shadowMap.type = THREE.PCFSoftShadowMap;
    this.render.autoClear = false;
    // this.render.outputColorSpace = 'srgb';
    // this.render.gammaFactor = 2.2;
    this.render.physicallyCorrectLights = false;
    // if (ltype === 1) {
    //   // 开启现实世界渲染效果
    //   this.render.physicallyCorrectLights = true;
    // } else {
    //   // 使用默认灯光效果
    //   this.render.physicallyCorrectLights = false;
    // }
    // this.render.physicallyCorrectLights = true;
    // this.render.setClearColor(0xFFFFFF, 0);
    //      this.render.outputEncoding = THREE.LinearEncoding;
    // this.render.outputColorSpace = "srgb"
    // this.render.gammaFactor = 2.2;
  }

  //判断是否有hdr场景
  hasScene() {
    return this.scene.background.isTexture || false;
  }

  //启动场景
  start() {
    this.contron = new OrbitControls(this.camera, this.render.domElement);
    this.contron.maxZoom = 4
    // this.contron.minZoom = 1
    // this.contron.minDistance = 100
    this.contron.maxDistance = 800
    this.contron.enableRotate = true; // 允许旋转
    // this.contron.enablePan = false; // 禁止平移
    // this.contron.enableZoom = false; // 禁止缩放
    this.contron.enableDamping = true; // 启用阻尼效果，使旋转平滑
    this.contron.dampingFactor = 0.2; // 设置阻尼系数
    // this.contron.rotateSpeed = 0.5; // 设置旋转速度
    this.contron.addEventListener('start', function() {
      this.isDragging = true;
    });
    
    this.contron.addEventListener('end', function() {
      this.isDragging = false;
    });
    // this.render.domElement.addEventListener('pointerdown', function() {
    //   console.log('pointerdown', this.isDragging)
    //   this.isDragging = false;
    // });
    this.render.domElement.addEventListener('pointerup', (event) => {
      if (this.isDragging) return
      event.preventDefault();
      this.previousMousePosition = {
        x: event.clientX,
        y: event.clientY,
      };
      // const mouse = {};
      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / this.canvasWidth) * 2 - 1;
      mouse.y = -(event.clientY / this.canvasHeight) * 2 + 1;
      this.raycaster.setFromCamera(mouse, this.camera);
      const intersects = this.raycaster.intersectObject(this.scene, true);
      if (intersects.length > 0) {
        if (this.rotationOpen) {
          this.rotationOpen = false;
          this.rotationOpenHover = true;
        }
        const selectedObject = intersects[0].object;
        if (!this.renderCodes.find((v) => v === selectedObject.name)) {
          return;
        }
        const selectedObjects = [];
        selectedObjects.push(selectedObject);
        this.outlinePass.selectedObjects = selectedObjects;
        if (this.outlinePassTimer) {
          clearTimeout(this.outlinePassTimer);
          this.outlinePassTimer = null;
        }
        if (!this.outlinePassTimer) {
          this.outlinePassTimer = setTimeout(() => {
            this.outlinePass.selectedObjects = [];
            this.outlinePassTimer = null;
          }, 2000);
        }
      } else {
        clearTimeout(this.outlinePassTimer);
        this.outlinePassTimer = null;
        if (this.rotationOpenHover) {
          this.rotationOpen = true;
          this.rotationOpenHover = false;
        }
        this.outlinePass.selectedObjects = [];
      }
      if (this.onOutlinePassSelectedObjects) {
        this.onOutlinePassSelectedObjects(this.outlinePass.selectedObjects);
      }
      if (this.rotationOpen) {
        this.rotationOpen = false;
        this.rotationOpenHover = true;
      }
    });
    this.loopRender();
  }

  //设置canvas大小
  setCanvasSize(
    width = document.body.clientWidth,
    height = document.body.clientHeight
  ) {
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.render.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.zoom = 1.5;
    this.camera.updateProjectionMatrix();

    this.composer.setSize(width, height);
    this.effectFXAA.uniforms['resolution'].value.set(1 / width, 1 / height);
  }

  //加载子部件并替换
  replaceGeometry(name, path) {
    return new Promise((resolve, reject) => {
      if (!path) {
        //恢复缓存的
        if (this.bakGeometrys[name]) {
          // let b = cloneDeep(this.bakGeometrys[name])
          this.object3Dchilds[name].geometry = this.bakGeometrys[name];
          if (this.designObject3Dchilds[name]) {
            this.designObject3Dchilds[name].geometry = this.bakGeometrys[name];
          }
        }
        resolve();
        return;
      }
      new OBJLoader().load(
        process.env.REACT_APP_OSS_URL + path,
        (objects) => {
          if (!this.bakGeometrys[name]) {
            let bakg = cloneDeep(this.object3Dchilds[name].geometry);
            this.bakGeometrys[name] = bakg;
          }

          this.object3Dchilds[name].geometry.dispose();
          this.object3Dchilds[name].geometry = objects.children[0].geometry;
          if (this.designObject3Dchilds[name]) {
            this.designObject3Dchilds[name].geometry.dispose();
            this.designObject3Dchilds[name].geometry = cloneDeep(
              objects.children[0].geometry
            );
            this.mapUV(this.designObject3Dchilds[name].geometry);
          }
          resolve();
        },
        () => {},
        (err) => {
          reject(err);
        }
      );
    });
  }

  //循环渲染
  loopRender() {
    // this.contron?.update();
    this.rafId = requestAnimationFrame(() => {
      // this.ambientLight.position.copy(this.camera.position);
      // this.directionalLight_1.position.copy(this.camera.position);
      // if (this.playgroudMesh) {
      //     this.playgroudMesh.position.copy(this.camera.position);
      // }
      this.loopRender();
    });
    this.contron.update();
    if (this.modelObject && this.rotationOpen) {
      this.container.rotation.y += this.speed * this.direction;
    }
    this.composer.render();
    // if(this.dirLight && this.shadowMap){
    //     this.render.render(this.scene, this.dirLight.shadow.camera, this.shadowMap);
    // }

    //this.render?.render(this.scene as Object3D, this.camera as Camera)
  }

  //旋转
  rotation(open) {
    this.rotationOpen = open;
  }

  /** 设置模型动画 */
  onSetModelAnimaion({ animationName, loop, timeScale, weight }) {
    // mixamo.com LoopRepeat 1 1
    this.animationMixer = new THREE.AnimationMixer(this.modelObject)
    const clip = THREE.AnimationClip.findByName(this.modelAnimation, animationName)
    if (clip) {
      // 剔除腿部相关的动画
      const filterTracks = clip.tracks.filter((item) => {
        return [
          // top
          // 'mixamorigHips.position',
          'mixamorigHips.quaternion',
          'mixamorigSpine.quaternion',
          'mixamorigSpine1.quaternion',
          'mixamorigSpine2.quaternion',
          'mixamorigNeck.quaternion',
          'mixamorigHead.quaternion',
          // left arm
          'mixamorigLeftShoulder.quaternion',
          'mixamorigLeftArm.quaternion',
          'mixamorigLeftForeArm.quaternion',
          // right arm
          'mixamorigRightShoulder.quaternion',
          'mixamorigRightArm.quaternion',
          'mixamorigRightForeArm.quaternion',
          // left leg
          // 'mixamorigLeftUpLeg.quaternion',
          // 'mixamorigLeftLeg.quaternion',
          // 'mixamorigLeftFoot.quaternion',
          // 'mixamorigLeftToeBase.quaternion',
          // right leg
          // 'mixamorigRightUpLeg.quaternion',
          // 'mixamorigRightLeg.quaternion',
          // 'mixamorigRightFoot.quaternion',
          // 'mixamorigRightToeBase.quaternion',          
        ].includes(item.name)
      })
      clip.tracks = filterTracks
      this.animateClipAction = this.animationMixer.clipAction(clip)
      this.animateClipAction.setEffectiveTimeScale(timeScale)
      this.animateClipAction.setEffectiveWeight(weight)
      this.animateClipAction.setLoop(this.loopMap[loop])
      this.animateClipAction.play()
    }
    this.contron.update();
  }

  /** 动画帧 */
 animationFrameFun() {
    this.animationFrame = requestAnimationFrame(() => this.animationFrameFun())
    if (this.animationMixer) {
      this.animationMixer.update(this.animationClock.getDelta())
    }
  }

  // 开始执行动画
  onStartModelAnimaion(config) {
    this.onSetModelAnimaion(config)
    cancelAnimationFrame(this.animationFrame)
    this.animationFrameFun()
  }

  // 清除动画
  onClearAnimation() {
    if (!this.animateClipAction) return
    this.animationMixer.stopAllAction();
    this.animationMixer.update(0);
    cancelAnimationFrame(this.animationFrame)
    this.contron.update();
  }

  stopNeedsUpdate() {
    this.isStopNeedsUpdate = true;
  }

  //清除法线纹理
  clearNormalMap(type = 'normalMap', color = '#ffffff') {
    for (let name in this.object3Dchilds) {
      let mesh = this.object3Dchilds[name];
      this.onBeforeCompiles = {};
      mesh.material.onBeforeCompile = () => {};
      mesh.material[type] = null;
      mesh.material.color.set(color);
      mesh.material.needsUpdate = true;
      this.object3Dchilds[mesh.name] = mesh;
    }
  }

  getObjects() {
    return this.modelObject;
  }

  //选择部位效果
  activate(name) {
    //console.log("name", name)
    let object = this.object3Dchilds[name];
    this.outlinePass.selectedObjects = [object];
    this.outlinePass.pulsePeriod = 1;
    this.outlinePass.enabled = true;
    if (this.outlinePassTimer) {
      clearTimeout(this.outlinePassTimer);
      this.outlinePassTimer = null;
    }
    if (!this.outlinePassTimer) {
      this.outlinePassTimer = setTimeout(() => {
        this.outlinePass.selectedObjects = [];
        this.outlinePassTimer = null;
      }, 2000);
    }
  }

  //设置map的repeat
  setMapRepeat(name, repeatNum, repeatNumY = 10) {
    let object = this.object3Dchilds[name];
    if (!object) {
      return;
    }
    for (let i = 0; i < this.fabricMaps.length; i++) {
      let map = this.fabricMaps[i];
      if (object.material[map]) {
        object.material[map].repeat = new THREE.Vector2(repeatNum, repeatNumY);
      }
    }
    this.object3Dchilds[name] = object;
  }

  //设置map的offset
  setMapOffset(name, x, y) {
    let object = this.object3Dchilds[name];
    if (!object) {
      return;
    }
    for (let i = 0; i < this.fabricMaps.length; i++) {
      let map = this.fabricMaps[i];
      if (object.material[map]) {
        object.material[map].offset = new THREE.Vector2(x, y);
      }
    }
    this.object3Dchilds[name] = object;
  }

  //设置置换贴图displacementScale: SCALE,
  //displacementBias: BIAS,
  setDisplacementMapAttr(name, scale, bias) {
    let object = this.object3Dchilds[name];
    if (!object) {
      return;
    }
    if (object?.material.displacementMap) {
      object.material.displacementScale = scale;
      object.material.displacementBias = bias;
    }
    object.material.needsUpdate = true;
    this.object3Dchilds[name] = object;
  }

  //设置材质透明
  setMaterialTransparent(name, transparent) {
    let object = this.object3Dchilds[name];
    if (!object) {
      return;
    }
    object.material.transparent = transparent;
    object.material.needsUpdate = true;
    this.object3Dchilds[name] = object;
  }

  //设置材质颜色
  setMaterialColor(name, color, hslColor) {
    // console.log(hslColor);
    let object = this.object3Dchilds[name];
    if (!object) {
      return;
    }
    var hsl = new THREE.Color(color);
    const { h, s, l } = hsl.getHSL();
    // if (hslColor) hsl.setHSL(h, hslColor.s, hslColor.l);
    // else {
    hsl.setHSL(h, s, l);
    // }
    object.material.color.set(hsl);
    object.material.needsUpdate = true;
    this.object3Dchilds[name] = object;
  }

  //隐藏部位
  //设置材质颜色
  hideMaterial(name, h = true) {
    let object = this.object3Dchilds[name];
    if (!object) {
      return;
    }
    object.visible = h;
  }

  //设置map平铺模式
  openRepeat(name, open) {
    let object = this.object3Dchilds[name];
    if (!object) {
      return;
    }
    for (let i = 0; i < this.fabricMaps.length; i++) {
      let map = this.fabricMaps[i];
      if (object.material[map]) {
        if (open) {
          object.material[map].wrapS = THREE.RepeatWrapping;
          object.material[map].wrapT = THREE.RepeatWrapping;
        } else {
          object.material[map].wrapS = THREE.ClampToEdgeWrapping;
          object.material[map].wrapT = THREE.ClampToEdgeWrapping;
        }
        object.material[map].needsUpdate = true;
      }
    }
    this.object3Dchilds[name] = object;
  }

  //clear清除贴图
  clearMap(code) {
    let mesh = this.object3Dchilds[code];
    //先清除所有贴图
    for (let i = 0; i < this.fabricMaps.length; i++) {
      delete mesh?.material[this.fabricMaps[i]];
    }
  }

  //获取贴图
  getMap(code) {
    let maps = [];
    if (!code) {
      return maps;
    }
    let mesh = this.object3Dchilds[code];
    for (let i = 0; i < this.fabricMaps.length; i++) {
      let mapName = this.fabricMaps[i];
      if (
        mesh?.material &&
        mesh?.material[mapName] &&
        mesh?.material[mapName]?.image?.currentSrc
      ) {
        let urlSplit = mesh?.material[mapName].image.currentSrc.split('files/');
        maps.push({
          map: mapName,
          url: 'files/' + urlSplit[1],
        });
      }
    }
    return maps;
  }

  //设置贴图
  setMap(url, type = 'normalMap', code = '') {
    return new Promise((resolve, reject) => {
      if (this.mapLoaderCache[url]) {
        let mesh = this.object3Dchilds[code];
        if (!mesh) {
          resolve(null);
          return;
        }
        let newTexture = this.mapLoaderCache[url];
        mesh.material[type] = newTexture;
        mesh.material.needsUpdate = true;
        this.object3Dchilds[code] = mesh;
        resolve(null);
        return;
      }
      let mesh = this.object3Dchilds[code];
      if (!mesh) {
        resolve(null);
        return;
      }
      const loader = new THREE.TextureLoader();
      loader.load(
        process.env.REACT_APP_OSS_URL + url,
        async (texture) => {
          // 调节色温
          let newTexture = texture;
          mesh.material[type] = newTexture;
          mesh.material.needsUpdate = true;
          this.object3Dchilds[code] = mesh;
          this.mapLoaderCache[url] = texture;

          resolve(null);
        },
        () => {},
        () => {
          reject();
        }
      );
    });
  }
  // -100~100
  setColorTemperature(texture, colorTemperature = 0) {
    const tempTexture = texture.clone();
    tempTexture.needsUpdate = true;

    const tempCanvas = document.createElement('canvas');
    const tempContext = tempCanvas.getContext('2d');
    tempCanvas.width = texture.image.width;
    tempCanvas.height = texture.image.height;
    tempContext.drawImage(texture.image, 0, 0);

    const imageData = tempContext.getImageData(
      0,
      0,
      tempCanvas.width,
      tempCanvas.height
    );
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      // 色温调节算法
      data[i] = r + colorTemperature;
      data[i + 1] = g + colorTemperature;
      data[i + 2] = b + colorTemperature;
    }

    tempContext.putImageData(imageData, 0, 0);
    tempTexture.image = tempCanvas;
    return tempTexture;
  }
  // -100~100
  setContrast(texture, contrast = 1) {
    const tempTexture = texture.clone();
    tempTexture.needsUpdate = true;

    const tempCanvas = document.createElement('canvas');
    const tempContext = tempCanvas.getContext('2d');
    tempCanvas.width = texture.image.width;
    tempCanvas.height = texture.image.height;
    tempContext.drawImage(texture.image, 0, 0);

    const imageData = tempContext.getImageData(
      0,
      0,
      tempCanvas.width,
      tempCanvas.height
    );
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      // 对比度调节算法
      data[i] = (r - 128) * contrast + 128;
      data[i + 1] = (g - 128) * contrast + 128;
      data[i + 2] = (b - 128) * contrast + 128;
    }

    tempContext.putImageData(imageData, 0, 0);
    tempTexture.image = tempCanvas;
    return tempTexture;
  }

  //判断是否有面料
  hasMap(name) {
    if (isEmpty(this.object3Dchilds)) return false
    if (!name) {
      let h = false;
      tag: for (let i in this.object3Dchilds) {
        let m = this.object3Dchilds[i];
        for (let j = 0; j < this.fabricMaps.length; j++) {
          if (m.material[this.fabricMaps[j]]) {
            h = true;
            break tag;
          }
        }
      }
      return h;
    }
    let object = this.object3Dchilds[name];
    for (let j = 0; j < this.fabricMaps.length; j++) {
      if (object.material[this.fabricMaps[j]]) {
        return true;
      }
    }
    return false;
  }

  mapUV(geometry) {
    // 获取所有顶点的uv坐标
    var uvs = geometry.attributes.uv.array;

    // 初始化最小和最大的uv坐标
    var minU = 1,
      maxU = 0,
      minV = 1,
      maxV = 0;

    // 遍历所有uv坐标，找到最小和最大的u值和v值
    for (var i = 0; i < uvs.length; i += 2) {
      var u = uvs[i];
      var v = uvs[i + 1];
      minU = Math.min(minU, u);
      maxU = Math.max(maxU, u);
      minV = Math.min(minV, v);
      maxV = Math.max(maxV, v);
    }

    // 计算原始uv坐标范围
    var rangeU = maxU - minU;
    var rangeV = maxV - minV;

    // 如果纹理的宽度大于高度
    if (rangeU > rangeV) {
      // 缩放比例
      var scale = 1 / rangeU;
      // 计算空白区域的长度
      var delta = (rangeU - rangeV) / 2;
      // 将所有uv坐标缩放到正方形内
      // eslint-disable-next-line no-redeclare
      for (var i = 0; i < uvs.length; i += 2) {
        uvs[i] = (uvs[i] - minU) * scale;
        uvs[i + 1] = (uvs[i + 1] - minV + delta) * scale;
      }
    }
    // 如果纹理的高度大于宽度
    else {
      // 缩放比例
      // eslint-disable-next-line no-redeclare
      var scale = 1 / rangeV;
      // 计算空白区域的长度
      // eslint-disable-next-line no-redeclare
      var delta = (rangeV - rangeU) / 2;
      // 将所有uv坐标缩放到正方形内
      // eslint-disable-next-line no-redeclare
      for (var i = 0; i < uvs.length; i += 2) {
        uvs[i] = (uvs[i] - minU + delta) * scale;
        uvs[i + 1] = (uvs[i + 1] - minV) * scale;
      }
    }

    // 更新BufferGeometry的uv属性
    geometry.attributes.uv.needsUpdate = true;
  }

  action() {
    let _this = this;
    return {
      //根据name设置图片Texture
      setMapImageTextureByName(name, url) {
        return new Promise((resolve, reject) => {
          const loader = new THREE.TextureLoader();
          const texture = loader.load(url);
          let object = _this.object3Dchilds[name];
          object.material.map = texture;
          object.material.map.needsUpdate = true;
          resolve(null);
        });
      },

      //根据name设置mapTexture
      setMapCanvasTextureByName(name, canvas) {
        let object = _this.object3Dchilds[name];
        let texture = new THREE.CanvasTexture(canvas);
        let geometry = object.geometry.clone();
        let designMaterial = object.material.clone();
        texture.minFilter = THREE.LinearFilter;
        // 复制变换操作
        var newPosition = object.position.clone();
        var newRotation = object.rotation.clone();
        var newScale = object.scale.clone();

        for (let i = 0; i < _this.fabricMaps.length; i++) {
          delete designMaterial[_this.fabricMaps[i]];
        }

        designMaterial.side = THREE.FrontSide;
        designMaterial.dithering = true;

        designMaterial.transparent = true; // 设置材质为透明
        designMaterial.opacity = 0.9; // 设置透明度，可根据需要调整

        designMaterial.needsUpdate = true;
        designMaterial.map = texture;

        if (_this.modelType === 'glb') {
          designMaterial.map.flipY = false;
        }
        // designMaterial.map.offsetX = 20

        let mesh = new THREE.Mesh(geometry, designMaterial);
        let parent = object.parent;
        mesh.name = 'design_mesh_' + object.name;
        mesh.position.copy(newPosition);
        mesh.rotation.copy(newRotation);
        mesh.scale.copy(newScale);
        // mesh.renderOrder = 1;

        // 使用uv坐标移动到0,1
        //  // 计算UV坐标的范围
        _this.mapUV(geometry);
        parent.add(mesh);
        mesh.castShadow = true;
        mesh.receiveShadow = false;
        _this.designObject3Dchilds[object.name] = mesh;
        return {
          //更新map
          needsUpdate(timeout = 0) {
            if (designMaterial) {
              setTimeout(() => {
                designMaterial.map.needsUpdate = true;
              }, timeout);
            }
          },
        };
      },
    };
  }

  //设置摄像机位置
  setCamera(x, y, z) {
    this.camera.position.set(x, y, z);
  }

  setModelMap(name) {
    let child = this.object3Dchilds[name];
    let geometry = cloneDeep(child.geometry);
    let designMaterial = cloneDeep(child.material);
    designMaterial.side = THREE.FrontSide;
    designMaterial.dithering = true;
    designMaterial.transparent = true;
    designMaterial.needsUpdate = true;
    // designMaterial.opacity = 0.5
    let mesh = new THREE.Mesh(geometry, designMaterial);
    let parent = child.children;
    mesh.name = 'm_mesh_' + child.name;
    const loader = new THREE.TextureLoader();
    loader.load(
      'http://resource.moreplay.com.cn/files/a5aa6bc55a77966e6cb37d50f370e842.jpeg',
      (t) => {
        mesh.normalMap = t;
        mesh.needsUpdate = true;
      }
    );

    parent.add(mesh);
  }

  maxAbsoluteValue(num1, num2, num3) {
    var absNum1 = Math.abs(num1);
    var absNum2 = Math.abs(num2);
    var absNum3 = Math.abs(num3);

    var maxAbs = Math.max(absNum1, absNum2, absNum3);

    if (maxAbs === absNum1) {
      return num1;
    } else if (maxAbs === absNum2) {
      return num2;
    } else {
      return num3;
    }
  }

  //加载模型，自动区分格式
  loadModel(
    path,
    onProgress,
    character_model_url,
    character_maps_url,
    isShowLine = true
  ) {
    let loader = null;
    if (path.indexOf('.obj') !== -1) {
      this.modelType = 'obj';
      loader = new OBJLoader();
    } else if (path.indexOf('.fbx') !== -1) {
      this.modelType = 'fbx';
      loader = new FBXLoader();
    } else {
      this.modelType = 'glb';
      loader = new GLTFLoader();
    }
    return new Promise((resolve, reject) => {
      loader.load(
        path,
        async (objects) => {
          if (this.modelType === 'glb') {
            objects = objects.scene;
          }
          //模型包围盒
          let modelBox3 = new THREE.Box3();
          var meshBox3 = new THREE.Box3();
          modelBox3.expandByObject(objects);
          // 计算模型的中心点坐标，这个为爆炸中心
          let modelWorldPs = new THREE.Vector3()
            .addVectors(modelBox3.max, modelBox3.min)
            .multiplyScalar(0.5);
          for (let i = 0; i < objects.children.length; i++) {
            let child = objects.children[i];
            if (child.isMesh) {
              meshBox3.setFromObject(child);
              //获取每个mesh的中心点，爆炸方向为爆炸中心点指向mesh中心点
              let worldPs = new THREE.Vector3()
                .addVectors(meshBox3.max, meshBox3.min)
                .multiplyScalar(0.5);
              if (isNaN(worldPs.x)) return;
              //计算爆炸方向
              child.worldDir = new THREE.Vector3()
                .subVectors(worldPs, modelWorldPs)
                .normalize();
              //保存初始坐标
              child.userData.oldPs = child.getWorldPosition(
                new THREE.Vector3()
              );
              if (!child?.material?.normalMap) {
                child.material = new THREE.MeshPhongMaterial({
                  side: THREE.DoubleSide,
                  shininess: 0,
                })
              }
              child.castShadow = true;
              child.receiveShadow = false;
              this.object3Dchilds[child.name] = child;
            }
          }
          console.log('objects', objects)
          this.modelAnimation = objects.animations || null;
          this.modelObject = objects;
          //将模型添加到容器对象中
          this.container.add(objects);
          this.scene.add(this.container);
          this.setContentCenter(objects);
          // this.setModelPositionSize(objects)
          // this.playgroudMesh = new THREE.Mesh(new THREE.PlaneGeometry(800, 800), new THREE.MeshPhongMaterial({ color: 0xffffff, side: THREE.DoubleSide, aoMapIntensity: 0 }));
          // this.playgroudMesh.rotation.x = - Math.PI /2;
          // this.playgroudMesh.receiveShadow = true;
          //           // this.playgroudMesh.position.y =objects.position.y
          // this.playgroudMesh.position.y =this.maxAbsoluteValue(objects.position.y,objects.position.x,objects.position.z)
          // this.camera.position.y=0
          // this.scene.add(this.playgroudMesh);
          const that = this;
          if (character_model_url) {
            let model_loader = null;
            if (character_model_url.indexOf('.obj') !== -1) {
              model_loader = new OBJLoader();
            } else if (character_model_url.indexOf('.fbx') !== -1) {
              model_loader = new FBXLoader();
            } else {
              model_loader = new GLTFLoader();
            }
            model_loader.load(character_model_url, function (characterModelObject) {
              characterModelObject.traverse((child) => {
                if (child.isMesh) {
                  child.castShadow = true;
                  child.receiveShadow = true;
                }
              });
              if (character_maps_url) {
                var textureLoader = new THREE.TextureLoader();
                textureLoader.load(character_maps_url, function (texture) {
                  characterModelObject.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                      child.material.map = texture;
                    }
                  });
                  that.container.add(characterModelObject);
                  that.scene.add(that.container);
                  var positionX = that.modelObject.position.x;
                  var positionY = that.modelObject.position.y;
                  var positionZ = that.modelObject.position.z;
                  characterModelObject.position.set(positionX, positionY, positionZ);
                  that.characterModelObject = characterModelObject;
                });
              } else {
                that.container.add(characterModelObject);
                that.scene.add(that.container);
                var positionX = objects.position.x;
                var positionY = objects.position.y;
                var positionZ = objects.position.z;
                characterModelObject.position.set(positionX, positionY, positionZ);
                that.characterModelObject = characterModelObject;
              }
              if (isShowLine) {
                // var objBoundingBox = new THREE.Box3().setFromObject(obj2);
                // var objSize = objBoundingBox.getSize(new THREE.Vector3());
                // const maxLine = Math.max(objSize.x, objSize.y, objSize.z);
                // // 将盒子的尺寸设置为OBJ模型的尺寸
                // var boxGeometry = new THREE.BoxGeometry(
                //   maxLine,
                //   maxLine,
                //   maxLine
                // );
                // var edges = new THREE.EdgesGeometry(boxGeometry);
                // var line = new THREE.LineSegments(
                //   edges,
                //   new THREE.LineBasicMaterial({
                //     color: 0x0000ff,
                //     linewidth: 10,
                //     antialias: true,
                //   })
                // );
                // line.position.copy(obj2.position);
                // line.rotation.y = THREE.Math.degToRad(25);
                // that.scene.add(line);
              }
              resolve(that.action());
            },'',(error) => {
              console.log('error11', error)
            });
          } else {
            // if (isShowLine) {
            //   let positionX = objects.position.x;
            //   let positionY = objects.position.y;
            //   let positionZ = objects.position.z;
            //   var objBoundingBox = new THREE.Box3().setFromObject(objects);
            //   var objSize = objBoundingBox.getSize(new THREE.Vector3());
            //   const maxLine = Math.max(objSize.x, objSize.y, objSize.z);
            //   // 将盒子的尺寸设置为OBJ模型的尺寸
            //   var boxGeometry = new THREE.BoxGeometry(
            //     maxLine,
            //     maxLine,
            //     maxLine
            //   );

            //   var edges = new THREE.EdgesGeometry(boxGeometry);
            //   var line = new THREE.LineSegments(
            //     edges,
            //     new THREE.LineBasicMaterial({
            //       color: 0x0000ff,
            //       linewidth: 2,
            //     })
            //   );
            //   line.position.set(positionX, 0, positionZ);
            //   // line.rotation.y = THREE.Math.degToRad(25);
            //   that.scene.add(line);
            // }

            resolve(that.action());
          }

          // setTimeout(() => ), 500)
        },
        (xhr) => {
          let p = (xhr.loaded / xhr.total) * 100;
          onProgress(p.toFixed(2));
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  //销毁
  destroy() {
    this.scene?.remove(this.modelObject);
    // this.contron?.dispose()
    this.render?.dispose();
    cancelAnimationFrame(this.rafId);
  }

  //重置模型位置
  resetModelPosition() {
    this.container.rotation.x = this.cachePosition.mx;
    this.container.rotation.y = this.cachePosition.my;
    this.container.position.y = this.cachePosition.py;
    this.container.position.x = this.cachePosition.px;
    this.camera.position.z = this.cachePosition.cz;
  }

  //获取模型位置
  getModelPosition() {
    return {
      rx: cloneDeep(this.container.rotation.x),
      ry: cloneDeep(this.container.rotation.y),
      py: cloneDeep(this.container.position.y),
      px: cloneDeep(this.container.position.x),
      z: cloneDeep(this.camera.position.z),
    };
  }

  //设置模型位置
  setModelPosition(p) {
    if (!p) {
      return;
    }
    this.container.rotation.x = p.rx || 0;
    this.container.rotation.y = p.ry || 0;
    this.container.position.y = p.py || 0;
    this.container.position.x = p.px || 0;
    this.camera.position.z = p.z || 0;
  }

  //模型垂直居中
  setContentCenter(object) {
    object.updateMatrixWorld()
    const box = new THREE.Box3().setFromObject(object);
    // const box = new THREE.Box3().setFromObject(this.container);
    const center = box.getCenter(new THREE.Vector3());
    //点移到几何中心
    object.position.sub(center);
    const boxSize = box.getSize(new THREE.Vector3());
    if (boxSize.x > boxSize.y) {
      this.camera.position.z = boxSize.x * 2.8;
    } else {
      this.camera.position.z = boxSize.y * 2.8;
    }
    this.container.position.y = 3
    this.cachePosition.mx = cloneDeep(this.container.rotation.x);
    this.cachePosition.my = cloneDeep(this.container.rotation.y);
    this.cachePosition.px = cloneDeep(this.container.position.x);
    this.cachePosition.py = cloneDeep(this.container.position.y);
    this.cachePosition.cz = cloneDeep(this.camera.position.z);
  }
  // 设置模型定位缩放大小
	setModelPositionSize(object) {
		//设置模型位置
		object.updateMatrixWorld()
		const box = new THREE.Box3().setFromObject(object);
		const size = box.getSize(new THREE.Vector3());
		const center = box.getCenter(new THREE.Vector3());
		// 计算缩放比例
		const maxSize = Math.max(size.x, size.y, size.z);
		const targetSize = 2.5; // 目标大小
		const scale = targetSize / (maxSize > 1 ? maxSize : .5);
		object.scale.set(scale, scale, scale)
		// 设置模型位置
		object.position.sub(center.multiplyScalar(scale))
		// 设置控制器最小缩放值
		this.contron.maxDistance = size.length() * 10
		// 设置相机位置
		// this.camera.position.set(0, 2, 6)
		// 设置相机坐标系
		this.camera.updateProjectionMatrix();

	}

  FitCameraToObject(obj, camera) {
    //calc cam pos from Bounding Box
    const BB = new THREE.Box3().setFromObject(obj);

    let centerPoint = new THREE.Vector3();
    centerPoint = BB.getCenter(centerPoint);

    let size = new THREE.Vector3();
    size = BB.getSize(size);

    const backupSize = Math.max(size.x, size.y);
    const backup =
      backupSize / 2 / Math.sin((camera.fov / 2) * (Math.PI / 180));
    const camZpos = BB.max.z + backup + camera.near;

    //move cam
    camera.position.set(centerPoint.x, centerPoint.y, -camZpos * 1.4);
    camera.far = camera.near + 100 * size.z;
    camera.updateProjectionMatrix();
  }

  //
  setRenderCodes = (codes) => {
    this.renderCodes = codes;
  };

  //初始化选择部位的阴影效果
  initComposer() {
    this.composer = new EffectComposer(this.render);
    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);
    this.outlinePass = new OutlinePass(
      new THREE.Vector2(this.canvasWidth, this.canvasHeight),
      this.scene,
      this.camera
    );
    // this.outlinePass.edgeStrength = 4.6;
    // this.outlinePass.visibleEdgeColor.set("rgb(245, 119, 102)");
    // this.outlinePass.hiddenEdgeColor.set("#5336dc");
    // this.outlinePass.edgeThickness = 4.4
    // this.outlinePass.edgeGlow = 1
    // this.outlinePass.pulsePeriod = 1
    this.outlinePass.edgeStrength = 4;
    this.outlinePass.visibleEdgeColor.set(new THREE.Color(0x1677ff, 1.0, 0));
    this.outlinePass.hiddenEdgeColor.set(new THREE.Color(0x1677ff, 1.0, 0));
    this.outlinePass.edgeThickness = 1;
    this.outlinePass.edgeGlow = 1;

    this.composer.addPass(this.outlinePass);
    this.effectFXAA = new ShaderPass(FXAAShader);
    this.effectFXAA.uniforms['resolution'].value.set(
      1 / this.canvasWidth,
      1 / this.canvasHeight
    );
    this.composer.addPass(this.effectFXAA);
  }

  modelZoomIn() {
    if (
      !(
        this.camera.position.z + 1 > this.zoomMin &&
        this.camera.position.z + 1 < this.zoomMax
      )
    ) {
      return;
    }
    // this.container.scale.z += 0.1
    // this.container.scale.x += 0.1
    // this.container.scale.y += 0.1
    this.camera.position.z += 1;
  }

  modelZoomOut() {
    if (
      !(
        this.camera.position.z - 1 > this.zoomMin &&
        this.camera.position.z - 1 < this.zoomMax
      )
    ) {
      return;
    }

    // this.container.scale.z -= 0.1
    // this.container.scale.x -= 0.1
    // this.container.scale.y -= 0.1

    this.camera.position.z -= 1;
  }

  modelZoom(zoom) {
    let step = zoom * 5;
    if (this.lastZoom <= zoom) {
      if (
        !(
          this.camera.position.z - step > this.zoomMin &&
          this.camera.position.z - step < this.zoomMax
        )
      ) {
        return;
      }
      this.camera.position.z -= step;
    } else {
      if (
        !(
          this.camera.position.z + step > this.zoomMin &&
          this.camera.position.z + step < this.zoomMax
        )
      ) {
        return;
      }
      this.camera.position.z += step;
    }
  }

  // 缩放事件的处理
  getDistance(start, stop) {
    //计算两根手指之间的距离
    return Math.sqrt(
      Math.pow(Math.abs(start.x - stop.x), 2) +
        Math.pow(Math.abs(start.y - stop.y), 2)
    );
  }

  //截图
  screePhoto() {
    return new Promise((resolve, reject) => {
      this.render?.domElement.toBlob((file) => {
        resolve(file);
      });
    });
  }

  //截图base64
  async screePhoto64() {
    return new Promise((reslove, reject) => {
      this.render.setPixelRatio(3);
      this.render.render(this.scene, this.camera);
      setTimeout(() => {
        const file = this.render?.domElement.toDataURL('image/png');
        this.render.setPixelRatio(window.devicePixelRatio);
        this.render.render(this.scene, this.camera);
        reslove(file)
      }, 300)
    })
  }

  //模型分解
  applyScalar(scalar = 0.2) {
    //爆炸公式
    for (let i = 0; i < this.modelObject.children.length; i++) {
      let value = this.modelObject.children[i];
      if (!value.isMesh || !value.worldDir) continue;
      value.position.copy(
        new THREE.Vector3()
          .copy(value.userData.oldPs)
          .add(new THREE.Vector3().copy(value.worldDir).multiplyScalar(scalar))
      );
    }
  }

  isWithinMaxAngle(x) {
    if (x < -0.7) {
      return false;
    }
    if (x > 1) {
      return false;
    }
    return true;
  }

  // mousemoveModelHandle(event) {
  //   // 如果没有按下鼠标，则退出函数
  //   if (!this.isDragging) {
  //     return;
  //   }
  //   // 计算鼠标移动的距离
  //   var deltaMove = {
  //     x: Math.sign(event.clientX - this.previousMousePosition.x),
  //     y: Math.sign(event.clientY - this.previousMousePosition.y),
  //   };
  //   if (!this.contextmenuDown) {
  //     let rotation_speed_ = 5;
  //     // if (!this.isWithinMaxAngle(deltaMove.y * rotation_speed_ + this.container.rotation.x)) {
  //     //     return
  //     // }

  //     const deltaRotation = {
  //       x: (Math.PI / 180) * (deltaMove.y * rotation_speed_),
  //       y: (Math.PI / 180) * (deltaMove.x * rotation_speed_),
  //     };
  //     if (Math.abs(deltaRotation.x) > Math.abs(deltaRotation.y)) {
  //       deltaRotation.y = 0;
  //     } else {
  //       deltaRotation.x = 0;
  //     }
  //     this.currentRotation.x += deltaRotation.x;
  //     this.currentRotation.y += deltaRotation.y;

  //     this.container.rotation.x = this.currentRotation.x;
  //     this.container.rotation.y = this.currentRotation.y;
  //     // if ([deltaMove.x, deltaMove.y].includes(0)) {

  //     // }
  //     // if (deltaMove.x !== 0) {
  //     //     this.container.rotation.y += deltaMove.x * rotation_speed_
  //     //     this.container.rotation.x += 0
  //     // }else{
  //     //     this.container.rotation.x += deltaMove.y * rotation_speed_;
  //     //     this.container.rotation.y +=0
  //     // }
  //     // 将模型绕Y轴旋转
  //     // this.container.rotation.y += deltaMove.x * rotation_speed_;
  //     // // 将模型绕X轴旋转
  //     // this.container.rotation.x += deltaMove.y * rotation_speed_;
  //   } else {
  //     //右键移动
  //     this.container.position.y += deltaMove.y * -1;
  //     this.container.position.x += deltaMove.x * 1;
  //   }
  //   // 更新上一次鼠标位置
  //   this.previousMousePosition = {
  //     x: event.clientX,
  //     y: event.clientY,
  //   };
  // }
}

export default Client3D;
