import { Input, Empty, Divider, Tabs,TabPane,Slider, Tooltip } from '@douyinfe/semi-ui';
import { useContext, useEffect, useState } from 'react';
import C from '../context'
import Tab from '../../../components/tab'

const Index = (props) => {
    const {allHide = false, rowCount = 3, pr = 8} = props
    const ic = useContext(C)

    const itemChange = (val) => {
        ic.setCurCode(val)
        ic?.fclients[ic.curCode]?.discardActiveObject()
        if(!val){ //全部
            return
        }
        // ic.threeClient.activate(val)
        // const fclient = ic.fclients.get(code)
        // if (!fclient) return
        // const tclient = threeclients.get(code)
        // if (!tclient) return

        // let f = d?.materials?.cutparts?.find((i: any) => i.code === code)
        // setCurCutpartCode(code)
        // setCurCanvasClient(fclient)
        // setCurThreeClient(tclient)
        // if (f.camera) {
        //     threeClientRef?.current?.setCamera(f.camera.x, f.camera.y, f.camera.z)
        // }
        // console.log(fclients)
        // await fclient.addImageById("https://file-1254182580.cos.ap-beijing.myqcloud.com/2021-12-10/1639141117721.png",300)
        // tclient.needsUpdate(500)
        return true

        
    }

    const dataHandle = () => {
        const data = !allHide ? [{id: "", name:"全部"}]:[]
        // for(let i=0;i<ic?.meshs?.length;i++){
        //     let item = ic?.meshs[i]
        //     data.push({
        //         id: item,
        //         name: item
        //     })
        // }
        return data
    }

    return (
        <Tab isVip={props.isVip} data={dataHandle()} value={ic.curCode} onChange={itemChange} width={60} rowCount={rowCount} pr={pr} />
    )
}

export default Index