import {
  Input,
  Tabs,
  TabPane,
  Notification,
  Tooltip,
  Button,
} from '@douyinfe/semi-ui';

const Index = (props) => {
  const {
    data = [],
    width = 86.5,
    value = 0,
    rowCount = 3,
    pr = 12,
    isVip = false,
  } = props;
  return data?.map((v, k) => (
    <Button
      onClick={() => {
        props.onChange(v.id);
      }}
      style={{
        width,
        fontSize: 14,
        marginRight: (k + 1) % rowCount === 0 ? 0 : pr,
        marginBottom: 12,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        color: v.id === value ? isVip?'#fff':'#000' : isVip?'#dfe0d9':'rgba(28,31,35,0.62)',
        background: isVip
          ? isVip == '2'
            ? '#535061'
            : 'rgb(40, 39, 53)'
          : '',
      }}
      key={v.id}
    >
      {v.name}
    </Button>
  ));
};

export default Index;
