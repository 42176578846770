import { Button, Input, Dropdown, Modal, Form, Toast } from '@douyinfe/semi-ui';
import { IconSearch, IconChevronDown, IconCrossStroked } from '@douyinfe/semi-icons';
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { useEffect, useState, useRef } from 'react'
import Tab from '../../components/tab'
import * as tool from '../../lib/tool'
import DefaultAvatar from '../../static/img/default_avatar.png'
import VipIcon from '../../static/img/vip.png'
import { useNavigate } from "react-router-dom";

const Index = (props) => {
    const { listApi = apis.DESIGN_ADMIN_CATEGORY_LIST, hideUser = false,isVip=false } = props
    const n = useNavigate()
    const [category, setCategory] = useState([])
    const [secondCategory, setSecondCategory] = useState([])
    const [threeCategory, setThreeCategory] = useState([])
    const [searchShow, setSearchShow] = useState(false)
    const [curCategory, setCurCategory] = useState(-1)
    const [curSecconCategory, setCurSecconCategory] = useState(0)
    const [curThreeCategory, setCurThreeCategory] = useState(0)
    const [userInfo, setUserInfo] = useState({})
    const [uploadVisible, setUploadVisible] = useState(false)
    const formApiRef = useRef()

    useEffect(()=>{
        //获取分类
        http.post(listApi, {status: 1, is_pub: 1}).then(async cdata => {
            setCategory(tool.handleLevelData(cdata))
        })
        http.post(listApi,{status: 1, super_id: 0, level: 2, is_pub: 1}).then(r => {
            setSecondCategory(r)
        })
        http.post(listApi,{status: 1, super_id: 0, level: 3, is_pub: 1}).then(r => {
            setThreeCategory(r)
        })
        http.get(apis.USER_INFO).then(uinfo => {
            setUserInfo(uinfo)
        })
    },[])

    const categoryChange = async (v, level = 'category_id') => {
        let cpSearch = {}
        cpSearch.page = 1
        cpSearch[level] = v
        if(level === 'category_id'){
            cpSearch.second_category_id = 0
            cpSearch.three_category_id = 0
            setCurSecconCategory(0)
            setCurThreeCategory(0)
            setThreeCategory([])
            http.post(listApi,{status: 1, super_id: v, level: 2, is_pub: 1}).then(r => {
                setSecondCategory(r)
            })
            http.post(listApi,{status: 1, super_id: 0, level: 3, is_pub: 1}).then(r => {
                setThreeCategory(r)
            })
        }
        if(level === 'second_category_id'){
            cpSearch.three_category_id = 0
            setCurThreeCategory(0)
            http.post(listApi,{status: 1, super_id: v, level: 3, is_pub: 1}).then(r => {
                setThreeCategory(r)
            })
        }
        props.onSearch(cpSearch)
        setSearchShow(false)
        if(level === 'category_id'){
            setCurCategory(v)
        }
        if(level === 'second_category_id'){
            setCurSecconCategory(v)
        }
        if(level === 'three_category_id'){
            setCurThreeCategory(v)
        }
    }

    const handleOk = async () => {
        try {
            const data = await formApiRef.current.validate()
            if(data.new_password2 !== data.new_password){
                Toast.info("两次输入密码不一致")
                return
            }
            await http.post(`${apis.CHANGE_PASSWORD}?new_password=${data.new_password}&old_password=${data.old_password}`)
            Toast.success("密码修改成功")
            setTimeout(()=>{
                //window.location.replace("/")
                n("/login")
            },100)
        } catch (error) {
            //console.log(error)
        }
    }

    return (
        <div style={{
            height: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            width: "100%"
        }}>
            <Modal
                title={"修改密码"}
                visible={uploadVisible}
                onOk={handleOk}
                onCancel={() => setUploadVisible(false)}
                closeOnEsc={true} 
            >
                <div className="hideScroll" style={{maxHeight: "50vh", overflow:"auto"}}>
                    <Form getFormApi={(formApi) => formApiRef.current = formApi} autoComplete="off" style={{ width: "100%" }}>
                        {({ formState, values, formApi }) => (
                            <>
                                <Form.Input 
                                    field='old_password' 
                                    label="原密码"
                                    style={{ width: '100%' }} 
                                    placeholder='请输入'
                                    type="password"
                                    rules={[
                                        { required: true, message: '请输入' }
                                    ]}
                                />
                                <Form.Input 
                                    field='new_password' 
                                    label="新密码"
                                    style={{ width: '100%' }} 
                                    placeholder='请输入'
                                    type="password"
                                    rules={[
                                        { required: true, message: '请输入' }
                                    ]}
                                />
                                <Form.Input 
                                    field='new_password2' 
                                    label="确认新密码"
                                    style={{ width: '100%' }} 
                                    placeholder='请输入'
                                    type="password"
                                    rules={[
                                        { required: true, message: '请输入' }
                                    ]}
                                />
                            </>
                        )}
                    </Form>
                </div>
            </Modal>
            <div style={{width: "100%",paddingRight: 12, background: isVip?'#282735':"#e9e9e9", height: 48, borderRadius: 30, display: "flex", alignItems: "center"}}>
                <Input onChange={props.inputSearch} placeholder="输入关键字搜索..." className={isVip?'semi-always-dark':'semi-always-light'} style={{
                    height: 48,
                    borderRadius: 30,
                    width: "100%",
                    background: "none",
                    border: "none",
                   color:isVip? "#6D6A78":'#000000',
                }} prefix={<IconSearch style={{
                    fontSize: 22,
                    color:isVip? "#6D6A78": "#000"
                }} />} showClear />
                <div style={{
                    width: 2,
                    background: "rgb(216, 216, 216)",
                    height: 32
                }}></div>
                <div style={{flexShrink: 0}}>
                    <Button onClick={()=>{
                        setSearchShow(!searchShow)
                    }} style={{background: "none", color:isVip? "#6D6A78":"#111111"}} icon={<IconChevronDown style={{
                        color:isVip? "#6D6A78":"#111111",
                        transform: !searchShow ? "rotate(-0deg)":"rotate(180deg)"
                    }} />}>筛选</Button>
                </div>
            </div>
            <div style={{
                flexShrink: 0,
                paddingLeft: 12,
                display: hideUser ? "none":"block"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div style={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        position: "relative"
                    }}>
                        <img style={{
                            width: "100%",
                            height: "100%"
                        }} src={userInfo.avatar || DefaultAvatar} />
                        {
                            userInfo.have_private === 1 && <img style={{
                                position: "absolute",
                                top: -24,
                                zIndex: 11,
                                right: -28,
                                width: 55,
                                pointerEvents: "none"
                            }} src={VipIcon} />
                        }
                    </div>
                    <div>
                        <Dropdown
                            // visible
                            // trigger={'focus'}
                            position={'bottom'}
                            render={
                                <Dropdown.Menu style={{width: 200}}>
                                    <Dropdown.Item disabled>
                                        <div>
                                            <div style={{color: "#111111", fontSize: 12}}>目前登录账号</div>
                                            <div style={{height: 76,borderRadius:8, display: "flex", alignItems: "center"}}>
                                                <div>
                                                    <img style={{
                                                        width: 60,
                                                        height: 60,
                                                        borderRadius: "50%"
                                                    }} src={userInfo.avatar || DefaultAvatar} />
                                                </div>
                                                <div style={{paddingLeft:8}}>
                                                    <div style={{
                                                        width: 110,
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        color: "#333333"
                                                    }}>{userInfo.name}</div>
                                                    <div style={{
                                                        width: 110,
                                                        fontSize: 12,
                                                        color:"#5f5f5f",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}>{userInfo.company_name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={async ()=>{
                                        setUploadVisible(true)
                                    }}>修改密码</Dropdown.Item>
                                    {
                                        userInfo.have_private === 1  && <Dropdown.Item onClick={() => n("/vip/design")}>
                                            <div className="vip-text">
                                                    我的私库
                                                </div>
                                        </Dropdown.Item>
                                    }
                                    <Dropdown.Item onClick={async ()=>{
                                        await http.post(apis.USER_LOGOUT)
                                        localStorage.clear()
                                        n("/login")
                                    }}>退出登录</Dropdown.Item>
                                </Dropdown.Menu>
                            }
                        >
                            <Button style={{background: "none"}} icon={<IconChevronDown style={{color: "#000"}} />}></Button>
                        </Dropdown> 
                    </div>
                </div>
            </div>
            
            <div style={{
                position: "absolute",
                background: isVip?"#282735":"rgb(255, 255, 255, 0.9)",
                        //  background:"rgb(255, 255, 255, 0.9)",
                top: 70,
                boxShadow: "0px 1px 4px rgba(0,0,0,0.1)",
                padding: 12,
                borderRadius: 8,
                display: searchShow ? "block":"none",
                width: "100%",
                zIndex: 99
            }}>
                <div style={{height: 30}}>
                    <Button onClick={()=> setSearchShow(false)} style={{background: "none",float: "right"}} icon={<IconCrossStroked style={{color:isVip? "#6D6A78": "#111111"}} />}></Button>
                </div>
                <div style={{fontSize: 14, fontWeight: 500,     color:isVip? "#6D6A78": "#000"}}>一级分类</div>
                <div style={{height: 12}}></div>
                <Tab  isVip={isVip?'2':false} rowCount={0} data={[{id: -1, name: "全部", super_id: 0},...category]} value={curCategory} onChange={v => categoryChange(v,'category_id')} />
                {
                    secondCategory.length !== 0 && <>
                        <div style={{fontSize: 14, fontWeight: 500,     color:isVip? "#6D6A78": "#000"}}>二级分类</div>
                        <div style={{height: 12}}></div>
                        <Tab  isVip={isVip?'2':false} rowCount={0} data={[{id: 0, name: "全部", super_id: 0}, ...secondCategory]} value={curSecconCategory} onChange={v => categoryChange(v,'second_category_id')} />
                    </>
                }
                {
                    threeCategory.length !== 0 && <>
                        <div style={{fontSize: 14, fontWeight: 500,  color:isVip? "#6D6A78": "#000"}}>三级分类</div>
                        <div style={{height: 12}}></div>
                        <Tab isVip={isVip?'2':false}  rowCount={0} data={[{id: 0, name: "全部", super_id: 0}, ...threeCategory]} value={curThreeCategory} onChange={v => categoryChange(v,'three_category_id')} />
                    </>
                }
            </div>
        </div>
    )
}

export default Index