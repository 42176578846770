import React, { useState } from "react";
import bg from '../../static/img/bg.png'
import logo from '../../static/img/logo2.png'
import { Form, Toast, Button } from '@douyinfe/semi-ui';
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)


  const handleSubmit = async (values) => {
    setLoading(true)
    try {
        const data = await http.post(apis.LOGIN,{...values,computer_tag:window.navigator.userAgent.replace(/[^\w]/gi, '')})
        localStorage.setItem('token',data.token)
        navigate("/")
        //window.location.replace("/")
    } catch (error) {
        
    }
    setLoading(false)
  };

  return (
    <>
        <div style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto"
        }}>
            <img src={bg} style={{
                objectFit: "cover",
                filter: "blur(20px)",
                opacity: 0.3,
                width: "100%",
                height: "100%"
            }} />
        </div>
        <div style={{
            width: 960,
            height: 593,
            margin: 'auto',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
            borderRadius: 24,
            backgroundColor: '#FFFFFF',
            overflow: "hidden",
            display: "flex"
        }}>
            <div style={{
                width: 593,
                height: "100%"
            }}>
                <img src={bg} style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%"
                }} />
            </div>
            <div style={{width: 367}}>
                <div style={{
                    width: 250,
                    margin: "0 auto",
                    marginTop: 40
                }}>
                    <img src={logo} style={{width: "100%", height: "100%"}} />
                </div>
                <div style={{
                    width: 250,
                    margin: "0 auto",
                    display: "block"
                }}>
                    <Form autoComplete="off" onSubmit={values => handleSubmit(values)} style={{ width: "100%" }}>
                        {({ formState, values, formApi }) => (
                            <>
                                <Form.Input 
                                    field='phone' 
                                    noLabel
                                    style={{ width: '100%' }} 
                                    placeholder='请输入账号'
                                    rules={[
                                        { required: true, message: '请输入' }
                                    ]}
                                />
                                <Form.Input 
                                    field='password' 
                                    noLabel
                                    style={{ width: '100%' }}
                                    placeholder='请输入密码'
                                    type="password"
                                    rules={[
                                        { required: true, message: '请输入' }
                                    ]} 
                                />

                                <Form.Checkbox field='agree' noLabel>
                                    <span style={{color: "#333333",fontSize: 12}}>登录即代表同意<span style={{cursor: "pointer", color: "rgb(0,100,250)"}} onClick={() => window.location.href = "/xieyi"}>《用户隐私协议》</span></span>
                                </Form.Checkbox>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p>
                                        <span></span>
                                    </p>
                                    <Button disabled={!values.agree} loading={loading} htmlType='submit' type="tertiary">登录</Button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    </>
  );
};
export default Index;