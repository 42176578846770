import { Button, Spin, Toast } from '@douyinfe/semi-ui';
import { IconUpload, IconTick, IconCrossStroked } from '@douyinfe/semi-icons';
import Animate from '../../../components/animate'
import C from '../context'
import { useContext, useEffect, useRef, useState, useImperativeHandle } from 'react';
import {TYPES} from '../left_menu'
import { cloneDeep } from 'lodash'
import * as apis from '../../../lib/api'
import http from '../../../lib/http'
import Tab from '../../../components/tab'
import md5 from 'md5';
import * as cache from '../../../lib/cache'


const Index = (props) => {
    const ic = useContext(C)
    const [curCode, setCurCode] = useState(-1)
    const [loading, setLoading] = useState(false)
    const [curItems, setCurItems] = useState([])
    const categoryDiv = useRef()

    const loader = async (attr) => {
        for(let code in attr){
            await ic.threeClient.replaceGeometry(code, attr[code].path)
        }
        
    }

    const confirm = () => {
        let attrData = cloneDeep(cache.get(cache.ATTR_DATA))
        if(md5(JSON.stringify(attrData.itemsAttr)) !== md5(JSON.stringify(ic.itemsAttr))){
            attrData.itemsAttr = ic.itemsAttr
            ic.setSteps(attrData)
        }
        ic.onMenuContentClose(TYPES.PART)
    }

    const cancel = async (e) => {
        e.preventDefault()
        let attrData = cache.get(cache.ATTR_DATA)
        if(md5(JSON.stringify(attrData.itemsAttr)) !== md5(JSON.stringify(ic.itemsAttr))){
            ic.setLoading(true)
            ic.setLoadingTxt("加载中...")
            ic.setItemsAttr(attrData.itemsAttr)
            await loader(attrData.itemsAttr)
            ic.setLoading(false)
        }
        ic.onMenuContentClose(TYPES.PART)
    }

    useImperativeHandle(props.action, () => ({
        loader,
        fullData,
        confirm
    }));

    //初始化数据
    const fullData = (itemAttr = {}, meshs = ic.meshs) => {
        const obj = {}
        for(let i=0;i<meshs.length;i++){
            let code = meshs[i]
            if(!obj[code]){
                obj[code] = {}
            }
            if(!itemAttr[code]){
                itemAttr[code] = {}
            }
            obj[code].path = itemAttr[code].path || ""
            obj[code].md5 = itemAttr[code].md5 || ""
        }
        return obj
    }

    const choiceFabric = async (v) => {
        console.log(v)
        ic.setLoading(true)
        ic.setLoadingTxt("部件加载中...")
        try {
            let itemsAttr = cloneDeep(ic?.itemsAttr)
            itemsAttr[v.code].path = v.path
            itemsAttr[v.code].md5 = v.md5
            ic.setItemsAttr(itemsAttr)
            await loader(itemsAttr)
        } catch (error) {
            console.log(error)
            Toast.error("部件加载失败")
        }
        ic.setLoading(false)
    }
 
    const handleData = () => {
        const arr = [{"code":"", txt:"全部", items: [], id:-1, name:"全部"}]
        for(let i=0;i<ic?.designData?.model_items?.length;i++){
            let item = ic?.designData?.model_items[i]
            item.id = i
            item.name = item.txt
            if(item.items && item.items.length !== 0){
                for(let j=0;j<item.items.length;j++){
                    item.items[j].code = item.code
                }
                arr[0].items = [...arr[0].items, ...item.items]
                arr.push(item)
            }
        }
        return arr
    }

    const onChoiceCode = (id,arr) => {
        setCurCode(id)
        let f = arr.find(v => v.id === id)
        setCurItems(f.items)
    }

    useEffect(()=>{
        if(ic.curMenu === TYPES.PART){
            onChoiceCode(-1,handleData())
        }
    },[ic.curMenu])

    return (
        <Animate showType="flex" show={ic.curMenu === TYPES.PART} style={{
            flexDirection: "column",
            justifyContent: "center",
            position: "fixed",
            width: 300,
            left: 70,
            top: 60,
            bottom: 0
        }}>
            <div style={{height: "96%",overflow:"hidden"}}>
                <Spin spinning={loading}>
                    <div style={{
                        color: "#333333",
                        fontSize: 14,
                        padding: "0px 8px",
                        display:"flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Button style={{height: 20, width: 20}} onClick={cancel} icon={<IconCrossStroked style={{fontSize: 12,}} />}></Button>
                        <div>部件库</div>
                        <Button style={{height: 20, width: 20}} onClick={confirm} icon={<IconTick style={{fontSize: 12}} />}></Button>
                    </div>
                    <div style={{
                        padding: "12px 8px"
                    }}>
                        <Tab   isVip={props.isVip} value={curCode} data={handleData() || []} onChange={(v) => onChoiceCode(v,handleData())} />
                        <div>
                            <div className="hideScroll" style={{
                                height: document.body.clientHeight - (categoryDiv?.current?.clientHeight || 0) - document.body.clientHeight*0.04 - 162,
                                overflow: "auto",
                                position: "relative"
                            }}>
                                {
                                    !curItems || (curItems && curItems.length === 0) ? 
                                    <div style={{textAlign: "center", paddingTop: 5, fontSize: 12}}>暂无部件~</div>
                                    :
                                    <>
                                        {
                                            curItems?.map((v,k) => (
                                                <div key={k} style={{
                                                    cursor: "pointer", 
                                                    display: "inline-block",
                                                    marginRight: ((k+1)%4 === 0) ? 0:5,
                                                    marginBottom: 5
                                                    // float: "left",
                                                    // width: 62
                                                }} onClick={(e)=>{
                                                    e.preventDefault()
                                                    choiceFabric(v)
                                                }}>
                                                    <img style={{
                                                        width: 65, 
                                                        height: 65,
                                                        border: "solid 1px rgba(255,255,255,0.8)",
                                                        borderRadius: 5
                                                    }} src={process.env.REACT_APP_OSS_URL+v.cover+ "?x-oss-process=image/resize,w_100"} />
                                                    <div style={{textAlign: "center"}}>{v.name}</div>
                                                </div>
                                            ))
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
            
        </Animate>
    )
}

export default Index