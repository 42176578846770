import styles from './index.module.css';
import { Modal, Form, Toast, Spin, Button, Dropdown, Popconfirm, TextArea } from '@douyinfe/semi-ui';
import C from '../context';
import {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { cloneDeep } from 'lodash';
import * as cache from '../../../lib/cache';
import * as tool from '../../../lib/tool';
import http from '../../../lib/http';
import * as apis from '../../../lib/api';
import Upload from '../../../components/upload';
import md5 from 'md5';
import OSS from 'ali-oss';
import { useNavigate } from 'react-router-dom';
import { TYPES } from '../left_menu';
import FilerobotImageEditor from '../../../filerobot-image-editor';
import AddFashionShowModal from './addFashionShowModal';

const Index = (props) => {
  const [rotationOpen, setRotationOpen] = useState(false);
  const [scalar, setScalar] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [category, setCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  /** 多角度图 */
  const [visible, setVisible] = useState(false);
  const [imageSource, setImageSource] = useState('')
  const [activeAdjustRadius, setActiveAdjustRadius] = useState('b-front')
  const [savedImageName, setSavedImageName] = useState('')
  const [describeValue, setDescribeValue] = useState('')
  const [describeShowValue, setDescribeShowValue] = useState('')

  /** 开启走秀 区分视频走秀&模型走秀 */
  const [walking, setWalking] = useState(false);
  const [videoWalking, setVideoWalking] = useState(false);
  const navigate = useNavigate();
  const formApiRef = useRef();
  const ic = useContext(C);
  const [orderVisible, setOrderVisible] = useState(false);
  const orderFormRef = useRef();
  const walkVideoRef = useRef()
  const addFashionShowModalRef = useRef();

  useImperativeHandle(props.action, () => ({
    slideData,
  }));

  useEffect(() => {
    if (ic.designData) {
      setDescribeShowValue(ic.designData?.describe)
    }
  }, [ic, ic.designData])

  const initOrderValues = {
    unitPrice: 0,
    number: 0,
    amount: 0,
  };
  const backDisabled = () => {
    let stepNum = cache.getStepNum();
    if (stepNum <= 0) {
      return true;
    }
    return false;
  };

  const forwardDisabled = () => {
    if (cache.getStepNum() + 1 === (cache.get(cache.STEPS) || []).length) {
      return true;
    }
    return false;
  };

  const slideData = async (curStep) => {
    ic.setLoading(true);
    ic.setLoadingTxt('加载中...');
    //回退面料数据
    ic.setFabricAttr(curStep.fabric);
    await ic.fabricLoader(curStep.fabric);

    //回退图案数据
    ic.setCanvasAttr(curStep.pattern);
    await ic.canvasLoader(curStep.pattern);

    //回退背景数据
    ic.setBgAttr(curStep.bg);
    await ic.bgLoader(curStep.bg);

    //回退部件数据
    ic.setItemsAttr(curStep.itemsAttr);
    await ic.partLoader(curStep.itemsAttr);

    ic.setLoading(false);
  };

  const back = async (e) => {
    e.preventDefault();
    if (backDisabled()) {
      return;
    }
    let steps = cache.get(cache.STEPS) || [];
    let stepNum = cache.getStepNum();
    stepNum--;
    let curStep = steps[stepNum];
    await slideData(curStep);
    cache.set(cache.STEP_NUM, stepNum);
    ic.setUpdate(new Date().getTime());
  };

  const forward = async (e) => {
    e.preventDefault();
    if (forwardDisabled()) {
      return;
    }
    let steps = cache.get(cache.STEPS) || [];
    let stepNum = cache.getStepNum();
    stepNum++;
    let curStep = steps[stepNum];
    await slideData(curStep);
    cache.set(cache.STEP_NUM, stepNum);
    ic.setUpdate(new Date().getTime());
  };

  const resetData = async () => {
    let category_id = [
      ic.designData.pub_category_id,
      ic.designData.second_pub_category_id,
      ic.designData.three_pub_category_id,
    ];
    if (ic.userInfo?.have_private === 1) {
      category_id = [
        ic.designData.category_id,
        ic.designData.second_category_id,
        ic.designData.three_category_id,
      ];
    }

    await tool.sleep(300);
    //获取blob
    const blob = await ic?.threeClient?.screePhoto();
    //上传效果图
    const uuid = await http.get(apis.UUID);
    const filename = `files/${md5(uuid)}.png`;
    const ossData = await ic?.ossClient?.put(filename, blob);

    let data = {
      id: ic.designData.id,
      name: ic.designData.name,
      path: ic.designData.path,
      tags: ic.designData?.tags ? ic.designData?.tags.split(',') : [],
      category_id,
      cover: ossData.name,
      describe: ic.designData?.describe,
    };
    let new_category_id = [];
    for (let i = 0; i < data.category_id.length; i++) {
      if (data.category_id[i] !== 0) {
        new_category_id.push(data.category_id[i]);
      }
    }
    data.category_id = new_category_id;

    if (!isSelfDesign()) {
      data.category_id = [];
    }

    formApiRef.current.setValues(data);
  };

  // console.log("isFullScreen",tool.isFullScreen())
  //发布作品
  const readyPublishDesign = async (e) => {
    if (
      ic.curMenu === TYPES.FABRIC ||
      ic.curMenu === TYPES.AFABRIC ||
      ic.curMenu === TYPES.FINISHED
    ) {
      ic.fabrciAttrApi.confirm();
    }
    if (ic.curMenu === TYPES.PATTERN || ic.curMenu === TYPES.DRAW) {
      ic.canvasAttrApi.confirm();
    }
    if (ic.curMenu === TYPES.BAG) {
      ic.bgAttrApi.confirm();
    }
    if (ic.curMenu === TYPES.PART) {
      ic.partAttrApi.confirm();
    }

    //如果是3D插件，校验物料填写数据
    if (ic.userInfo?.role === 5) {
      try {
        let data = await ic?.printApi?.getFormApi()?.validate();
        for (let i = 0; i < data?.cutparts?.length; i++) {
          let item = data?.cutparts[i];
          if (!item.txt.trim()) {
            ic.leftMenuApi.choiceMenu(e, { key: TYPES.PRINT });
            Toast.error('请填写版片名称');
            return;
          }
        }
      } catch (error) {
        ic.leftMenuApi.choiceMenu(e, { key: TYPES.PRINT });
        return;
      }
    }

    ic.setLoadingTxt('获取分类中');
    ic.setLoading(true);
    try {
      //获取分类
      let cdata = await http.post(
        ic.userInfo?.have_private === 1 || ic.userInfo?.role === 5
          ? apis.DESIGN_CATEGORY_LIST
          : apis.DESIGN_ADMIN_CATEGORY_LIST,
        { status: 1, is_pub: 1 }
      );
      setCategory(tool.handleLevelData(cdata));
      setUploadVisible(true);
    } catch (error) {}
    ic.setLoading(false);
    setTimeout(async () => {
      setLoading(true);
      await resetData();
      setLoading(false);
    }, 500);
  };

  const onDescChange = (content) => {
    setDescribeValue(content)
  }

  const handleOk = async () => {
    //如果是3D插件，获取物料数据
    let product_materials;
    if (ic.userInfo?.role === 5) {
      try {
        let data = await ic?.printApi?.getFormApi()?.validate();
        product_materials = data;
      } catch (error) {
        console.log(error);
      }
    }
    let designData = {};
    designData.fabricAttr = ic.fabricAttr;
    designData.canvasAttr = ic.canvasAttr;
    designData.bgAttr = ic.bgAttr;
    designData.itemsAttr = ic.itemsAttr;
    designData.model_url = ic.designData.model_url;
    designData.camera = cloneDeep(ic.threeClient.getCameraPosition());
    const uuid = await http.get(apis.UUID);
    const filename = `files/${md5(uuid)}.json`;
    const ossData = await ic?.ossClient?.put(
      filename,
      OSS.Buffer(JSON.stringify(designData))
    );
    if (!ossData.name) {
      Toast.error('提交失败,请重试');
      return;
    }
    try {
      const data = await formApiRef.current.validate();
      await http.post(apis.DESIGN_CREATE, {
        id: ic.designData.id,
        light_type: ic.lightAttr,
        model_id: ic.designData.model_id,
        name: data.name,
        path: filename,
        describe: data.describe,
        tags: data?.tags?.join(),
        category_id: data.category_id[0] ? data.category_id[0] : 0,
        second_category_id: data.category_id[1] ? data.category_id[1] : 0,
        three_category_id: data.category_id[2] ? data.category_id[2] : 0,
        cover: data.cover,
        product_materials: product_materials
          ? JSON.stringify(product_materials)
          : null,
      });
      setUploadVisible(false);
      Toast.success('操作成功');
    } catch (error) {
      console.log(error);
    }
  };

  const saveToMy = async (e) => {
    debugger;
    if (ic.userInfo?.role !== 4 && ic.userInfo?.role !== 5) {
      Toast.info('您不是会员，暂不能保存到个人空间');
      return;
    }
    if (
      ic.curMenu === TYPES.FABRIC ||
      ic.curMenu === TYPES.AFABRIC ||
      ic.curMenu === TYPES.FINISHED
    ) {
      ic.fabrciAttrApi.confirm();
    }
    if (ic.curMenu === TYPES.PATTERN || ic.curMenu === TYPES.DRAW) {
      ic.canvasAttrApi.confirm();
    }
    if (ic.curMenu === TYPES.BAG) {
      ic.bgAttrApi.confirm();
    }
    if (ic.curMenu === TYPES.PART) {
      ic.partAttrApi.confirm();
    }

    //如果是3D插件，校验物料填写数据
    if (ic.userInfo?.role === 5) {
      try {
        let data = await ic?.printApi?.getFormApi()?.validate();
        for (let i = 0; i < data?.cutparts?.length; i++) {
          let item = data?.cutparts[i];
          if (!item.txt.trim()) {
            ic.leftMenuApi.choiceMenu(e, { key: TYPES.PRINT });
            Toast.error('请填写版片名称');
            return;
          }
        }
      } catch (error) {
        ic.leftMenuApi.choiceMenu(e, { key: TYPES.PRINT });
        return;
      }
    }

    ic.setLoadingTxt('获取分类中');
    ic.setLoading(true);

    try {
      //获取分类
      let cdata = await http.post(apis.DESIGN_CATEGORY_LIST, {
        status: 1,
        is_pub: 1,
      });
      setCategory(tool.handleLevelData(cdata));
      setUploadVisible(true);
    } catch (error) {}

    ic.setLoading(false);
    setTimeout(async () => {
      setLoading(true);
      await resetData();
      setLoading(false);
    }, 500);
  };

  const titleTxt = () => {
    if (ic?.params?.get('publish')) {
      return '作品发布';
    }
    if (ic.userInfo?.have_private === 1) {
      return '保存到vip专属私库';
    }
  };

  const openImageEditor = async () => {
    setActiveAdjustRadius('b-front')
    setSavedImageName('包含背景-前')
    const img = await ic.threeClient.screePhoto64();
    setImageSource(img)
    setVisible(true)
  }

  /** 多角度图 - 角度调整 itemClick */
  const handleClickAdjustItem = async (type, flag) => {
    if (flag) {
      // 设置透明底
      ic.threeClient.setSceneTransparent();
      setActiveAdjustRadius(`o-${type}`)
    } else {
      // 恢复带背景图
      const { cover } = ic.bgAttr
      if (cover) {
        await ic.threeClient.setSceneBg(process.env.REACT_APP_OSS_URL + cover)
      } else {
        ic.threeClient.clearSceneBg();
      };
      setActiveAdjustRadius(`b-${type}`)
    }
    ic.threeClient.container.rotation.x = 0;
    const flagName = flag ? '透明底' : '包含背景'
    switch (type) {
      case 'right':
        setSavedImageName(`${flagName}-右`)
        ic.threeClient.container.rotation.y = Math.PI / 2;
        break;
      case 'left':
        setSavedImageName(`${flagName}-左`)
        ic.threeClient.container.rotation.y = - Math.PI / 2;
        break;
      case 'back':
        setSavedImageName(`${flagName}-后`)
        ic.threeClient.container.rotation.y = Math.PI;
        break;
      case 'front':
        setSavedImageName(`${flagName}-前`)
        ic.threeClient.container.rotation.y = 0
        break;
      default:
        break;
    }
    await tool.sleep(300);
    const img = await ic.threeClient.screePhoto64();
    setImageSource(img)
  }

  const isSelfDesign = () => {
    return ic?.designData?.owner === ic?.userInfo?.company_id;
  };

  /** 多角度调整按钮 */
  const AdjustRadiusBtn = () => {
    return <Dropdown
        trigger={'click'}
        showTick
        position={'top'}
        render={
          <Dropdown.Menu>
            <Dropdown.Title>包含背景</Dropdown.Title>
            <Dropdown.Item active={activeAdjustRadius === 'b-front'} onClick={() => handleClickAdjustItem('front', 0)}>前</Dropdown.Item>
            <Dropdown.Item active={activeAdjustRadius === 'b-back'} onClick={() => handleClickAdjustItem('back', 0)}>后</Dropdown.Item>
            <Dropdown.Item active={activeAdjustRadius === 'b-left'} onClick={() => handleClickAdjustItem('left', 0)}>左</Dropdown.Item>
            <Dropdown.Item active={activeAdjustRadius === 'b-right'} onClick={() => handleClickAdjustItem('right', 0)}>右</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Title>透明底</Dropdown.Title>
            <Dropdown.Item active={activeAdjustRadius === 'o-front'} onClick={() => handleClickAdjustItem('front', 1)}>前</Dropdown.Item>
            <Dropdown.Item active={activeAdjustRadius === 'o-back'} onClick={() => handleClickAdjustItem('back', 1)}>后</Dropdown.Item>
            <Dropdown.Item active={activeAdjustRadius === 'o-left'} onClick={() => handleClickAdjustItem('left', 1)}>左</Dropdown.Item>
            <Dropdown.Item active={activeAdjustRadius === 'o-right'} onClick={() => handleClickAdjustItem('right', 1)}>右</Dropdown.Item>
          </Dropdown.Menu>
        }
    >
        <Button className={styles['FIE_custom-tool-button']}>角度调整</Button>
    </Dropdown>
  }

  return (
    <div style={props.showH5 ? { justifyContent: 'center' } : {}} className={styles.head}>
      <Modal
        title={titleTxt()}
        visible={uploadVisible}
        onOk={handleOk}
        onCancel={() => setUploadVisible(false)}
        closeOnEsc={true}
      >
        <div
          className="hideScroll"
          style={{ maxHeight: '50vh', overflow: 'auto' }}
        >
          <Spin spinning={loading}>
            <Form
              getFormApi={(formApi) => (formApiRef.current = formApi)}
              autoComplete="off"
              style={{ width: '100%' }}
            >
              {({ formState, values, formApi }) => (
                <>
                  {/* {
                                        (ic?.designData?.owner === ic?.userInfo?.company_id && !ic?.params.get("publish")) && <Form.Switch 
                                            initValue={true}
                                            field='is_new' 
                                            label='发布为新作品'
                                        />
                                    } */}
                  <Form.Input
                    field="name"
                    label="作品名称"
                    style={{ width: '100%' }}
                    placeholder="请输入"
                  />
                  <Form.Input
                    field="describe"
                    label="内容描述"
                    style={{ width: '100%' }}
                    placeholder="请输入"
                  />
                  <Form.Cascader
                    field="category_id"
                    label="所属分类"
                    showClear
                    style={{ width: '100%' }}
                    treeData={category}
                    placeholder="请选择"
                    rules={[{ required: true, message: '请选择' }]}
                  />
                  <Form.Select
                    label="标签"
                    placeholder="请输入"
                    field="tags"
                    style={{ width: '100%' }}
                    optionList={[]}
                    allowCreate={true}
                    multiple={true}
                    filter={true}
                  />
                  <Upload
                    showCrop
                    field="cover"
                    label="封面"
                    rules={[{ required: true, message: '请上传' }]}
                  />
                </>
              )}
            </Form>
          </Spin>
        </div>
      </Modal>
      <Modal
        title="多角度图"
        width={1168}
        height={800}
        visible={visible}
        maskClosable={false}
        onCancel={() => {
          setVisible(false)
          // 复位
          ic.threeClient.container.rotation.x = 0;
          ic.threeClient.container.rotation.y = 0
          // 恢复带背景图
          const { cover } = ic.bgAttr
          if (cover) {
            ic.threeClient.setSceneBg(process.env.REACT_APP_OSS_URL + cover)
          } else {
            ic.threeClient.clearSceneBg();
          };
        }}
        bodyStyle={{ padding: '0 0 20px'}}
        footer={null}
      >
        <div style={{ width: '1120px', height: '708px' }}>
          {imageSource && <FilerobotImageEditor
            source={imageSource}
            isVip={props.isVip}
            defaultSavedImageName={savedImageName + '-' + new Date().toLocaleString()}
            adjustRadiusBtn={<AdjustRadiusBtn />}
            onSave={(editedImageObject) => {
              var link = document.createElement('a');
              link.href = editedImageObject.imageBase64;
              link.download = editedImageObject.fullName;
              link.click();
              Toast.success('保存成功')
              setVisible(false)
            }}
          />}
        </div>
      </Modal>
      <Modal
        title="商品下单"
        visible={orderVisible}
        okText={'下单'}
        cancelText={'取消'}
        style={{
          width: '275px',
          height: '320px',
        }}
        onOk={() => {
          Toast.warning('该功能下期上线，敬请期待！');
          setOrderVisible(false);
        }}
        onCancel={() => {
          setOrderVisible(false);
        }}
      >
        <Form
          initValues={initOrderValues}
          ref={orderFormRef}
          labelPosition={'left'}
          labelWidth={'48px'}
          labelAlign={'left'}
          onValueChange={(values) => console.log(values)}
        >
          <Form.InputNumber
            min={0}
            field="unitPrice"
            label="单价"
            onChange={(value) => {
              const number = orderFormRef.current.formApi.getValue('number');
              orderFormRef.current.formApi.setValue('amount', value * number);
            }}
          />
          <Form.InputNumber
            min={0}
            field="number"
            label="数量"
            onChange={(value) => {
              const unitPrice =
                orderFormRef.current.formApi.getValue('unitPrice');
              orderFormRef.current.formApi.setValue(
                'amount',
                value * unitPrice
              );
            }}
          />
          <Form.Input disabled field="amount" label="金额" />
        </Form>
      </Modal>
      <div
        className={styles.headMid}
        style={{
          marginLeft: 6,
          visibility: ic?.params?.get('publish') ? 'hidden' : 'unset',
          display: props.display === 'none' ? 'none' : 'block',
        }}
        onClick={() => window.history.back()}
      >
        <div className={styles.headMidItem}>
          <i className="iconfont">&#xe6ff;</i>
          <div>返回</div>
        </div>
      </div>
      <div className={styles.headMid}>
        <div
          className={styles.headMidItem}
          style={{
            color: backDisabled() ? 'rgba(28,31,35,0.35)' : '#333333',
            display: props.display === 'none' ? 'none' : 'block',
          }}
          onClick={back}
        >
          <i className="iconfont">&#xe68a;</i>
          <div>撤销</div>
        </div>
        <div
          className={styles.headMidItem}
          style={{
            color: forwardDisabled() ? 'rgba(28,31,35,0.35)' : '#333333',
            display: props.display === 'none' ? 'none' : 'block',
          }}
          onClick={forward}
        >
          <i className="iconfont">&#xe654;</i>
          <div>前进</div>
        </div>
        <div
          className={styles.headMidItem}
          style={{ color: rotationOpen && '#0064fa' }}
          onClick={() => {
            ic.threeClient.rotation(!rotationOpen);
            setRotationOpen(!rotationOpen);
          }}
        >
          <i className={`iconfont ${rotationOpen ? 'turn' : ''}`}>&#xe601;</i>
          <div>旋转</div>
        </div>
        {ic?.threeClient?.modelAnimation?.length ? <div
          className={styles.headMidItem}
          style={{ color: walking && '#0064fa' }}
          onClick={() => {
            console.log('ic.threeClient', ic.threeClient)
            if (walking) {
              setWalking(false)
              ic.threeClient.onClearAnimation();
            } else {
              setWalking(true)
              ic.threeClient.onStartModelAnimaion({
                animationName: 'mixamo.com',
                loop: 'LoopRepeat',
                timeScale: 1,
                weight: 1
              });
            }
          }}
        >
          <i className="clo-iconfont">&#xe710;</i>
          <div>走秀</div>
        </div> : null}
        {ic?.walkVideoUrl ? <div
          className={styles.headMidItem}
          style={{ color: videoWalking && '#0064fa' }}
          onClick={() => {
            if (videoWalking) {
              setVideoWalking(false)
              walkVideoRef?.current?.pause()
            } else {
              setVideoWalking(true)
              // 当前时间设置为0
              walkVideoRef.current.currentTime = 3
              walkVideoRef.current?.play()
            }
          }}
        >
          <i className="clo-iconfont">&#xe710;</i>
          <div>视频走秀</div>
        </div> : null}
        <div
          className={styles.headMidItem}
          style={{ color: scalar && '#0064fa' }}
          onClick={() => {
            ic.threeClient.applyScalar(!scalar ? 20.8 : 0);
            setScalar(!scalar);
          }}
        >
          <i className="iconfont">&#xe605;</i>
          <div>拆解</div>
        </div>
        <div
          className={styles.headMidItem}
          onClick={() => {
            // if(ic.resourceData.camera){
            //     let {x,y,z} = ic.resourceData.camera
            //     ic.threeClient.setCamera(x,y,z)
            // }else{
            ic.threeClient.resetModelPosition();
            //}
          }}
        >
          <i className="iconfont">&#xe60a;</i>
          <div>复位</div>
        </div>
        <div
          className={styles.headMidItem}
          onClick={openImageEditor}
          style={{
            display: props.display === 'none' ? 'none' : 'block',
          }}
        >
          <i className="iconfont">&#xeb9a;</i>
          <div>多角度图</div>
        </div>
        <div
          className={styles.headMidItem}
          style={{
            color: TYPES.LIGHT === ic.curMenu && '#0064fa',
            display: props.display === 'none' ? 'none' : 'block',
          }}
          onClick={async (e) => {
            if (TYPES.LIGHT === ic.curMenu) {
              ic.leftMenuApi.choiceMenu(e, { key: '' });
            } else {
              ic.leftMenuApi.choiceMenu(e, { key: TYPES.LIGHT });
            }
          }}
        >
          <i className="iconfont">&#xe628;</i>
          <div>灯光</div>
        </div>
        <div
          className={styles.headMidItem}
          style={{
            color: TYPES.MODEL === ic.curMenu && '#0064fa',
          }}
          onClick={async (e) => {
            // for (let i = 0; i < ic?.designData?.model_items?.length; i++) {
            //   let item = ic.designData.model_items[i];
            //   if (item.isModel) {
            //     ic.threeClient.hideMaterial(
            //       item.code,
            //       TYPES.MODEL === ic.curMenu
            //     );
            //     break;
            //   }
            // }
            if (ic.threeClient?.modelObject2) {
              ic.threeClient.modelObject2.visible =
                !ic.threeClient.modelObject2.visible;
            }
            if (TYPES.MODEL === ic.curMenu) {
              ic?.leftMenuApi?.choiceMenu(e, { key: '' });
            } else {
              ic?.leftMenuApi?.choiceMenu(e, { key: TYPES.MODEL });
            }
          }}
        >
          <i className="iconfont">&#xe61f;</i>
          <div>{TYPES.MODEL === ic.curMenu ? '显示模特' : '隐藏模特'}</div>
        </div>
        <div
          className={styles.headMidItem}
          style={{
            display: props.display === 'none' ? 'none' : 'block',
          }}
        >
          <Popconfirm
              title="备注"
              content={({ initialFocusRef }) => {
                return <TextArea value={describeValue} ref={initialFocusRef} maxCount={100} onChange={onDescChange} showClear/>;
              }}
              onVisibleChange={(visible) => {
                if (visible) {
                  setDescribeValue(ic.designData.describe)
                } 
              }}
              onConfirm={async () => {
                try {
                  await http.post(apis.UPDATE_DESIGN_DESCRIBE, {
                    id: ic.designData.id,
                    describe: describeValue,
                  })
                  const { describe = '' } = await http.get(`${apis.DESIGN_DETAIL}?id=${ic.designData.id}`)
                  // 再次调用 detail接口查询
                  setDescribeShowValue(describe)
                } finally  {
                  Toast.success('修改成功')
                }
              }}
          >
            <i className="clo-iconfont">&#xe7d1;</i>
            <div>备注</div>
          </Popconfirm>
        </div>
        <div
          className={styles.headMidItem}
          style={{
            color: TYPES.PRINT === ic.curMenu && '#0064fa',
            display: ic?.userInfo?.role === 5 ? 'block' : 'none',
          }}
          onClick={async (e) => {
            if (TYPES.PRINT === ic.curMenu) {
              ic.leftMenuApi.choiceMenu(e, { key: '' });
            } else {
              ic.leftMenuApi.choiceMenu(e, { key: TYPES.PRINT });
            }
          }}
        >
          <i className="iconfont">&#xe622;</i>
          <div>生产定价</div>
        </div>
        <div
          style={{
            display: ic?.userInfo?.role === 5 ? 'block' : 'none',
          }}
          className={styles.headMidItem}
          onClick={(e) => {
            e.preventDefault();
            tool.fullscreen();
          }}
        >
          <i className="iconfont">&#xeb99;</i>
          <div>全屏</div>
        </div>
      </div>
      <div
        className={styles.placeOrder}
        style={{
          display: props?.display === 'none' && !props.showH5 ? 'block' : 'none',
        }}
        onClick={() => {
          setOrderVisible(true);
        }}
      >
        下单
      </div>
      <div
        className={styles.headMid}
        style={{
          display: props?.display === 'none' ? 'none' : 'flex',
        }}
      >
        <div
          className={styles.headMidItem}
          onClick={async () => {
            let currentUrl;
            if (ic.fabrciApi.isEdit) {
              let designData = {};
              designData.fabricAttr = ic.fabricAttr;
              designData.canvasAttr = ic.canvasAttr;
              designData.bgAttr = ic.bgAttr;
              designData.itemsAttr = ic.itemsAttr;
              designData.model_url = ic.designData.model_url;
              designData.camera = cloneDeep(ic.threeClient.getCameraPosition());
              //获取blob
              const blob = await ic?.threeClient?.screePhoto();
              //上传效果图
              const coverUuid = await http.get(apis.UUID);
              const coverFilename = `files/${md5(coverUuid)}.png`;
              const cover = await ic?.ossClient?.put(coverFilename, blob);
              const uuid = await http.get(apis.UUID);
              const filename = `files/${md5(uuid)}.json`;
              await ic?.ossClient?.put(
                filename,
                OSS.Buffer(JSON.stringify(designData))
              );
              let data = {
                cover: cover.name,
                id: ic.designData.id,
                name: ic.designData.name,
                path: filename,
                tags: ic.designData?.tags ? ic.designData?.tags : [],
                describe: '',
                model_id: ic.designData.model_id,
                product_materials: null,
                category_id: ic.designData.category_id[0]
                  ? ic.designData.category_id[0]
                  : 0,
                second_category_id: ic.designData.category_id[1]
                  ? ic.designData.category_id[1]
                  : 0,
                three_category_id: ic.designData.category_id[2]
                  ? ic.designData.category_id[2]
                  : 0,
              };
              const reponse = await http.post(apis.DESIGN_CREATE, data);
              currentUrl =
                window.location.origin + '/onlydesign?id=' + reponse?.id;
            } else {
              currentUrl = window.location.href.replace('design', 'onlydesign');
            }

            var textarea = document.createElement('textarea');
            textarea.value = currentUrl;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            Toast.success('链接已复制');
          }}
        >
          <i className="iconfont">&#xe654;</i>
          <div>保存并转发</div>
        </div>
        {(
          <div
            onClick={() => {
              addFashionShowModalRef.current.openModal(ic?.designData)
            }}
            className={styles.headMidItem}
          >
            <i className="clo-iconfont">&#xe602;</i>
            <div>申请走秀</div>
          </div>
        )}
        {ic?.userInfo?.have_private === 1 && !ic?.params?.get('publish') && (
          <div
            onClick={() => {
              navigate('/vip/design');
            }}
            className={styles.headMidItem}
          >
            <i className="iconfont">&#xe60d;</i>
            <div>私库</div>
          </div>
        )}
        {ic?.params?.get('publish') ? (
          <div
            onClick={(e) => {
              readyPublishDesign(e);
            }}
            className={styles.headMidItem}
          >
            <i className="iconfont">&#xe69d;</i>
            <div>发布</div>
          </div>
        ) : (
          <div className={styles.headMidItem} onClick={(e) => saveToMy(e)}>
            <i className="iconfont">&#xe69d;</i>
            <div>保存</div>
          </div>
        )}
      </div>
      <div id="top_describe">
        { describeShowValue || '' }
      </div>
      <div style={{ display: videoWalking ? 'block' : 'none'}}>
        {ic?.walkVideoUrl && <video ref={walkVideoRef} loop preload='auto' width="100%" style={{ position: 'absolute', left: 0, top: 0, width: '100vw', height: '100vh', zIndex: -1 }}>
          <source src={ic?.walkVideoUrl} type="video/mp4" />
          <source src={ic?.walkVideoUrl} type="video/ogg" />
          您的浏览器不支持 HTML5 video 标签。
        </video>}
      </div>
      <AddFashionShowModal funcs={addFashionShowModalRef} />
    </div>
  );
};

export default Index;
