import { Toast, Modal, TabPane,Notification, Button, withField, withFormApi, Form, Image } from '@douyinfe/semi-ui';
import { useContext, useRef, useState } from 'react'
import { IconUpload } from '@douyinfe/semi-icons';
import C from '../../pages/design/context'
import http from '../../lib/http'
import * as apis from '../../lib/api'
import md5 from 'md5'
import Cropper from 'cropperjs';
let cropper
export const Index = (props) => {
    const {size = 1024*10, value = '',accept=".jpg,.png,.jpeg",showCrop = false, ...rest} = props
    const [loading, setLoading] = useState(false)
    const [cropVisible, setCropVisible] = useState(false)
    const ic = useContext(C)
    const fileInput = useRef()
    const cropImg = useRef()
    


    const onChange = async (e) => {
        const file = e.target.files[0]
        e.target.value = ''
        if(file.size/1024 > size){
            Toast.error("文件大小不能超过"+parseInt(file.size/1024)+"M")
            return
        }
        setLoading(true)
        const uuid = await http.get(apis.UUID)
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = md5(uuid) + suffix;
        const data = await ic.ossClient.put(process.env.REACT_APP_OSS_BUCKET_DIR+"/"+filename, file);
        setLoading(false)
        props.onChange(data.name)
        return
    }

    const doCrop = () => {
        setCropVisible(true)
        setTimeout(()=>{
            cropper = new Cropper(cropImg.current, {
                // 宽高比
                // aspectRatio: 16 / 9,
                rotatable: true,
                scalable: true,
                crop(event) {
                    // console.log(event.detail.x);
                    // console.log(event.detail.y);
                    // console.log(event.detail.width);
                    // console.log(event.detail.height);
                    // console.log(event.detail.rotate);
                    // console.log(event.detail.scaleX);
                    // console.log(event.detail.scaleY);
                }
            });
            console.log(cropper);
        },500)
    }

    return (
        <Form.Slot
            {...rest}
        >
            <input accept={accept} onChange={onChange} ref={fileInput} type="file" style={{display: "none"}} />
            <Button loading={loading} onClick={() => {
                fileInput?.current?.click()
            }} icon={<IconUpload />} theme="light">
                点击上传
            </Button>
            {
                (value !== '' && showCrop) && <Button onClick={doCrop} style={{marginLeft: 8}}>裁剪</Button>
            }
            {
                value !== '' && <>
                    <div style={{height: 12}}></div>
                    <div>
                        <Image 
                            width={104}
                            height={104}
                            src={process.env.REACT_APP_OSS_URL + value}
                        />
                    </div>
                </>
            }
            <Modal
                title="裁剪封面"
                visible={cropVisible}
                onOk={()=>{
                    return new Promise((resolve, reject) => {
                        cropper.getCroppedCanvas().toBlob(async (blob) => {
                            const uuid = await http.get(apis.UUID)
                            const filename = md5(uuid) + ".png";
                            const data = await ic.ossClient.put(process.env.REACT_APP_OSS_BUCKET_DIR+"/"+filename, blob);
                            props.onChange(data.name)
                            setCropVisible(false)
                            cropper.destroy()
                            resolve()
                        })
                    })
                }}
                onCancel={() => {
                    cropper.destroy();
                    setCropVisible(false)
                }}
                closeOnEsc={true} 
            >
                <div>
                    <img ref={cropImg} src={process.env.REACT_APP_OSS_URL + value} style={{display: "block", maxWidth: "100%"}} />
                </div>
            </Modal> 
        </Form.Slot>
    )
}


export default withField(Index, { valueKey: 'value', onKeyChangeFnName: 'onChange' })