
const Index = () => {
    return (
        <div className="main-wrap" style={{height: '100vh',overflow: 'auto',padding: 8}}>
            <div className="copy-state-box" data-v-62c6c8ac="" data-v-3bb724ea="">
                <div className="copy-state" data-v-62c6c8ac="">
                    <h2 data-v-62c6c8ac="">猫耳3D用户协议</h2>
                    <p className="indent2" data-v-62c6c8ac="">
                        <span data-v-62c6c8ac="">《猫耳3D用户协议》（以下简称“本协议”）是您（或称“用户”）与猫耳3D之间关于使用猫耳3D各项服务的法律协议。在本协议中，“猫耳3D”是指猫耳3D的运营公司杭州玉承东业网络科技有限公司。欢迎您使用猫耳3D服务，请您在使用前认真阅读以下协议。</span>
                    </p>
                    <h3 data-v-62c6c8ac="">一、猫耳3D用户协议的接受</h3>
                    <p className="indent2" data-v-62c6c8ac="">
                        <b data-v-62c6c8ac="">本协议中与您的权益（可能）存在重大关系的条款（包括免除猫耳3D责任的条款、限制您权利的条款、争议解决条款等），猫耳3D已采用字体加粗的方式来特别提醒您，请您注意重点查阅。如您对本协议有任何疑问，可向猫耳3D客服咨询。</b>
                    </p>
                    <p className="indent2" data-v-62c6c8ac="">
                        <b data-v-62c6c8ac="">请您确保您在使用猫耳3D服务前已仔细阅读、充分理解并毫无保留地接受本协议所有条款。除非您已阅读并接受本协议的所有条款，否则您不得使用猫耳3D提供的任何服务。当您在注册过程中在“已阅读，同意本《猫耳3D用户协议》”处打勾“✓”并按照注册程序成功注册为猫耳3D用户，或您以其他猫耳3D允许的方式实际使用猫耳3D服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与猫耳3D达成协议。</b>
                    </p>
                    <h3 data-v-62c6c8ac="">二、本协议的变更和修改</h3>
                    <p className="indent2" data-v-62c6c8ac="">
                        <b data-v-62c6c8ac="">猫耳3D有权随时对本协议进行修改，并且一旦发生协议条款的变动，猫耳3D将通过网页公告、电子邮件、系统通知、猫耳3D管理账号主动联系、私信、手机短信或常规的信件传送等方式向您提示修改的内容；您如果不同意本协议的修改，可以放弃使用或访问猫耳3D或取消已经获得的服务；如果您选择在本协议变更后继续访问或使用猫耳3D服务，则视为您已经同意并接受本协议的修改。</b>
                    </p>
                    <h3 data-v-62c6c8ac="">三、服务说明</h3>
                    <p className="indent2" data-v-62c6c8ac="">
                        <span data-v-62c6c8ac="">1、猫耳3D为广大用户提供信息存储、内容展示、素材下载、素材交易、展示推广等服务，是一个为用户提供上传、下载、分享3D模型等设计内容的交流平台网站。对于猫耳3D提供给您的具体服务内容，以猫耳3D实际提供的为准。</span>
                    </p>
                    <p className="indent2" data-v-62c6c8ac="">
                        <span data-v-62c6c8ac="">2、猫耳3D运用自己的系统通过互联网向用户提供服务，除非另有明确规定，增强或强化目前服务的任何新功能，包括新产品以及新增加的服务，均无条件地适用本协议。</span>
                    </p>
                    <p className="indent2" data-v-62c6c8ac="">
                        <span data-v-62c6c8ac="">3、用户同意并自愿接受，猫耳3D通过网页公告、电子邮件、系统通知、猫耳3D管理账号主动联系、私信、手机短信或常规的信件传送等方式向用户发送猫耳3D的商品促销或其他相关商业信息。</span>
                    </p>
                    <h3 data-v-62c6c8ac="">四、用户行为</h3>
                    <p className="indent2" data-v-62c6c8ac="">
                        <span data-v-62c6c8ac="">用户需要完成账号注册，才能正常使用猫耳3D提供的服务。任何机构或个人注册和使用的猫耳3D用户账号名称，必须符合《互联网用户账号名称管理规定》，用户必须承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线。</span>
                    </p>
                    <ul className="rule" data-v-62c6c8ac="">
                        <li data-v-62c6c8ac="">
                            <span className="indent2 inherit" data-v-62c6c8ac="">1、用户帐号、密码和安全</span>
                            <ul className="sub-rule" data-v-62c6c8ac="">
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（1）用户必须在猫耳3D完成注册，通过注册获得帐号及密码，才能成为猫耳3D的合法用户。您可以利用该账户，通过猫耳3D上传您的电子素材(包括但不限于3dmax模型、su模型、其他3D模型、贴图、VR全景图、贴图、VARY材质、光域网、CAD施工图、插件、各类信息、文字、图片、软件、音频等，以下简称电子素材），合法正当地使用猫耳3D提供的各项服务功能。</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（2）用户须对在猫耳3D上填写的注册信息的真实性、合法性、有效性承担全部责任，如注册信息发生变化，您应及时更改。用户不得使用他人的信息注册账户，不得使用不雅或不恰当的账户名称注册账户，不得模仿猫耳3D管理人员或其他人员的账户名称注册账户。如发现用户冒用他人或机构的名义恶意注册帐号，猫耳3D有权单方解除本协议，取消您的用户资格，扣除您账户中的全部余额，并要求您赔偿因此给猫耳3D造成的全部损失。</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（3）您知晓并同意：您仅享有账号及账号项下由猫耳3D提供的虚拟产品及服务的使用权，账号及账号项下的虚拟产品及服务的所有权归猫耳3D所有。除猫耳3D明确同意的使用方式外，您不得以其他任何方式（包括但不限于赠与、出借、转让、销售、抵押、继承、许可他人使用等）处置您的账号以及账号项下的虚拟产品及服务。否则，猫耳3D有权单方解除本协议，取消您的用户资格，扣除您账户中的全部余额，并要求您赔偿因此给猫耳3D造成的全部损失。</span>
                                </li>
                                <li className="indent2 flex-dc" data-v-62c6c8ac="">
                                    <p data-v-62c6c8ac="">（4）您应采取合理措施维护您账户密码和帐号的安全，妥善保管自己的账号信息、账号密码以及其他与账号相关的信息、资料，并加强密码安全性，谨防账号泄露或被盗；请您不要将您的帐号、密码转让或出借予他人使用；<b data-v-62c6c8ac="">因用户账号被泄露、被盗或出借予他人而造成的任何损失，猫耳3D不承担责任。</b>
                                    </p>
                                    <p className="indent2" data-v-62c6c8ac="">
                                        <span data-v-62c6c8ac="">用户的密码和帐号遭到未授权的使用或发生其他任何安全问题，用户应当立即通知猫耳3D；用户在每次使用完猫耳3D服务后，应将相关账户安全退出。</span>
                                    </p>
                                    <p data-v-62c6c8ac="">
                                        <b data-v-62c6c8ac="">如因您的原因，造成账号信息、资料、数据的变动、灭失或财产损失等，您应立即通知猫耳3D并自行承担相关法律后果。</b>
                                    </p>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <b data-v-62c6c8ac="">（5）用户对利用密码和帐号所进行的一切活动承担全部责任，不得侵犯包括但不限于猫耳3D在内的任何主体的合法权益。</b>
                                </li>
                            </ul>
                        </li>
                        <li data-v-62c6c8ac="">
                            <span className="indent2 inherit" data-v-62c6c8ac="">2、用户上传电子素材</span>
                            <ul className="sub-rule" data-v-62c6c8ac="">
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">
                                        <b data-v-62c6c8ac="">（1）您同意授权猫耳3D在全球范围内对您上传至猫耳3D上的所有电子素材（含本协议生效前以及本协议生效后的所有电子素材）享有专有许可使用权（您自己以及其他第三方均无权使用），包括但不限于信息网络传播权、制作（含语音/多媒体制作）、改编、汇编、复制、发行、以数字代码形式传播作品等所有著作权法规定的著作权人可以享有的权利。前述专有许可使用权是可转让的专有许可使用权，猫耳3D享有向任何第三方进行全部或部分转许可的权利，且无须另行再征得您同意。授权期限自本协议生效之日起，永久有效。同时，您同意放弃对您上传至猫耳3D上的所有电子素材所享有的署名权，并同意由猫耳3D自行决定对于前述电子素材的具体署名方式，包括但不限于在电子素材上添加包含“猫耳3D”字样的水印等。</b>
                                    </span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">
                                        <b data-v-62c6c8ac="">（2）您专有许可（您自己以及其他第三方均无权行使）猫耳3D在全球范围内对您上传至猫耳3D上的所有电子素材（含本协议生效前以及本协议生效后的所有电子素材）进行维权。前述专有许可是可转让的，猫耳3D享有向任何第三方进行全部或部分转许可的权利，且无须另行再征得您同意。授权期限自本协议生效之日起，永久有效。您保证配合猫耳3D办理维权所需的所有手续。</b>
                                    </span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">
                                        <b data-v-62c6c8ac="">（3）您在此承诺：您上传至猫耳3D的全部电子素材系完全由您原创的，且您有权授予猫耳3D本协议约定的权利且前述权利授予无任何权利瑕疵。您承诺前述电子素材以及授权不会侵犯他人的合法权益（包括但不限于著作权、专利权、商标权、姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等），不会违反国家相关法律法规的规定。</b>
                                    </span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">
                                        <b data-v-62c6c8ac="">（4）您上传的电子素材不得出现以下任一种情况，否则，猫耳3D有权在不提前通知您的前提下，对相关电子素材采取包括但不限于删除、屏蔽、修改相关链接或内容等处理措施，由此造成的损失，由您自行承担，与猫耳3D无关。如因此导致猫耳3D遭受索赔或处罚的，您保证负责全权处理并承担因此给猫耳3D造成的全部损失：</b>
                                    </span>
                                </li>
                                <li className="indent4" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">1）上传违反本协议约定、猫耳3D规定、法律法规或国家政策规定的电子素材的；</span>
                                </li>
                                <li className="indent4" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">2）上传侵犯他人的合法权益（包括但不限于著作权、专利权、商标权、姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等）的电子素材的；</span>
                                </li>
                                <li className="indent4" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">3）上传的内容与主题无关，或者属于无内容的灌水电子素材的；</span>
                                </li>
                                <li className="indent4" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">4）同一个素材上传两次或两次以上的；</span>
                                </li>
                                <li className="indent4" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">5）上传的电子素材属于广告信息或垃圾信息的（是否属于前述信息由猫耳3D认定）；</span>
                                </li>
                                <li className="indent4" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">6）电子素材中包含严重影响其他用户浏览的内容或格式的；</span>
                                </li>
                                <li className="indent4" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">7）其他猫耳3D认为不恰当的情况。</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">
                                        <b data-v-62c6c8ac="">（5）您知晓并同意：猫耳3D不对用户所上传的电子素材的删除或储存失败等承担责任。</b>
                                    </span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（6）其他与电子素材上传相关的规定详见与您签订的《电子素材上传协议》以及其他猫耳3D的协议、规定。</span>
                                </li>
                            </ul>
                        </li>
                        <li data-v-62c6c8ac="">
                            <b className="indent2 inherit" data-v-62c6c8ac="">3、禁止用户从事以下行为：</b>
                            <ul className="sub-rule" data-v-62c6c8ac="">
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（1）反对宪法所确定的基本原则的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（3）损害国家荣誉和利益的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（4）煽动民族仇恨、民族歧视，破坏民族团结的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（7）散布淫秽、赌博、暴力、凶杀、自残、恐怖或者教唆犯罪的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（8）侮辱或者诽谤他人，侵害他人合法权益（如名誉权、肖像权、隐私权等）的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（9）侵害他人知识产权、商业秘密等合法权利的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（10）虚构事实、隐瞒真相以误导、欺骗他人的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（11）发布、传送、传播广告信息及垃圾信息的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（12）有违公序良俗、社会公德或不利于猫耳3D、干扰猫耳3D正常运营的，“有违公序良俗”包括但不限于可能严重误导公众认知、具有欺诈或不正当性质等一切违反道德、善良风俗或社会公共秩序的情形；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（13）其他法律法规禁止的行为。</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <b data-v-62c6c8ac="">如果您出现上述任一种行为的，应当自行承担由此产生的一切法律后果。此外，猫耳3D有权单方解除本协议，取消您的用户资格，扣除您账户中的全部余额，并要求您赔偿因此给猫耳3D造成的全部损失。</b>
                                </li>
                            </ul>
                        </li>
                        <li data-v-62c6c8ac="">
                            <span className="indent2 inherit" data-v-62c6c8ac="">4、用户下载电子素材</span>
                            <ul className="sub-rule" data-v-62c6c8ac="">
                                <li className="indent2" data-v-62c6c8ac="">
                                    <b data-v-62c6c8ac="">（1）您在此承诺：将按照猫耳3D的要求下载电子素材并支付对应的对价（如需）。对于由此获得的电子素材，您保证按照猫耳3D的要求进行使用，不将其用于任何商业、促销、软文、代言、广告宣传或推销等目的，不向第三方提供或许可第三方使用，且使用时须注明电子素材的来源。否则，猫耳3D有权单方解除本协议，取消您的用户资格，扣除您账户中的全部余额，要求您立即停止违约使用电子素材的行为并赔偿因此给猫耳3D造成的全部损失。</b>
                                </li>
                                <li className="indent2 flex-dc inherit" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（2）用户不得违反猫耳3D的规则，使用软件或采取其他不正当手段下载猫耳3D上的电子素材，否则，</span>
                                    <b data-v-62c6c8ac="">猫耳3D有权单方解除本协议，取消您的用户资格，扣除您账户中的全部余额，并要求您赔偿因此给猫耳3D造成的全部损失。</b>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h3 data-v-62c6c8ac="">五、关于隐私</h3>
                    <ul className="rule" data-v-62c6c8ac="">
                        <li className="indent2" data-v-62c6c8ac="">
                            <span data-v-62c6c8ac="">1､当您注册使用猫耳3D及其提供的服务时，需要您自愿提供您的信息。在您的同意及确认下，猫耳3D将通过注册表格等形式要求您提供一些信息资料，您应当积极配合。</span>
                        </li>
                        <li data-v-62c6c8ac="">
                            <p className="indent2" data-v-62c6c8ac="">
                                <b data-v-62c6c8ac="">2､在未经用户授权同意的情况下，猫耳3D不会公开、编辑或透露用户信息，以下情况除外：</b>
                            </p>
                            <ul className="sub-rule" data-v-62c6c8ac="">
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（1）根据执法单位之要求或为公共之目的向相关单位提供相关资料；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（2）由于用户将用户密码告知他人或与他人共享注册帐户，由此导致的任何资料泄露；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（3）由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的资料泄露、丢失、被盗用或被篡改等；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（4）由于与猫耳3D链接的其他网站所造成之资料泄露，猫耳3D无须承担由此而导致的任何法律争议和后果；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（5）为免除用户或者其他单位及个人在生命、身体或财产方面之急迫危险；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（6）为维护猫耳3D（包含猫耳3D、广东图居网络科技股份有限公司及其关联公司）的合法权益；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（7）为履行与您签订的相关协议或其他书面文件所必需的；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（8）在相关权利人提出维权主张并提供初步证据（猫耳3D认为相关证据已充分即可）时；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（9）猫耳3D可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与猫耳3D同等的保护用户隐私的责任，则猫耳3D有权将用户信息等提供给该第三方。</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（10）法律法规等规定的其他情形。</span>
                                </li>
                            </ul>
                        </li>
                        <li className="indent2" data-v-62c6c8ac="">
                            <b data-v-62c6c8ac="">3､在以下情况下，猫耳3D有权搜集、使用用户信息，收集、使用信息是为了向您提供更好、更优、更个性化的服务：</b>
                            <ul className="sub-rule" data-v-62c6c8ac="">
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（1）用户所提供的资料将会被猫耳3D统计、汇总，猫耳3D会不定期地通过用户留下的信息资料同该用户保持联系；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（2）猫耳3D可以将用户信息与第三方数据匹配；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（3）猫耳3D会通过合计用户统计数据向第三方描述猫耳3D服务；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（4）猫耳3D所提供的服务会自动收集有关访问者的信息，这些信息包括访问者人数、访问时间、访问页面、来访地址等，猫耳3D使用这些信息来对服务器进行分析和对网站进行管理；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（5）用户在使用搜索引擎时输入的关键字将不被认为是用户的隐私资料，猫耳3D有权进行收集和使用；</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（6）其他猫耳3D认为需要搜集、使用用户信息的情形。</span>
                                </li>
                            </ul>
                        </li>
                        <li className="indent2" data-v-62c6c8ac="">
                            <span data-v-62c6c8ac="">猫耳3D将遵循国家法律法规，基于合法、合理、诚实信用原则妥善处理或使用用户信息。</span>
                        </li>
                        <li className="indent2" data-v-62c6c8ac="">
                            <span data-v-62c6c8ac="">4､cookie是一个标准的互联网技术，它可以使猫耳3D存储和获得用户登录信息。猫耳3D使用cookie来确保您不会重复浏览到相同的内容并可以获得最新的信息，并确认您是猫耳3D的用户之一。猫耳3D不会使用cookie来跟踪任何用户信息。</span>
                        </li>
                    </ul>
                    <h3 data-v-62c6c8ac="">六、知识产权声明</h3>
                    <ul className="f-flex-li rule" data-v-62c6c8ac="">
                        <li className="indent2 flex-dc" data-v-62c6c8ac="">
                            <b data-v-62c6c8ac="">1、猫耳3D为提供服务所使用的或提供的服务中所包含的包括但不限于文字、图片、图形、音频、字形字体、设计素材、视频资料、广告、域名、程序、专栏目录与名称、内容分类标准及页面设计、编排、软件、技术、商标等的所有知识产权以及其他合法权益归猫耳3D所有或享有（另有说明的除外）。对于前述内容，未经猫耳3D或相关权利人书面许可，任何人不得使用或许可第三方使用。</b>
                            <b data-v-62c6c8ac="">2、您知晓并同意：对于用户经由猫耳3D上传的电子素材，猫耳3D不保证其合法性、正当性、准确性、完整性以及品质。用户在接受猫耳3D服务后，在任何情况下，猫耳3D均不对任何电子素材承担任何责任，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。如您认为相关电子素材损害您合法权益的，应当向对应上传用户主张，但是猫耳3D有权（但无义务）采取包括但不限于删除、屏蔽、修改相关链接或内容等处理方式。</b>
                            <span data-v-62c6c8ac="">3、任何单位或个人如果发现猫耳3D的用户上传的电子素材侵害其合法权益时，请及时与猫耳3D取得联系，并提供具有法律效力的证明材料，包括身份证明、权属证明、具体链接（URL）及详细侵权情况描述，以便猫耳3D作出处理。</span>
                        </li>
                    </ul>
                    <h3 data-v-62c6c8ac="">七、交易声明：</h3>
                    <ul className="f-flex-li rule" data-v-62c6c8ac="">
                        <li className="indent2" data-v-62c6c8ac="">1、代收、代付款说明：猫耳3D对用户在猫耳3D上发生的电子素材交易提供代收、代付款服务。用户注册完成后，可以查询或管理账户内的预付、应收或应付款，用户须提供与账户相对应的经实名认证的支付宝账户方能使用提现功能。</li>
                        <li data-v-62c6c8ac="">
                            <ul className="sub-rule" data-v-62c6c8ac="">
                                <li className="indent2" data-v-62c6c8ac="">2、猫耳3D向用户提供交易款代收代付服务，其中包含：</li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（1）代管：代为保管用户在猫耳3D账户中的款项。</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（2）代收：代为收取其他猫耳3D用户支付的各类款项。</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（3）代付：代为将款项支付给用户指定的其他猫耳3D用户，该支付不可逆转。</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（4）提现：用户在猫耳3D的收益可以提现，当账户满足猫耳3D规定的提现条件时，用户可于每月1-5日发出提现申请，猫耳3D将于收到申请并经审核通过后统一在当月20日，将相应的款项汇入用户预留的收款账户中（如遇节假日将顺延至节后的第一个工作日），猫耳3D不接受其他提现方式。提现通常没有手续费，但若产生手续费，将由用户自行承担。</span>
                                </li>
                                <li className="indent2" data-v-62c6c8ac="">
                                    <span data-v-62c6c8ac="">（5）查询：用户可以在猫耳3D中查询您的猫耳3D账户名下的交易记录，若认为记录有误的，可以要求猫耳3D提供管理后台有关收付款的详细记录。但最终的款项支付服务是由支付宝账户对应的公司或者金融机构提供的，如与猫耳3D记录不符，请向对应公司或金融机构进行核实，猫耳3D对此不承担责任。</span>
                                </li>
                            </ul>
                        </li>
                        <li className="flex-dc" data-v-62c6c8ac="">
                            <p className="indent2" data-v-62c6c8ac="">
                                <span data-v-62c6c8ac="">3、为保障用户交易时的合法权益，用户同意接受以下规则：</span>
                            </p>
                            <p className="indent2" data-v-62c6c8ac="">
                                <span data-v-62c6c8ac="">（1）用户通过猫耳3D预设的交易流程进行交易，猫耳3D会根据用户指示代收、代付款项，上述指示不可撤回或撤销。</span>
                            </p>
                            <p className="indent2" data-v-62c6c8ac="">
                                <span data-v-62c6c8ac="">（2）交易成功后，猫耳3D会将本次交易中用户应得收益存入用户在猫耳3D的账户内，满足本协议约定时，收益可提现至支付宝账户。</span>
                            </p>
                            <p className="indent2" data-v-62c6c8ac="">
                                <span data-v-62c6c8ac="">（3）交易不成功的，猫耳3D会将此次的交易金额返还购买者。</span>
                            </p>
                            <p className="indent2" data-v-62c6c8ac="">
                                <span data-v-62c6c8ac="">4、交易未成功、交易所涉电子素材不符合本协议规定或猫耳3D要求或交易违反其他猫耳3D或法律相关规定，而款项已先行划付至用户的猫耳3D账户名下的，用户同意猫耳3D有权直接从该账户中扣回款项；若此款项已汇入用户的支付宝账户，用户应当在接到猫耳3D书面通知后的3日内将此款项退回。</span>
                            </p>
                            <p className="indent2" data-v-62c6c8ac="">
                                <span data-v-62c6c8ac="">5、因非猫耳3D的过错导致的账户任何损失由用户自行承担，包括但不限于：用户不按照交易提示操作，用户未及时进行交易操作，用户遗忘或泄漏密码，用户的密码被他人破解以及计算机被他人侵入等。</span>
                            </p>
                            <p className="indent2" data-v-62c6c8ac="">
                                <span data-v-62c6c8ac="">6、猫耳3D并非银行或其它金融机构，猫耳3D保管、代收或代付的款项不产生任何利息及其他收益。猫耳3D交易模式下的资金移转均通过支付宝来实现，猫耳3D不因该资金流转途中的风险向用户承担责任。</span>
                            </p>
                            <p className="indent2" data-v-62c6c8ac="">
                                <span data-v-62c6c8ac="">7、用户不得将猫耳3D提供的交易服务用于任何非法目的，同时也不以任何非法方式在猫耳3D上进行交易，不得有侵害他人合法权益之行为，包括但不限于：冒用他人名义或非法使用他人支付宝账户进行交易的，使用电脑病毒攻击猫耳3D或进行其他可能侵害猫耳3D网站及相关系统的行为，以及其他不适当行为等。</span>
                            </p>
                            <p className="indent2" data-v-62c6c8ac="">
                                <span data-v-62c6c8ac="">
                                    <b data-v-62c6c8ac="">8、猫耳3D在发现用户存在异常交易（是否属于异常由猫耳3D认定）时，有权在不提前通知用户的情况下，采取暂停正在进行的交易、取消交易或冻结账户等限制措施，由此造成的损失，由用户自行承担，猫耳3D不承担任何赔偿责任。</b>
                                </span>
                            </p>
                        </li>
                    </ul>
                    <h3 data-v-62c6c8ac="">八、服务变更、中断或终止</h3>
                    <ul className="f-flex-li rule" data-v-62c6c8ac="">
                        <li className="indent2 flex-dc" data-v-62c6c8ac="">
                            <span data-v-62c6c8ac="">1、在服务器的稳定性、网络状况、通讯线路、第三方网站出现问题或存在恶意的网络攻击行为或出现其他不可抗力情形时，用户同意猫耳3D有权随时中断或终止部分或全部的猫耳3D服务（包括收费服务和免费服务）。<b data-v-62c6c8ac="">对于前述中断或终止所造成的一切不便与损失，猫耳3D无须承担任何责任。</b>但若发生该等情形，猫耳3D将尽可能及时通过网页公告、电子邮件、系统通知、猫耳3D管理账号主动联系、私信、手机短信或常规的信件传送等方式通知受到影响的用户。</span>
                            <span data-v-62c6c8ac="">2、您知晓并同意：猫耳3D需要定期或不定期地对提供猫耳3D服务的平台（如互联网网站、应用程序等）以及相关的设备进行检修、维护或升级，<b data-v-62c6c8ac="">如因此类情况而造成服务在合理时间内的中断，猫耳3D无需为此承担任何责任，</b>但猫耳3D应尽可能事先通过网页公告、电子邮件、系统通知、猫耳3D管理账号主动联系、私信、手机短信或常规的信件传送等方式告知相关用户。</span>
                            <b data-v-62c6c8ac="">3、如发生下列任何一种情形，猫耳3D有权采取封禁警告（登录不受限制；正常消费；强制扣除违规收益（如账户余额不足以扣除违规收益的，用户应当在猫耳3D书面通知后的3日内补足）、部分欧币不可提现、账号冻结（不能登录并强制下线；无法消费；强制扣除违规收益（如账户余额不足以扣除违规收益的，用户应当在猫耳3D书面通知后的3日内补足）、冻结资产、无法消费、无法获得收益、无法提现、账号永久封禁（不能登录并强制下线；无法消费；冻结并没收全部账号资产、无法获得收益、无法提现）中的任一一种措施，由此造成的损失由用户自行承担。如在猫耳3D采取封禁警告或账号冻结措施后，相关用户未在猫耳3D要求的时间内纠正违约行为的，猫耳3D有权对其采取账号永久封禁措施。如猫耳3D采取账号永久封禁措施的，猫耳3D有权单方解除本协议，取消您的用户资格，扣除您账户中的全部余额，并要求您赔偿因此给猫耳3D造成的全部损失。</b>
                            <span data-v-62c6c8ac="">（1）用户提供的信息资料不真实、不准确或不完整；</span>
                            <span data-v-62c6c8ac="">（2）猫耳3D有合理理由认为账号使用人并非初始注册用户的；</span>
                            <span data-v-62c6c8ac="">（3）用户违反本协议中与电子素材上传、下载、交易相关的规定、与用户注册相关的规定或者本协议的其他约定或猫耳3D的其他规定的；</span>
                            <span data-v-62c6c8ac="">（4）用户违反法律法规、国家政策的规定的；</span>
                            <span data-v-62c6c8ac="">（5）用户在使用收费服务时，未按规定为其所使用的收费服务支付相关服务费用的；</span>
                            <span data-v-62c6c8ac="">（6）用户上传的电子素材或用户给猫耳3D的授权侵犯他人的合法权益（包括但不限于著作权、专利权、商标权、姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等）或违反国家相关法律法规的规定的；</span>
                            <span data-v-62c6c8ac="">（7）用户存在损害监管部门、国家机关或政府形象的行为；</span>
                            <span data-v-62c6c8ac="">（8）用户以任何方式损害猫耳3D（包含猫耳3D、广东图居网络科技股份有限公司及其关联公司）的商誉或信誉等合法权益；</span>
                            <span data-v-62c6c8ac="">（9）猫耳3D有其他合理理由认为应当采取相应措施的情形。</span>
                            <b data-v-62c6c8ac="">4、如用户在申请开通猫耳3D服务后，在任何连续90日内未实际使用，则猫耳3D有权在法律法规允许的范围内选择采取以下任何一种方式进行处理，由此造成的损失由用户自行承担：</b>
                            <span data-v-62c6c8ac="">（1）回收用户昵称；</span>
                            <span data-v-62c6c8ac="">（2）回收用户账号；</span>
                            <span data-v-62c6c8ac="">（3）停止为该用户提供猫耳3D服务。</span>
                            <span data-v-62c6c8ac="">5、用户选择将猫耳3D账号与猫耳3D合作的第三方账号进行绑定的，除用户自行解除绑定关系外，如发生下列任何一种情形，用户已绑定的第三方账号也会被解除绑定，猫耳3D无需对用户或任何第三方承担任何责任：</span>
                            <span data-v-62c6c8ac="">（1）用户违反法律法规、国家政策、本协议或其他猫耳3D规定的；</span>
                            <span data-v-62c6c8ac="">（2）用户违反第三方用户协议或其相关规定的；</span>
                            <span data-v-62c6c8ac="">（3）第三方账号所属平台要求解除绑定的；</span>
                            <span data-v-62c6c8ac="">（4）第三方账号所属平台或业务已关停的；</span>
                            <span data-v-62c6c8ac="">（5）依据法律法规、国家政策规定或主管部门要求的；</span>
                            <span data-v-62c6c8ac="">（6）猫耳3D有合理理由认为需要解除绑定的。</span>
                            <span data-v-62c6c8ac="">6、用户违反本协议约定或法律规定，应当赔偿给猫耳3D的全部损失包括但不限于直接损失、预期利益损失、用户因违约或违法所得利益、猫耳3D向第三人支付的违约金、赔偿金、罚金及猫耳3D为索赔支出的全部合理费用，包括但不限于律师费、差旅费、取证费、诉讼费等费用。</span>
                        </li>
                    </ul>
                    <h3 data-v-62c6c8ac="">九、免责声明</h3>
                    <ul className="f-flex-li rule" data-v-62c6c8ac="">
                        <li className="indent2 flex-dc" data-v-62c6c8ac="">
                            <b data-v-62c6c8ac="">1､ 用户明确同意其使用猫耳3D服务所存在的风险将完全由其自己承担；因其使用猫耳3D服务而产生的一切后果也由其自己承担。</b>
                            <b data-v-62c6c8ac="">2、您知晓并同意：您与其它用户之间因为使用猫耳3D服务产生的法律关系及法律纠纷与猫耳3D无关，猫耳3D无须承担与此相关的任何责任。</b>
                            <b data-v-62c6c8ac="">3、猫耳3D不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作保证。</b>
                            <b data-v-62c6c8ac="">4、任何通过使用猫耳3D而搜索链接到的第三方网页均系他人制作或提供，您可能从该第三方网页上获得资讯及享用服务，猫耳3D对其合法性概不负责，亦不承担任何法律责任。</b>
                        </li>
                    </ul>
                    <h3 data-v-62c6c8ac="">十、法律适用与争议解决</h3>
                    <ul className="f-flex-li rule" data-v-62c6c8ac="">
                        <li className="indent2 flex-dc" data-v-62c6c8ac="">
                            <span data-v-62c6c8ac="">1、本协议的订立、执行、解释及争议的解决均应适用中华人民共和国法律。</span>
                            <b data-v-62c6c8ac="">2、如就本协议内容或其执行发生任何争议，应尽量通过友好协商解决；协商不成时，则争议各方可向猫耳3D（即广东图居网络科技股份有限公司）所在地人民法院提起诉讼。</b>
                        </li>
                    </ul>
                    <h3 data-v-62c6c8ac="">十一、其他规定</h3>
                    <ul className="f-flex-li rule" data-v-62c6c8ac="">
                        <li className="indent2 flex-dc" data-v-62c6c8ac="">
                            <span data-v-62c6c8ac="">1、猫耳3D可通过网页公告、电子邮件、系统通知、猫耳3D管理账号主动联系、私信、手机短信或常规的信件传送等方式向用户发出通知；该等通知于发送之日视为已送达用户。</span>
                            <span data-v-62c6c8ac="">2、无论本协议中的任何条款因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</span>
                            <b data-v-62c6c8ac="">3、本协议以及猫耳3D的其他协议、规则、声明等文件的修改权、更新权及最终解释权均归属于猫耳3D所有。</b>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Index