import {
  Input,
  Tabs,
  TabPane,
  RadioGroup,
  Radio,
  Button,
  Spin,
  Modal,
  Form,
  Banner,
  Toast,
} from '@douyinfe/semi-ui';
import { IconUpload, IconSearch, IconTick } from '@douyinfe/semi-icons';
import Animate from '../../../components/animate';
import C from '../context';
import {
  useContext,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { TYPES } from '../left_menu';
import { EDIT_MODE } from '../defined';
import { cloneDeep } from 'lodash';
import * as apis from '../../../lib/api';
import http from '../../../lib/http';
import Tab from '../../../components/tab';
import Upload from '../../../components/upload';
import FabricParam from '../../../components/fabric_param';
import * as tool from '../../../lib/tool';
import InfiniteScroll from 'react-infinite-scroller';

let dataLoaded = false;

const cacheData = {
  category: [],
  pubCategory: [],
  categoryTab: '1',
  curCategory: 0,
  data: { list: [] },
  search: { page: 1, page_size: 20, status: 1, category_id: 0, isPub: '1' },
};

const Index = (props) => {
  const ic = useContext(C);
  const [category, setCategory] = useState(cacheData.category);
  const [selfCategory, setSelfCategory] = useState([]);
  const [secondCategory, setSecondCategory] = useState([]);
  const [threeCategory, setThreeCategory] = useState([]);
  const [categoryTab, setCategoryTab] = useState(cacheData.categoryTab);
  const [curCategory, setCurCategory] = useState(0);
  const [curSecconCategory, setCurSecconCategory] = useState(0);
  const [curThreeCategory, setCurThreeCategory] = useState(0);
  const [data, setData] = useState(cacheData.data);
  const [search, setSearch] = useState(cacheData.search);
  const [detailBoxX, setDetailBoxX] = useState(0);
  const [detailBoxY, setDetailBoxY] = useState(0);
  const [moveCurFabric, setMoveCurFabric] = useState({});
  const [categorySlide, setCategorySlide] = useState(false);
  const [fabricTp, setFabricTp] = useState(0);
  const [isTp2, setIsTp2] = useState(true);
  const [loading, setLoading] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const categoryDiv = useRef();
  const formApiRef = useRef();
  const detailBox = useRef();
  const scrollParentRef = useRef();
  const [isEdit, setIsEdit] = useState(false);
  const categoryChange = async (v, level = 'category_id') => {
    if (scrollParentRef.current) {
      scrollParentRef.current.scrollTop = 0;
    }

    let cpSearch = cloneDeep(search);
    cpSearch.page = 1;
    cpSearch.tp = ic?.curMenu === TYPES.FINISHED ? 2 : fabricTp;
    cpSearch[level] = v;
    if (level === 'category_id') {
      cpSearch.second_category_id = 0;
      cpSearch.three_category_id = 0;
      setCurSecconCategory(0);
      setCurThreeCategory(0);
      setThreeCategory([]);
      http
        .post(apis.FABRIC_CATEGORY_LIST, {
          assist: ic?.curMenu === TYPES.AFABRIC ? 1 : 0,
          status: 1,
          super_id: v,
          level: 2,
          is_pub: parseInt(categoryTab),
          tp: ic?.curMenu === TYPES.FINISHED ? 2 : fabricTp,
        })
        .then((r) => {
          setSecondCategory(r);
        });
    }
    if (level === 'second_category_id') {
      cpSearch.three_category_id = 0;
      setCurThreeCategory(0);
      http
        .post(apis.FABRIC_CATEGORY_LIST, {
          assist: ic?.curMenu === TYPES.AFABRIC ? 1 : 0,
          status: 1,
          super_id: v,
          level: 3,
          is_pub: parseInt(categoryTab),
          tp: ic?.curMenu === TYPES.FINISHED ? 2 : fabricTp,
        })
        .then((r) => {
          setThreeCategory(r);
        });
    }
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    console.log(5);
    await loadData(cacheData.search, true);
    if (level === 'category_id') {
      setCurCategory(v);
    }
    if (level === 'second_category_id') {
      setCurSecconCategory(v);
    }
    if (level === 'three_category_id') {
      setCurThreeCategory(v);
    }
    setTimeout(() => {
      ic.setUpdate(new Date().getTime());
    }, 100);
  };

  const loader = async (
    attr,
    c = '',
    tp = 0,
    temperature = 0,
    contrast = 1,
    lightness = 1,
    saturation = 0
  ) => {
    console.log(attr);
    for (let code in attr) {
      if (!code) {
        continue;
      }
      if (c) {
        if (c !== code) continue;
      }
      let item = attr[code];
      //清除之前的贴图
      if (item.maps?.length !== 0) {
        ic.threeClient.clearMap(code);
      }
      //加载贴图
      for (let m = 0; m < item.maps.length; m++) {
        let a = item.maps[m];
        if (!a?.url) {
          continue;
        }
        if (tp == '1') {
          var imageUrl = a.url; // 图片的名称或ID

          if (
            (temperature !== 0 ||
              contrast !== 1 ||
              lightness !== 1 ||
              saturation !== 0) &&
            a.map === 'map'
          ) {
            await ic.threeClient.setMap(
              imageUrl,
              a.map,
              code,
              item.maps.map((e) => e.map)
            );
          } else {
            await ic.threeClient.setMap(
              a.url,
              a.map,
              code,
              item.maps.map((e) => e.map)
            );
          }
        } else {
          await ic.threeClient.setMap(
            a.url,
            a.map,
            code,
            item.maps.map((e) => e.map)
          );
        }
      }
      ic.threeClient.setDisplacementMapAttr(
        code,
        item.displacementScale,
        item.displacementBias
      );
      //设置重复
      ic.threeClient.openRepeat(code, item.repeat);
      if (item.repeat) {
        //设置重复次数
        ic.threeClient.setMapRepeat(code, item.repeatNum, item.repeatNumY);
      } else {
        //设置重复次数
        ic.threeClient.setMapRepeat(code, 1, 1);
      }
      //设置offset
      ic.threeClient.setMapOffset(code, item.offsetX, item.offsetY);
      //设置材质颜色
      ic.threeClient.setMaterialColor(code, item.color);
      //设置透明
      ic.threeClient.setMaterialTransparent(code, item.transparent);
      if (c) {
        break;
      }
    }
  };

  useImperativeHandle(props.action, () => ({
    loader,
    fullData,
    isEdit,
  }));

  //初始化面料数据
  const fullData = (fabricAttr = {}, meshs = ic.meshs) => {
    let cs = meshs;
    const obj = {};
    for (let i = 0; i < cs.length; i++) {
      let item = cs[i];
      obj[item] = {
        offsetX: fabricAttr[item]?.offsetX || 0,
        offsetY: fabricAttr[item]?.offsetY || 0,
        repeat: fabricAttr[item]?.repeat,
        transparent: fabricAttr[item]?.transparent || false,
        repeatNum: fabricAttr[item]?.repeatNum || 26,
        repeatNumY: fabricAttr[item]?.repeatNumY || 26,
        color: fabricAttr[item]?.color || '#FFFFFF',
        displacementScale: fabricAttr[item]?.displacementScale || 0,
        displacementBias: fabricAttr[item]?.displacementBias || 0,
        maps: fabricAttr[item]?.maps || [],
      };
      obj[''] = {
        offsetX: fabricAttr[item]?.offsetX || 0,
        offsetY: fabricAttr[item]?.offsetY || 0,
        repeat: fabricAttr[item]?.repeat,
        transparent: fabricAttr[item]?.transparent || false,
        repeatNum: fabricAttr[item]?.repeatNum || 26,
        repeatNumY: fabricAttr[item]?.repeatNumY || 26,
        displacementScale: fabricAttr[item]?.displacementScale || 0,
        displacementBias: fabricAttr[item]?.displacementBias || 0,
        color: fabricAttr[item]?.color || '#FFFFFF',
      };
      if (obj[item].repeat === undefined || obj[item].repeat === true) {
        obj[item].repeat = true;
      } else {
        obj[item].repeat = false;
      }
      if (obj[''].repeat === undefined || obj[''].repeat === true) {
        obj[''].repeat = true;
      } else {
        obj[''].repeat = false;
      }
    }
    return obj;
  };

  const choiceFabric = async (v) => {
    console.log('choiceFabric', v)
    setIsEdit(true);
    ic.setLoading(true);
    ic.setLoadingTxt(txtShow() + '加载中...');
    if (!ic.curCode) {
      //全部部位
      //替换maps数据
      const cpFabricAttr = cloneDeep(ic.fabricAttr);

      for (let code in cpFabricAttr) {
        if (code) {
          cpFabricAttr[code].maps = v.maps;
        }
        if (v.param) {
          cpFabricAttr[code] = Object.assign(cpFabricAttr[code], v.param);
        }
      }

      if (v.tp === 1) {
        //花型，将颜色贴图替换掉
        for (let code in cpFabricAttr) {
          let modelMaps = ic.threeClient.getMap(code);
          let mapIndex = modelMaps.findIndex((v) => v.map === 'map');
          if (isTp2) {
            if (mapIndex !== -1) {
              modelMaps = [v.maps[0]];
            } else {
              modelMaps = [
                {
                  map: 'map',
                  url: v.maps[0].url,
                },
              ];
            }
          } else {
            //模型上的map
            if (mapIndex !== -1) {
              modelMaps[mapIndex].url = v.maps[0].url;
            } else {
              modelMaps.push({
                map: 'map',
                url: v.maps[0].url,
              });
            }
          }
          cpFabricAttr[code].maps = modelMaps;
          cpFabricAttr[code].isTp2 = false;
        }
        setIsTp2(false);
      }
      if (v.tp === 0) {
        //低胚，将除了map的全部替换
        for (let code in cpFabricAttr) {
          //模型上的map
          let modelMaps = ic.threeClient.getMap(code);
          let mapIndex = modelMaps.findIndex((v) => v.map === 'map');
          let vmapIndex = v.maps.findIndex((e) => e.map === 'map');
          const mapsCopy = JSON.parse(JSON.stringify(v.maps));
          // if (isTp2) {
          //   if (vmapIndex !== -1) {
          //     mapsCopy.splice(vmapIndex, 1);
          //   }
          //   modelMaps = mapsCopy;
          // } else {
          //   if (mapIndex !== -1) {
          //     if (vmapIndex !== -1) {
          //       mapsCopy.splice(vmapIndex, 1);
          //       modelMaps = [modelMaps[mapIndex], ...mapsCopy];
          //     } else {
          //       modelMaps = [modelMaps[mapIndex], ...mapsCopy];
          //     }
          //   } else {
          //     if (vmapIndex !== -1) {
          //       mapsCopy.splice(vmapIndex, 1);
          //       modelMaps = mapsCopy;
          //     } else {
          //       modelMaps = mapsCopy;
          //     }
          //   }
          // }
          modelMaps = mapsCopy;
          cpFabricAttr[code].maps = modelMaps;
          cpFabricAttr[code].isTp2 = false;
        }
        setIsTp2(false);
      }
      if (v.tp === 2) {
        for (let code in cpFabricAttr) {
          cpFabricAttr[code].isTp2 = true;
        }
        setIsTp2(true);
      }
      //更新state数据
      ic.setFabricAttr(cpFabricAttr);
      //加载数据
      await loader(
        cpFabricAttr,
        '',
        v.tp,
        v?.temperature,
        v?.contrast,
        v?.lightness,
        v?.saturation
      );
    } else {
      //更新数据
      const cpFabricAttr = cloneDeep(ic.fabricAttr || {});
      if (!v.param) {
        v.param = {};
      }
      v.param.maps = v.maps;
      cpFabricAttr[ic.curCode] = Object.assign(
        cpFabricAttr[ic.curCode],
        v.param
      );
      if (v.tp === 2) {
        cpFabricAttr[ic.curCode].isTp2 = true;
      }
      if (v.tp === 1) {
        //模型上的map
        let modelMaps = ic.threeClient.getMap(ic.curCode);
        let mapIndex = modelMaps.findIndex((v) => v.map === 'map');
        if (
          cpFabricAttr[ic.curCode]?.isTp2 !== undefined &&
          cpFabricAttr[ic.curCode]?.isTp2 === false
        ) {
          if (mapIndex !== -1) {
            modelMaps[mapIndex].url = v.maps[0].url;
          } else {
            modelMaps = [
              {
                map: 'map',
                url: v.maps[0].url,
              },
            ];
          }
        } else {
          modelMaps = [
            {
              map: 'map',
              url: v.maps[0].url,
            },
          ];
        }
        cpFabricAttr[ic.curCode].maps = modelMaps;
        cpFabricAttr[ic.curCode].isTp2 = false;
      }
      if (v.tp === 0) {
        //模型上的map
        let modelMaps = ic.threeClient.getMap(ic.curCode);
        let mapIndex = modelMaps.findIndex((v) => v.map === 'map');
        let vmapIndex = v.maps.findIndex((e) => e.map === 'map');
        const mapsCopy = JSON.parse(JSON.stringify(v.maps));
        // if (
        //   cpFabricAttr[ic.curCode]?.isTp2 !== undefined &&
        //   cpFabricAttr[ic.curCode]?.isTp2 === false
        // ) {
        //   if (mapIndex !== -1) {
        //     if (vmapIndex !== -1) {
        //       mapsCopy.splice(vmapIndex, 1);
        //       modelMaps = [modelMaps[mapIndex], ...mapsCopy];
        //     } else {
        //       modelMaps = [modelMaps[mapIndex], ...mapsCopy];
        //     }
        //   }
        // } else {
        //   if (vmapIndex !== -1) {
        //     mapsCopy.splice(vmapIndex, 1);
        //     modelMaps = mapsCopy;
        //   } else {
        //     modelMaps = mapsCopy;
        //   }
        // }
        modelMaps = mapsCopy;
        cpFabricAttr[ic.curCode].maps = modelMaps;
        cpFabricAttr[ic.curCode].isTp2 = false;
      }
      //更新state数据
      ic.setFabricAttr(cpFabricAttr);
      //加载数据
      await loader(
        cpFabricAttr,
        ic.curCode,
        v.tp,
        v?.temperature,
        v?.contrast,
        v?.lightness,
        v?.saturation
      );
    }
    ic.setLoading(false);
    ic.setCurEditMode(EDIT_MODE.FABRIC);
  };

  const loadData = async (s, refresh = false) => {
    setLoading(true);
    try {
      s.assist = ic?.curMenu === TYPES.AFABRIC ? 1 : 0;
      s.tp = ic?.curMenu === TYPES.FINISHED ? 2 : s.tp;
      s.assist = ic?.curMenu === TYPES.FINISHED ? 0 : s.assist;
      const d = await http.post(apis.FABRIC_LIST, s);
      for (let i = 0; i < d?.list?.length; i++) {
        let item = d.list[i];
        item.maps = JSON.parse(item.maps);
        if (item.param) {
          item.param = JSON.parse(item.param);
        }
      }
      if (refresh) {
        setData(d);
        cacheData.data = d;
      } else {
        let cpData = cloneDeep(data);
        cpData.list = [...cpData.list, ...d.list];
        setData(cpData);
        cacheData.data = cpData;
      }
    } catch (error) {}
    setLoading(false);
  };

  const tabChange = async (v) => {
    if (scrollParentRef.current) {
      scrollParentRef.current.scrollTop = 0;
    }
    setCurCategory(-1);
    setCurSecconCategory(0);
    setCurThreeCategory(0);
    setSecondCategory([]);
    setThreeCategory([]);
    setCategoryTab(v);
    const s = {
      page: 1,
      page_size: 20,
      status: 1,
      category_id: 0,
      second_category_id: 0,
      three_category_id: 0,
      name: search.name,
      is_pub: parseInt(v),
      tp: ic?.curMenu === TYPES.FINISHED ? 2 : fabricTp,
    };
    setSearch(s);
    cacheData.search = s;
    await loadData(cacheData.search, true);
    //获取分类
    const cdata = await http.post(apis.FABRIC_CATEGORY_LIST, {
      assist: ic?.curMenu === TYPES.AFABRIC ? 1 : 0,
      status: 1,
      is_pub: parseInt(v),
      tp: ic?.curMenu === TYPES.FINISHED ? 2 : fabricTp,
    });
    setCategory(tool.handleLevelData(cdata));
    setTimeout(() => {
      ic.setUpdate(new Date().getTime());
    }, 100);
  };

  const onInputSearch = async (val) => {
    let cpSearch = cloneDeep(search);
    cpSearch.page = 1;
    cpSearch.name = val;
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    console.log(2);
    await loadData(cacheData.search, true);
  };

  const loadMore = async () => {
    let cpSearch = cloneDeep(search);
    cpSearch.page++;
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    console.log(3);
    await loadData(cacheData.search, false);
  };

  const txtShow = () => {
    if (ic.curMenu === TYPES.AFABRIC) {
      return '辅料';
    }
    if (ic.curMenu === TYPES.FINISHED) {
      return '成品面料';
    }
    return '自定义面料';
  };
  useEffect(() => {
    ic.setUpdate(new Date().getTime());
    if (
      ic.curMenu === TYPES.FABRIC ||
      ic.curMenu === TYPES.AFABRIC ||
      ic?.curMenu === TYPES.FINISHED
    ) {
      dataLoaded = true;
      if (props.display === 'none') return;
      tabChange(parseInt(categoryTab));

      //获取分类
      http
        .post(apis.FABRIC_CATEGORY_LIST, {
          assist: ic?.curMenu === TYPES.AFABRIC ? 1 : 0,
          status: 1,
          is_pub: parseInt(categoryTab),
          tp: ic?.curMenu === TYPES.FINISHED ? 2 : fabricTp,
        })
        .then((cdata) => {
          setSelfCategory(tool.handleLevelData(cdata));
        });
    }
  }, [ic.curMenu, categoryTab, fabricTp]);

  const handleOk = async () => {
    setLoading(true);
    try {
      const data = await formApiRef.current.validate();
      const maps = [];
      if (data.map) {
        maps.push({
          map: 'map',
          url: data.map,
        });
      }
      if (data.normalMap) {
        maps.push({
          map: 'normalMap',
          url: data.normalMap,
        });
      }
      if (data.specularMap) {
        maps.push({
          map: 'specularMap',
          url: data.specularMap,
        });
      }
      if (data.aoMap) {
        maps.push({
          map: 'aoMap',
          url: data.aoMap,
        });
      }
      if (data.displacementMap) {
        maps.push({
          map: 'displacementMap',
          url: data.displacementMap,
        });
      }

      if (data.roughnessMap) {
        maps.push({
          map: 'roughnessMap',
          url: data.roughnessMap,
        });
      }
      if (data.metalnessMap) {
        maps.push({
          map: 'metalnessMap',
          url: data.metalnessMap,
        });
      }
      await http.post(apis.FABRIC_CREATE, {
        assist: ic?.curMenu === TYPES.AFABRIC ? 1 : 0,
        name: data.name,
        cover: data.cover,
        category_id: data.category_id[0] ? data.category_id[0] : 0,
        second_category_id: data.category_id[1] ? data.category_id[1] : 0,
        three_category_id: data.category_id[2] ? data.category_id[2] : 0,
        param: JSON.stringify(data.param),
        maps: JSON.stringify(maps),
        code: data.code,
        element: data.element,
        remark: data.remark,
        tp: ic?.curMenu === TYPES.FINISHED ? 2 : 1,
      });
      setUploadVisible(false);
      Toast.success('新建成功');
      tabChange('2');
      choiceFabric({
        maps,
        param: data.param,
        tp: ic?.curMenu === TYPES.FINISHED ? 2 : 1,
      });
    } catch (error) {
      //console.log(error)
    }
    setLoading(false);
  };

  const handleCancel = async () => {
    setUploadVisible(false);
  };

  const readyUpload = () => {
    setUploadVisible(true);
  };

  const showDetail = (e, v) => {
    setDetailBoxX(e.clientX + 20);
    setDetailBoxY(e.clientY + 20);
    setMoveCurFabric(v);
  };

  const setFabricTpRadio = async (e) => {
    let tempFabricTp = e.target.value;
    setFabricTp(tempFabricTp);
    if (scrollParentRef.current) {
      scrollParentRef.current.scrollTop = 0;
    }
    setCurCategory(-1);
    setCurSecconCategory(0);
    setCurThreeCategory(0);
    setSecondCategory([]);
    setThreeCategory([]);
    const s = {
      page: 1,
      page_size: 20,
      status: 1,
      category_id: 0,
      second_category_id: 0,
      three_category_id: 0,
      name: search.name,
      is_pub: search.is_pub,
      tp: ic?.curMenu === TYPES.FINISHED ? 2 : tempFabricTp,
    };
    setSearch(s);
    cacheData.search = s;
    console.log(4);
    await loadData(s, true);
    //获取分类
    const cdata = await http.post(apis.FABRIC_CATEGORY_LIST, {
      assist: ic?.curMenu === TYPES.AFABRIC ? 1 : 0,
      status: 1,
      is_pub: search.is_pub,
      tp: ic?.curMenu === TYPES.FINISHED ? 2 : tempFabricTp,
    });
    setCategory(tool.handleLevelData(cdata));
    setTimeout(() => {
      ic.setUpdate(new Date().getTime());
    }, 100);
  };

  return (
    <Animate
      showType="flex"
      show={
        (ic.curMenu === TYPES.FABRIC ||
          ic.curMenu === TYPES.AFABRIC ||
          ic?.curMenu === TYPES.FINISHED) &&
        props.display !== 'none'
      }
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'fixed',
        width: 300,
        left: 70,
        top: 60,
        bottom: 0,
      }}
    >
      <div
        ref={detailBox}
        style={{
          position: 'fixed',
          display: detailBoxX === 0 ? 'none' : 'block',
          width: 200,
          background: 'rgba(255,255,255,.7)',
          // height: 100,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px',
          zIndex: 999,
          left: detailBoxX,
          top: detailBoxY,
          borderRadius: 5,
          padding: 5,
        }}
      >
        <img
          alt=""
          style={{ width: 200, height: 200 }}
          src={process.env.REACT_APP_OSS_URL + moveCurFabric.cover}
        />
        <div>编号：{moveCurFabric.code || '-'}</div>
        <div style={{ height: 8 }}></div>
        <div>成分：{moveCurFabric.element || '-'}</div>
        <div style={{ height: 8 }}></div>
        <div>备注：{moveCurFabric.remark || '-'}</div>
      </div>
      <Modal
        title={txtShow() + '上传'}
        visible={uploadVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeOnEsc={true}
      >
        <div
          className="hideScroll"
          style={{ maxHeight: '50vh', overflow: 'auto' }}
        >
          <Banner
            type="warning"
            description={`上传的${txtShow()}都将默认存入私库！！！`}
          />
          <Form
            getFormApi={(formApi) => (formApiRef.current = formApi)}
            autoComplete="off"
            style={{ width: '100%' }}
          >
            {({ formState, values, formApi }) => (
              <>
                <Form.Input
                  field="name"
                  label={`${txtShow()}名称`}
                  style={{ width: '100%' }}
                  placeholder="请输入"
                  rules={[{ required: true, message: '请输入' }]}
                />
                <Form.Cascader
                  field="category_id"
                  label="所属分类"
                  showClear
                  style={{ width: '100%' }}
                  treeData={selfCategory}
                  placeholder="请选择"
                  rules={[{ required: true, message: '请选择' }]}
                />
                <Form.Input
                  field="code"
                  label="编号"
                  showClear
                  style={{ width: '100%' }}
                  placeholder="请填写"
                />
                <Form.Input
                  field="element"
                  label="成分"
                  showClear
                  style={{ width: '100%' }}
                  placeholder="请填写"
                />
                <Form.Input
                  field="remark"
                  label="备注"
                  showClear
                  style={{ width: '100%' }}
                  placeholder="请填写"
                />
                <FabricParam
                  field="param"
                  label="默认参数"
                  initValue={{
                    transparent: false,
                    repeatNum: 26,
                    repeatNumY: 26,
                    repeat: true,
                  }}
                />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Upload
                    field="cover"
                    label="封面图"
                    rules={[{ required: true, message: '请上传' }]}
                  />
                  <Upload
                    field="map"
                    label="颜色贴图"
                    rules={[{ required: true, message: '请上传' }]}
                  />
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal>
      <div style={{ height: '96%' }}>
        {props.display !== 'none' && <Spin spinning={loading}>
          <div
            style={{
              color: '#333333',
              fontSize: 14,
              padding: '0px 8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>{txtShow()}库</div>
          </div>
          <div
            style={{
              padding: '12px 8px',
            }}
          >
            <div>
              <Input
                onChange={onInputSearch}
                placeholder="搜索"
                suffix={<IconSearch />}
                showClear
                className={props.isVip ? 'semi-always-dark' : ''}
                style={{
                  background: props.isVip ? '#282735' : '',
                  color: props.isVip ? '#6D6A78' : '',
                }}
              ></Input>
            </div>
            <div ref={categoryDiv}>
              <Tabs type="line" value={categoryTab} onChange={tabChange}>
                <TabPane tab="公库" itemKey="1">
                  <RadioGroup
                    type="button"
                    buttonSize="large"
                    onChange={setFabricTpRadio}
                    value={fabricTp}
                    className={props.isVip ? 'semi-always-dark' : ''}
                    style={{
                      display:
                        ic?.curMenu === TYPES.FINISHED
                          ? 'none'
                          : 'inline-block',
                    }}
                  >
                    <Radio
                      value={0}
                      style={{
                        background: props.isVip ? '#282735' : '',
                        color: props.isVip ? '#fff' : '',
                      }}
                    >
                      底胚
                    </Radio>
                    <Radio
                      value={1}
                      style={{
                        background: props.isVip ? '#282735' : '',
                      }}
                    >
                      花型
                    </Radio>
                  </RadioGroup>
                  <div
                    style={{
                      fontSize: 12,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>一级分类</span>
                    <span
                      onClick={() => {
                        setCategorySlide(!categorySlide);
                        setTimeout(() => {
                          ic.setUpdate(new Date().getTime());
                        }, 500);
                      }}
                      style={{ color: 'rgb(0, 100, 250)', cursor: 'pointer' }}
                    >
                      {categorySlide ? '展开' : '收起'}
                    </span>
                  </div>
                  <div style={{ height: categorySlide && 0 }}>
                    <div style={{ height: 12 }}></div>
                    <Tab
                      isVip={props.isVip}
                      data={[
                        { id: -1, name: '全部', super_id: 0 },
                        ...category,
                      ]}
                      value={curCategory}
                      onChange={(v) => categoryChange(v, 'category_id')}
                    />
                    {secondCategory.length !== 0 && (
                      <>
                        <div style={{ fontSize: 12 }}>二级分类</div>
                        <div style={{ height: 12 }}></div>
                        <Tab
                          isVip={props.isVip}
                          data={[
                            { id: 0, name: '全部', super_id: 0 },
                            ...secondCategory,
                          ]}
                          value={curSecconCategory}
                          onChange={(v) =>
                            categoryChange(v, 'second_category_id')
                          }
                        />
                      </>
                    )}
                    {threeCategory.length !== 0 && (
                      <>
                        <div style={{ fontSize: 12 }}>三级分类</div>
                        <div style={{ height: 12 }}></div>
                        <Tab
                          isVip={props.isVip}
                          data={[
                            { id: 0, name: '全部', super_id: 0 },
                            ...threeCategory,
                          ]}
                          value={curThreeCategory}
                          onChange={(v) =>
                            categoryChange(v, 'three_category_id')
                          }
                        />
                      </>
                    )}
                  </div>
                </TabPane>
                <TabPane tab="私库" itemKey="2">
                  <div>
                    <RadioGroup
                      type="button"
                      buttonSize="large"
                      onChange={setFabricTpRadio}
                      className={props.isVip ? 'semi-always-dark' : ''}
                      value={fabricTp}
                      style={{
                        display:
                          ic?.curMenu === TYPES.FINISHED
                            ? 'none'
                            : 'inline-block',
                      }}
                    >
                      <Radio
                        value={0}
                        style={{
                          background: props.isVip ? '#282735' : '',
                        }}
                      >
                        底胚
                      </Radio>
                      <Radio
                        value={1}
                        style={{
                          background: props.isVip ? '#282735' : '',
                        }}
                      >
                        花型
                      </Radio>
                    </RadioGroup>
                  </div>
                  <div style={{ height: 12 }}></div>
                  <div
                    style={{
                      fontSize: 12,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>一级分类</span>
                    <span
                      onClick={() => {
                        setCategorySlide(!categorySlide);
                        setTimeout(() => {
                          ic.setUpdate(new Date().getTime());
                        }, 500);
                      }}
                      style={{ color: 'rgb(0, 100, 250)', cursor: 'pointer' }}
                    >
                      {categorySlide ? '展开' : '收起'}
                    </span>
                  </div>
                  <div style={{ height: categorySlide && 0 }}>
                    <div style={{ height: 12 }}></div>
                    <Tab
                      isVip={props.isVip}
                      data={[
                        { id: -1, name: '全部', super_id: 0 },
                        ...category,
                      ]}
                      value={curCategory}
                      onChange={(v) => categoryChange(v, 'category_id')}
                    />
                    {secondCategory.length !== 0 && (
                      <>
                        <div style={{ fontSize: 12 }}>二级分类</div>
                        <div style={{ height: 12 }}></div>
                        <Tab
                          isVip={props.isVip}
                          data={[
                            { id: 0, name: '全部', super_id: 0 },
                            ...secondCategory,
                          ]}
                          value={curSecconCategory}
                          onChange={(v) =>
                            categoryChange(v, 'second_category_id')
                          }
                        />
                      </>
                    )}
                    {threeCategory.length !== 0 && (
                      <>
                        <div style={{ fontSize: 12 }}>三级分类</div>
                        <div style={{ height: 12 }}></div>
                        <Tab
                          isVip={props.isVip}
                          data={[
                            { id: 0, name: '全部', super_id: 0 },
                            ...threeCategory,
                          ]}
                          value={curThreeCategory}
                          onChange={(v) =>
                            categoryChange(v, 'three_category_id')
                          }
                        />
                      </>
                    )}
                  </div>
                </TabPane>
              </Tabs>
            </div>
            <div>
              <div
                id="faricList"
                ref={scrollParentRef}
                style={{
                  height:
                    document.body.clientHeight -
                    (categoryDiv?.current?.clientHeight || 0) -
                    document.body.clientHeight * 0.04 -
                    162,
                  overflow: 'auto',
                  position: 'relative',
                }}
              >
                {!data.list || (data.list && data.list.length === 0) ? (
                  <div
                    style={{ textAlign: 'center', paddingTop: 5, fontSize: 12 }}
                  >
                    暂无{txtShow()}~
                  </div>
                ) : (
                  <InfiniteScroll
                    initialLoad={false} // 不让它进入直接加载
                    pageStart={1} // 设置初始化请求的页数
                    loadMore={() => {
                      if (!loading) {
                        loadMore();
                      }
                    }}
                    useWindow={false}
                    hasMore={
                      data.total !== data.list.length && data.total !== 0
                    }
                    getScrollParent={() => scrollParentRef.current}
                    loader={
                      <div style={{ textAlign: 'center' }} key={0}>
                        加载中...
                      </div>
                    }
                  >
                    {data?.list?.map((v, k) => (
                      <div
                        key={v.id}
                        onPointerMove={(e) => showDetail(e, v)}
                        onPointerLeave={() => setDetailBoxX(0)}
                        style={{
                          cursor: 'pointer',
                          display: 'inline-block',
                          marginRight: (k + 1) % 4 === 0 ? 0 : 5,
                          marginBottom: 5,
                          position: 'relative',
                          // float: "left",
                          // width: 62
                        }}
                        onClick={async (e) => {
                          e.preventDefault();
                          await choiceFabric(v);
                          //强制将面料变为原色
                          let cs = ic?.meshs;
                          for (let i = 0; i < cs.length; i++) {
                            let item = cs[i];
                            ic.threeClient.setMaterialColor(item, '#FFFFFF');
                            // ic.threeClient.setMaterialColor(item, '#FFFFFF', {
                            //   s: v.saturation,
                            //   l: v.lightness,
                            // });
                          }
                        }}
                      >
                        <img
                          style={{
                            width: 65,
                            height: 65,
                            border: 'solid 1px rgba(255,255,255,0.8)',
                            borderRadius: 5,
                          }}
                          src={
                            process.env.REACT_APP_OSS_URL +
                            v.cover +
                            '?x-oss-process=image/resize,w_100'
                          }
                        />
                        <div
                          style={{
                            fontSize: 12,
                            textAlign: 'center',
                            width: 67,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            height: 16,
                          }}
                        >
                          {v.name}
                        </div>
                      </div>
                    ))}
                    {
                      data.list.length === data.total && (
                        <div
                          style={{
                            textAlign: 'center',
                            paddingTop: 5,
                            fontSize: 12,
                          }}
                        >
                          没有更多了~
                        </div>
                      )
                      // :
                      // <div style={{textAlign: "center", paddingTop: 5}}>
                      //     <Button theme='borderless'  loading={loading} onClick={loadMore} style={{fontSize: 12}}>点击加载更多</Button>
                      // </div>
                    }
                  </InfiniteScroll>
                )}
              </div>
              <>
                <div style={{ height: 8 }}></div>
                <Button
                  onClick={readyUpload}
                  style={{
                    // position: "absolute",
                    width: '100%',
                    bottom: -30,
                    left: 0,
                    fontSize: 12,
                    background: props.isVip ? '#282735' : '',
                  }}
                >
                  上传{txtShow()}模拟
                </Button>
              </>
            </div>
          </div>
        </Spin>}
      </div>
    </Animate>
  );
};

export default Index;
