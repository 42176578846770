import { RadioGroup, InputNumber, Radio, Slider, Button } from '@douyinfe/semi-ui';
import Animate from '../../../components/animate'
import C from '../context'
import { useContext, useImperativeHandle, useState } from 'react';
import { TYPES } from '../left_menu'

const Index = (props) => {
  const ic = useContext(C)

  const ambientLight = ic.threeClient?.ambientLight
  const directionalLightFront = ic.threeClient?.directionalLightFront
  const directionalLightback = ic.threeClient?.directionalLightback

  // const [lightType, setLightType] = useState(0)
  const [ambientLightIntensity, setAmbientLightIntensity] = useState(0)
  const [directionalLightIntensity, setDirectionalLightIntensity] = useState(0)

  //初始化场景数据
  const fullData = (lightAttr = {}) => {
    const { lightType = 0 } = lightAttr
    return lightType
  }

  const loader = async (lightAttr) => {
    const { lightType = 0 } = lightAttr
    ic.setLightAttr(lightType)
    setAmbientLightIntensity(ambientLight.intensity)
    setDirectionalLightIntensity(directionalLightFront.intensity)
  }

  /** 根据lightType 对模型内部的材质进行调整 */
  const setMaterialByLightType = (type) => {
    const modelObject = ic.threeClient.modelObject
    console.log('modelObject', modelObject)
    for (let i = 0; i < modelObject.children.length; i++) {
      const child = modelObject.children[i];
      const material = child.material
      switch (type) {
        case 0:
          material?.color.set(0xffffff)
          material?.specular.set(0x111111)
          material.shininess = 0;
          break;
        case 1:
          // 羽绒服效果
          // ambientLight.color.set(0x404040)
          material.color.set(0xffffff)
          material.specular.set(0x303030)
          material.shininess = 10;
          break;
        case 2:
          // 哑光10效果
          material.color.set(0xffffff)
          material.specular.set(0x111111)
          material.shininess = 10;
          break;
        case 3:
          // 哑光20效果
          material.color.set(0xffffff)
          material.specular.set(0x222222)
          material.shininess = 20;
          break;
        case 4:
          // PU哑光皮效果
          material.color.set(0xffffff)
          material.specular.set(0x333333)
          material.shininess = 30;
          break;
        case 5:
          // 亮皮效果
          material.color.set(0xffffff)
          material.specular.set(0xffffff) // 设置高光颜色为白色，增强高光效果
          material.shininess = 100;
          break;
        default:
          break;
      }
    }
  }

  const onRadioChange = (val) => {
    ic.setLightAttr(val.target.value)
    setMaterialByLightType(val.target.value)
  }

  const onAmbientLightIntensityChange = (val) => {
    if (ambientLight) ambientLight.intensity = val
    setAmbientLightIntensity(val)
  }

  const onDirectionLightIntensityChange = (val) => {
    if (directionalLightFront) directionalLightFront.intensity = val
    if (directionalLightback) directionalLightback.intensity = val
    setDirectionalLightIntensity(val)
  }

  useImperativeHandle(props.action, () => ({
      fullData,
      loader
  }));

  return (
    <Animate showType="flex" show={ic.curMenu === TYPES.LIGHT} style={{
        position: "fixed",
        width: 240,
        right: 50,
        top: 60,
        bottom: 0,
        margin: "auto",
        flexDirection: "column",
        justifyContent: "center"
    }}>
      <div style={{height: "96%",overflow:"hidden"}}>
        {
          ic.curMenu === TYPES.LIGHT && <>
            <div style={{
                color: "#333333",
                fontSize: 14,
                padding: "5px 8px",
                display:"flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <div>调整灯光</div>
            </div>
            <div style={{
                padding: "12px 8px",
                overflow: "auto",
                // height: "calc(50% - 26px - 60px)"
            }}>
              <div>
                <div>灯光模式</div>
                <div style={{height: 8}}></div>  
                <div>
                  <RadioGroup direction="vertical" onChange={onRadioChange} value={ic.lightAttr} name="radio-group">
                    <Radio value={0}>默认</Radio>
                    <Radio value={1}>羽绒服灯光</Radio>
                    <Radio value={2}>哑光10</Radio>
                    <Radio value={3}>哑光20</Radio>
                    <Radio value={4}>PU哑光皮</Radio>
                    <Radio value={5}>亮皮效果</Radio>
                  </RadioGroup>
                </div>
                <div style={{height: 12}}></div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  环境光亮度
                  <Button size='small' onClick={() => setAmbientLightIntensity(0.6)}>重置</Button>
                </div>
                <div style={{height: 4}}></div>  
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Slider style={{ width: 135, marginRight: '5px' }} size="small" value={ambientLightIntensity} min={0.2} max={1} step={0.01} onChange={onAmbientLightIntensityChange}/>
                  <InputNumber style={{ width: 58 }} size='small' value={ambientLightIntensity} innerButtons min={0.2} max={1} precision={2} onChange={onAmbientLightIntensityChange} />
                </div>
                <div style={{height: 12}}></div>  
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  平行光亮度
                  <Button size='small' onClick={() => setDirectionalLightIntensity(0.6)}>重置</Button>
                </div>
                <div style={{height: 4}}></div>  
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Slider style={{ width: 135, marginRight: '5px' }} size="small" value={directionalLightIntensity} min={0.2} max={1} step={0.01} onChange={onDirectionLightIntensityChange}/>
                  <InputNumber style={{ width: 58 }} size="small" value={directionalLightIntensity} innerButtons min={0.2} max={1} precision={2} onChange={onDirectionLightIntensityChange} />
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </Animate>
  )
}

export default Index