import { Toast } from '@douyinfe/semi-ui';
import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    timeout: 1000 * 60
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    config.headers = {'Authorization': localStorage.getItem('token'),'from':'pc'}
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if(response.data.code === 401){
        // message.error("登录失效")
        // setTimeout(()=>{
        //     window.location.href = "/login"
        // },500)
        window.location.href = "/login"
        // Modal.confirm({
        //     title: "提醒",
        //     content: <p>登录已失效</p>,
        //     cancelButtonProps: {style: {display: 'none'}},
        //     onOk: () => {
        //         window.location.href = "/login"
        //     },
        //     okButtonProps: {style: {background: 'rgb(24, 159, 146)',outline: 'none'}},
        //     okText: "返回登录"
        // })
        return
    }
    if(response.data.code !== 200){
        Toast.error(response.data.msg)
        return Promise.reject(response.data.msg);
    }
    return response.data?.data;
  }, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    Toast.error(error?.message)
    return Promise.reject(error);
});

export default instance