export default {
  name: '名称',
  save: '保存',
  saveAs: '保存为',
  back: '后退',
  loading: '加载中...',
  resetOperations: '重置/删除所有操作',
  changesLoseWarningHint:
    '如果您按下“重置”按钮，您的更改将丢失。 你想继续吗？',
  discardChangesWarningHint:
    '如果关闭模式，您的最后更改将不会被保存。',
  cancel: '取消',
  apply: '引用',
  warning: '注意',
  confirm: '确认',
  discardChanges: '放弃更改',
  undoTitle: '撤消上次操作',
  redoTitle: '重做上次操作',
  showImageTitle: '显示原始图像',
  zoomInTitle: '放大',
  zoomOutTitle: '缩小',
  toggleZoomMenuTitle: '切换缩放菜单',
  adjustTab: '调整',
  finetuneTab: '微调',
  filtersTab: '滤镜',
  watermarkTab: '水印',
  annotateTabLabel: '标注',
  resize: '调整大小',
  resizeTab: '调整大小',
  imageName: '图像名称',
  invalidImageError: '提供的图像无效。',
  uploadImageError: '上传图像时出错。',
  areNotImages: '不是图像',
  isNotImage: '不是图像',
  toBeUploaded: '待上传',
  cropTool: '裁剪',
  original: '原始',
  custom: '自定义',
  square: '正方形',
  landscape: '横向',
  portrait: '纵向',
  ellipse: '椭圆',
  classicTv: '经典电视',
  cinemascope: '电影画幅',
  arrowTool: '箭头',
  blurTool: '模糊',
  brightnessTool: '亮度',
  contrastTool: '对比度',
  ellipseTool: '椭圆',
  unFlipX: '取消水平翻转',
  flipX: '水平翻转',
  unFlipY: '取消垂直翻转',
  flipY: '垂直翻转',
  hsvTool: 'HSV',
  hue: '色调',
  brightness: '亮度',
  saturation: '饱和度',
  value: '明度',
  imageTool: '贴图',
  importing: '导入中...',
  addImage: '+ 添加图像',
  uploadImage: '上传图像',
  fromGallery: '从图库',
  lineTool: '直线',
  penTool: '画笔',
  polygonTool: '多边形',
  sides: '边数',
  rectangleTool: '矩形',
  cornerRadius: '圆角半径',
  resizeWidthTitle: '宽度（像素）',
  resizeHeightTitle: '高度（像素）',
  toggleRatioLockTitle: '切换比例锁定',
  resetSize: '重置为原始图像大小',
  rotateTool: '旋转',
  textTool: '文本',
  textSpacings: '文本间距',
  textAlignment: '文本对齐',
  fontFamily: '字体',
  size: '大小',
  letterSpacing: '字间距',
  lineHeight: '行高',
  warmthTool: '暖色',
  addWatermark: '+ 添加水印',
  addTextWatermark: '+ 添加文本水印',
  addWatermarkTitle: '选择水印类型',
  uploadWatermark: '上传水印',
  addWatermarkAsText: '添加为文本',
  padding: '内边距',
  paddings: '内边距',
  shadow: '阴影',
  horizontal: '水平',
  vertical: '垂直',
  blur: '模糊',
  opacity: '不透明度',
  transparency: '透明度',
  position: '位置',
  stroke: '描边',
  saveAsModalTitle: '另存为',
  extension: '扩展名',
  format: '格式',
  nameIsRequired: '名称是必填项。',
  quality: '质量',
  imageDimensionsHoverTitle: '保存的图像尺寸（宽 x 高）',
  cropSizeLowerThanResizedWarning: '注意，所选的裁剪区域低于应用的调整大小，可能会导致质量降低。',
  actualSize: '实际大小（100%）',
  fitSize: '适应大小',
  addImageTitle: '选择要添加的图像...',
  mutualizedFailedToLoadImg: '加载图像失败。',
  tabsMenu: '菜单',
  download: '下载',
  width: '宽度',
  height: '高度',
  plus: '+',
  cropItemNoEffect: '此裁剪项无预览效果'
};
