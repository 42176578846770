import InfiniteScroll from 'react-infinite-scroller'
import Masonry from 'react-masonry-css'
import { Button, Spin, Toast } from '@douyinfe/semi-ui';
import { useRef, useState } from 'react';
import http from '../../lib/http'
import * as apis from '../../lib/api'
import * as tool from '../../lib/tool'
import axios from 'axios'

const Index = (props) => {
    const { data = [], height = 0, loadMore, loading = false, total = 0, showDownload = false, banner = <></>,isVip=false} = props
    let scrollParentRef = useRef()
    const [innerLoading, setLoading] = useState(false)

    const downLoadFile = async (item) => {
        setLoading(true)
        try {
            let data = await http.get(`${apis.DESIGN_DETAIL}?id=${item.id}`)
            let resource = await axios.get(process.env.REACT_APP_OSS_URL+data.path)
            let md5s = []
            for(let code in resource.data.itemsAttr){
                let item = resource.data.itemsAttr[code]
                if(item.md5){
                    md5s.push(item.md5)
                }
            }
            let ret = await http(`${apis.MODEL_DETAIL}?id=${item.model_id}`)
            let a = document.createElement("a")
            a.href = process.env.REACT_APP_OSS_URL + ret.design_file_path 
            a.click()
            await tool.sleep(300)
            if(ret.items){
                ret.items = JSON.parse(ret.items)
                for(let i=0;i<ret.items.length;i++){
                    let item = ret.items[i]
                    for(let j=0;j<item.items.length;j++){
                        let f = md5s.find(m => m === item.items[j].md5)
                        if(f){
                            let a = document.createElement("a")
                            a.href = process.env.REACT_APP_OSS_URL + item.items[j].design_file_path
                            a.click()
                            await tool.sleep(300)
                        }
                        
                    }
                }
            }
        } catch (error) {
            Toast.error("下载失败")
        }
        setLoading(false)
    }

    return (
        <div className='hideScroll' style={{ height, overflow: 'auto' }} ref={scrollParentRef}>
            <InfiniteScroll
                // initialLoad={false} // 不让它进入直接加载
                // pageStart={1} // 设置初始化请求的页数
                loadMore={() => {
                    if(!loading){
                        loadMore()
                    }
                }}
                useWindow={false}
                hasMore={(total !== data.length)}
                getScrollParent={() => scrollParentRef.current}
                loader={
                    <div style={{textAlign: "center"}} key={0}>加载中...</div>
                }
            >
                {banner}
                {/* 瀑布流 */}
                <Masonry
                    breakpointCols={{
                        default: 5,
                        1000: 4,
                        // 1100: 3,
                        700: 2,
                        500: 1
                    }}
                    className='my-masonry-grid'
                    columnClassName='my-masonry-grid_column'
                >
                    {
                        data.map(item => (
                            <div key={item.id} style={{borderRadius: 25, border: 'none',width:"100%",marginBottom: 30,overflow:"hidden"}}>
                                <img
                                    onClick={()=>props.onItemClieck(item)}  
                                    src={process.env.REACT_APP_OSS_URL + item.cover+"?x-oss-process=image/resize,w_500"}
                                    style={{width: "100%"}}
                                />
                                <div style={{background: isVip?"#282735":"#ffffff",color:isVip?"rgb(109, 106, 120)":"#000000",padding:"10px",wordWrap:"break-word"}}>{item.describe}</div>
                                {
                                    showDownload && <Button loading={innerLoading} onClick={()=>{
                                        downLoadFile(item)
                                    }} style={{width: "100%", fontSize: 12, color:isVip?"#6D6A78": "#111111",background:isVip?'#282735':''}}>下载源文件</Button>
                                }
                            </div>
                            // <Card
                            //     style={{borderRadius: 30, border: 'none'}}
                            //     key={item.id}
                            //     cover={ 
                            //         <img  
                            //             src={process.env.REACT_APP_OSS_URL + item.cover}
                            //         />
                            //     }
                            // >
                            //     {/* <Card.Meta title={item.name} /> */}
                            // </Card>
                        ))
                    }
                </Masonry>
            </InfiniteScroll>
        </div>
    )
}

export default Index