import {
  Input,
  Tabs,
  TabPane,
  Notification,
  Button,
  Spin,
  Modal,
  Form,
  Banner,
  Toast,
} from '@douyinfe/semi-ui';
import { IconUpload, IconSearch, IconCrossStroked } from '@douyinfe/semi-icons';
import Animate from '../../../components/animate';
import C from '../context';
import {
  useContext,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { TYPES } from '../left_menu';
import { EDIT_MODE } from '../defined';
import { cloneDeep } from 'lodash';
import * as apis from '../../../lib/api';
import http from '../../../lib/http';
import Tab from '../../../components/tab';
import Upload from '../../../components/upload';
import FabricParam from '../../../components/fabric_param';
import * as tool from '../../../lib/tool';

let dataLoaded = false;

const cacheData = {
  category: [],
  pubCategory: [],
  categoryTab: '2',
  curCategory: 0,
  data: { list: [] },
  search: { page: 1, page_size: 20, status: 1, category_id: 0, isPub: '1' },
};

const Index = (props) => {
  const ic = useContext(C);
  const [category, setCategory] = useState(cacheData.category);
  const [selfCategory, setSelfCategory] = useState([]);
  const [secondCategory, setSecondCategory] = useState([]);
  const [threeCategory, setThreeCategory] = useState([]);
  const [categoryTab, setCategoryTab] = useState(cacheData.categoryTab);
  const [curCategory, setCurCategory] = useState(0);
  const [curSecconCategory, setCurSecconCategory] = useState(0);
  const [curThreeCategory, setCurThreeCategory] = useState(0);
  const [data, setData] = useState(cacheData.data);
  const [search, setSearch] = useState(cacheData.search);
  const [loading, setLoading] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const categoryDiv = useRef();
  const formApiRef = useRef();

  const categoryChange = async (v, level = 'category_id') => {
    let cpSearch = cloneDeep(search);
    cpSearch.page = 1;
    cpSearch[level] = v;
    if (level === 'category_id') {
      cpSearch.second_category_id = 0;
      cpSearch.three_category_id = 0;
      setCurSecconCategory(0);
      setCurThreeCategory(0);
      setThreeCategory([]);
      http
        .post(apis.SCENE_CATEGORY_LIST, {
          status: 1,
          super_id: v,
          level: 2,
          is_pub: parseInt(categoryTab),
        })
        .then((r) => {
          setSecondCategory(r);
        });
    }
    if (level === 'second_category_id') {
      cpSearch.three_category_id = 0;
      setCurThreeCategory(0);
      http
        .post(apis.SCENE_CATEGORY_LIST, {
          status: 1,
          super_id: v,
          level: 3,
          is_pub: parseInt(categoryTab),
        })
        .then((r) => {
          setThreeCategory(r);
        });
    }
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    await loadData(cacheData.search, true);
    if (level === 'category_id') {
      setCurCategory(v);
    }
    if (level === 'second_category_id') {
      setCurSecconCategory(v);
    }
    if (level === 'three_category_id') {
      setCurThreeCategory(v);
    }
    setTimeout(() => {
      ic.setUpdate(new Date().getTime());
    }, 100);
  };

  const loader = async (attr) => {
    if (attr.cover) {
      //ic.threeClient.removeShadow()
      await ic.threeClient.setSceneBg(
        process.env.REACT_APP_OSS_URL + attr.cover
      );
    } else {
      ic.threeClient.clearSceneBg();
      //添加阴影
      //ic.threeClient.addShadow(ic.threeClient.modelObject.position.y)
    }
    // ic.threeClient.setCameraParam(attr.cameraFov, attr.cameraZoom)
    // ic.threeClient.setSceneBackgroundBlurriness(attr.backgroundBlurriness)
  };

  useImperativeHandle(props.action, () => ({
    loader,
    fullData,
  }));

  //初始化场景数据
  const fullData = (bgAttr = {}) => {
    const obj = {
      cover: bgAttr.cover || '',
      // backgroundBlurriness: bgAttr.backgroundBlurriness || 0,
      // cameraFov: bgAttr.cameraFov || 45,
      // cameraZoom: bgAttr.cameraZoom || 1.5,
    };
    return obj;
  };

  const choiceFabric = async (v) => {
    if (!v.cover) {
      let bgAttr = cloneDeep(ic?.bgAttr);
      bgAttr.cover = '';
      ic.setBgAttr(bgAttr);
      loader(bgAttr);
      return;
    }
    ic.setLoading(true);
    ic.setLoadingTxt('背景加载中...');
    try {
      await ic.threeClient.setSceneBg(process.env.REACT_APP_OSS_URL + v.cover);
      let bgAttr = cloneDeep(ic?.bgAttr);
      bgAttr.cover = v.cover;
      ic.setBgAttr(bgAttr);
      loader(bgAttr);
    } catch (error) {
      Toast.error('背景加载失败');
    }
    ic.setLoading(false);
    // if(ic.curCode){
    //     ic.setLoading(true)
    //     ic.setLoadingTxt("图案加载中...")
    //     console.log(ic.fclients[ic.curCode])
    //     await ic.fclients[ic.curCode].addImageById(process.env.REACT_APP_OSS_URL+v.path, 512)
    //     ic.setLoading(false)
    // }else{
    //     Toast.info("请先在右侧选择一个部位")
    // }
  };

  const loadData = async (s, refresh = false) => {
    setLoading(true);
    try {
      const d = await http.post(apis.SCENE_LIST, s);
      if (refresh) {
        setData(d);
        cacheData.data = d;
      } else {
        let cpData = cloneDeep(data);
        cpData.list = [...cpData.list, ...d.list];
        setData(cpData);
        cacheData.data = cpData;
      }
    } catch (error) {}
    setLoading(false);
  };

  const tabChange = async (v) => {
    setCurCategory(-1);
    setCurSecconCategory(0);
    setCurThreeCategory(0);
    setSecondCategory([]);
    setThreeCategory([]);
    setCategoryTab(v);
    const s = {
      page: 1,
      page_size: 20,
      status: 1,
      category_id: 0,
      second_category_id: 0,
      three_category_id: 0,
      name: search.name,
      is_pub: parseInt(v),
    };
    setSearch(s);
    cacheData.search = s;
    await loadData(cacheData.search, true);
    //获取分类
    const cdata = await http.post(apis.SCENE_CATEGORY_LIST, {
      status: 1,
      is_pub: parseInt(v),
    });
    setCategory(tool.handleLevelData(cdata));
    setTimeout(() => {
      ic.setUpdate(new Date().getTime());
    }, 100);
  };

  const onInputSearch = async (val) => {
    let cpSearch = cloneDeep(search);
    cpSearch.page = 1;
    cpSearch.name = val;
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    await loadData(cacheData.search, true);
  };

  const loadMore = async () => {
    let cpSearch = cloneDeep(search);
    cpSearch.page++;
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    await loadData(cacheData.search, false);
  };

  useEffect(() => {
    ic.setUpdate(new Date().getTime());
    if (ic.curMenu === TYPES.BAG) {
      dataLoaded = true;
      // http.post(apis.SCENE_PUB_CATEGORY_LIST,{page: 1,page_size: 100,status: 1}).then(r => {
      //     r?.list.unshift({id: 0, name: "全部"})
      //     setPubCategory(r?.list)
      // })
      tabChange('1');
      http
        .post(apis.SCENE_CATEGORY_LIST, { status: 1, is_pub: 2 })
        .then((cdata) => {
          setSelfCategory(tool.handleLevelData(cdata));
        });
    }
  }, [ic.curMenu]);

  const handleOk = async () => {
    setLoading(true);
    try {
      const data = await formApiRef.current.validate();
      await http.post(apis.SCENE_CREATE, {
        name: data.name,
        // path: data.path,
        cover: data.cover,
        tags: data?.tags?.join(),
        category_id: data.category_id[0] ? data.category_id[0] : 0,
        second_category_id: data.category_id[1] ? data.category_id[1] : 0,
        three_category_id: data.category_id[2] ? data.category_id[2] : 0,
      });
      setUploadVisible(false);
      Toast.success('新建成功');
      tabChange('2');
      choiceFabric({ cover: data.cover });
    } catch (error) {
      //console.log(error)
    }
    setLoading(false);
  };

  const handleCancel = async () => {
    setUploadVisible(false);
  };

  const readyUpload = () => {
    setUploadVisible(true);
  };

  const categoryDivHeight = () => {
    setInterval(() => {
      console.log(categoryDiv?.current?.clientHeight);
    }, 100);
  };

  return (
    <Animate
      showType="flex"
      show={ic.curMenu === TYPES.BAG}
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'fixed',
        width: 300,
        left: 70,
        top: 60,
        bottom: 0,
      }}
    >
      <Modal
        title="背景上传"
        visible={uploadVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeOnEsc={true}
      >
        <div
          className="hideScroll"
          style={{ maxHeight: '50vh', overflow: 'auto' }}
        >
          <Banner
            type="warning"
            description="上传的背景都将默认存入私库！！！"
          />
          <Form
            getFormApi={(formApi) => (formApiRef.current = formApi)}
            autoComplete="off"
            style={{ width: '100%' }}
          >
            {({ formState, values, formApi }) => (
              <>
                <Form.Input
                  field="name"
                  label="名称"
                  style={{ width: '100%' }}
                  placeholder="请输入"
                />
                <Form.Cascader
                  field="category_id"
                  label="所属分类"
                  showClear
                  style={{ width: '100%' }}
                  treeData={selfCategory}
                  placeholder="请选择"
                  rules={[{ required: true, message: '请选择' }]}
                />
                <Form.Select
                  label="标签"
                  placeholder="请输入"
                  field="tags"
                  style={{ width: '100%' }}
                  optionList={[]}
                  allowCreate={true}
                  multiple={true}
                  filter={true}
                />
                <Upload
                  showCrop
                  field="cover"
                  label="封面"
                  rules={[{ required: true, message: '请上传' }]}
                />
                {/* <Upload
                                    size={ 1024*30}
                                    accept=".hdr"
                                    field='path'
                                    label='hdr文件'
                                    rules={[
                                        { required: true, message: '请上传' }
                                    ]}
                                /> */}
              </>
            )}
          </Form>
        </div>
      </Modal>
      <div style={{ height: '96%', overflow: 'hidden' }}>
        <Spin spinning={loading}>
          <div
            style={{
              color: '#333333',
              fontSize: 14,
              padding: '0px 8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>背景库</div>
          </div>
          <div
            style={{
              padding: '12px 8px',
            }}
          >
            <div>
              <Input
                onChange={onInputSearch}
                placeholder="搜索"
                suffix={<IconSearch />}
                showClear
                className={props.isVip?'semi-always-dark':''} 
                style={
                  {
                    background:props.isVip?'#282735':'',
                   color:props.isVip? "#6D6A78":'',
                  }
                }
              ></Input>
            </div>
            <div ref={categoryDiv}>
              <Tabs type="line" value={categoryTab} onChange={tabChange}>
                <TabPane tab="公库" itemKey="1">
                  <div style={{ height: 12 }}></div>
                  <div style={{ fontSize: 12 }}>一级分类</div>
                  <div style={{ height: 12 }}></div>
                  <Tab
                    isVip={props.isVip}
                    data={[{ id: -1, name: '全部', super_id: 0 }, ...category]}
                    value={curCategory}
                    onChange={(v) => categoryChange(v, 'category_id')}
                  />
                  {secondCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>二级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...secondCategory,
                        ]}
                        value={curSecconCategory}
                        onChange={(v) =>
                          categoryChange(v, 'second_category_id')
                        }
                      />
                    </>
                  )}
                  {threeCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>三级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...threeCategory,
                        ]}
                        value={curThreeCategory}
                        onChange={(v) => categoryChange(v, 'three_category_id')}
                      />
                    </>
                  )}
                </TabPane>
                <TabPane tab="私库" itemKey="2">
                  <div style={{ height: 12 }}></div>
                  <div style={{ fontSize: 12 }}>一级分类</div>
                  <div style={{ height: 12 }}></div>
                  <Tab
                    isVip={props.isVip}
                    data={[{ id: -1, name: '全部', super_id: 0 }, ...category]}
                    value={curCategory}
                    onChange={(v) => categoryChange(v, 'category_id')}
                  />
                  {secondCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>二级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...secondCategory,
                        ]}
                        value={curSecconCategory}
                        onChange={(v) =>
                          categoryChange(v, 'second_category_id')
                        }
                      />
                    </>
                  )}
                  {threeCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>三级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...threeCategory,
                        ]}
                        value={curThreeCategory}
                        onChange={(v) => categoryChange(v, 'three_category_id')}
                      />
                    </>
                  )}
                </TabPane>
              </Tabs>
            </div>
            <div>
              <div
                className="hideScroll"
                style={{
                  height:
                    document.body.clientHeight -
                    (categoryDiv?.current?.clientHeight || 0) -
                    document.body.clientHeight * 0.04 -
                    162,
                  overflow: 'auto',
                  position: 'relative',
                }}
              >
                <div
                  onClick={() => {
                    choiceFabric({ cover: '' });
                  }}
                  style={{
                    width: '96%',
                    margin: '0 auto',
                    display: 'block',
                    height: 124,
                    borderRadius: 5,
                    marginBottom: 5,
                    textAlign: 'center',
                    lineHeight: '124px',
                    fontSize: 50,
                    cursor: 'pointer',
                  }}
                >
                  默认
                </div>
                {!data.list || (data.list && data.list.length === 0) ? (
                  <div
                    style={{ textAlign: 'center', paddingTop: 5, fontSize: 12 }}
                  >
                    暂无背景~
                  </div>
                ) : (
                  <>
                    {data?.list?.map((v, k) => (
                      <div
                        key={v.id}
                        style={{
                          cursor: 'pointer',
                          marginBottom: 5,
                          // float: "left",
                          // width: 62
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          choiceFabric(v);
                        }}
                      >
                        <img
                          style={{
                            width: '96%',
                            margin: '0 auto',
                            display: 'block',
                            // height: 124,
                            borderRadius: 5,
                          }}
                          src={
                            process.env.REACT_APP_OSS_URL +
                            v.cover +
                            '?x-oss-process=image/resize,w_300'
                          }
                        />
                      </div>
                    ))}
                    {data.list.length === data.total ? (
                      <div
                        style={{
                          textAlign: 'center',
                          paddingTop: 5,
                          fontSize: 12,
                        }}
                      >
                        没有更多了~
                      </div>
                    ) : (
                      <div style={{ textAlign: 'center', paddingTop: 5 }}>
                        <Button
                          theme="borderless"
                          loading={loading}
                          onClick={loadMore}
                          style={{ fontSize: 12 }}
                        >
                          点击加载更多
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
              <>
                <div style={{ height: 8 }}></div>
                <Button
                  onClick={readyUpload}
                  style={{
                    // position: "absolute",
                    width: '100%',
                    fontSize: 12,
                    background: props.isVip ? '#282735' : '',
                  }}
                >
                  上传背景
                </Button>
              </>
            </div>
          </div>
        </Spin>
      </div>
    </Animate>
  );
};

export default Index;
