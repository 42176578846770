import { Slider,  withField, Input, Button, Form, Card, Space, Anchor } from '@douyinfe/semi-ui';
import { useState } from 'react'
import { cloneDeep } from 'lodash'
import { IconCrossStroked } from '@douyinfe/semi-icons';
import {Index as Upload} from '../../components/upload'

// {
//     "cutparts": [
// 		{
// 			"img": "files/3289a4e3b2dd2908083d160c9c8c8cf9.png",
// 			"txt": "后片",
// 			"code": "transform2",
// 			"price": 20,
// 			"camera": {
// 				"x": 0,
// 				"y": 0,
// 				"z": 227.89005432128903
// 			}
// 		}
//     ]
// }

const Index = (props) => {
    const {value = [], curCode, getPositon, setPositon,threeClient, ...rest} = props
    return (
        <Form.Slot
            {...rest}
        >
            {
                value.map((v,k) => (
                    <div key={v.code}>
                        <div id={`print${v.code}`} style={{height: 12}}></div>
                        <Card title={<div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>序号-{k+1}</div>
                            <div style={{textAlign: 'right'}}>
                                <Button style={{height: 20, width: 20}} onClick={() => {
                                    let cpV = cloneDeep(value)
                                    cpV.splice(k,1)
                                    props.onChange(cpV)
                                }} icon={<IconCrossStroked style={{fontSize: 12}} />}></Button>
                            </div>
                        </div>} style={{borderColor: v.code === curCode && "red"}}>
                            <a href={`print${v.code}`}></a>
                            
                            <Form.Slot label="版片名称">
                                <Input 
                                    style={{ width: '100%' }} 
                                    placeholder='请输入'
                                    maxLength={5}
                                    value={v.txt}
                                    onChange={(item) => {
                                        let cpV = cloneDeep(value)
                                        cpV[k].txt = item
                                        props.onChange(cpV)
                                    }}
                                />
                            </Form.Slot>
                            <Form.Slot label="印刷价">
                                <Input 
                                    min={0}
                                    style={{ width: '100%' }} 
                                    placeholder='请输入'
                                    type="number"
                                    suffix={"元"}
                                    value={v.price}
                                    onChange={(item) => {
                                        let cpV = cloneDeep(value)
                                        cpV[k].price = item
                                        props.onChange(cpV)
                                    }}
                                />
                            </Form.Slot>
                            <Upload
                                label='1:1版片图'
                                value={v.img}
                                onChange={(item) => {
                                    let cpV = cloneDeep(value)
                                    cpV[k].img = item
                                    props.onChange(cpV)
                                }}
                            />
                            <Space>
                                <Button onClick={() => {
                                    let cpV = cloneDeep(value)
                                    cpV[k].position = threeClient.getModelPosition()
                                    props.onChange(cpV)
                                }}>快照</Button>
                                {
                                    v.position && <Button onClick={() => {
                                        threeClient.setModelPosition(v.position)
                                    }}>复位</Button>
                                }
                            </Space>
                        </Card>
                    </div>
                ))
            }
        </Form.Slot>
    )
}

export default withField(Index, { valueKey: 'value', onKeyChangeFnName: 'onChange' })