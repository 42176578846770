import { Button, Toast, Form, Input, Modal, Typography } from "@douyinfe/semi-ui"
import { useImperativeHandle, useState, useRef } from 'react';
// import Upload from '../../components/upload'
import * as apis from '../../../lib/api'
import http from '../../../lib/http'

const Index = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [detailData, setDetailData] = useState({})
    const [loading, setLoading] = useState(false)

    const formApiRef = useRef()

    const openModal = (record) => {
      console.log('record', record)
      setDetailData(record)
      setIsModalOpen(true)
    }

    const closeModal = () => {
      setLoading(false)
      setDetailData({})
      setIsModalOpen(false)
    }

    useImperativeHandle(props.funcs, () => ({
        openModal: (record) => openModal(record),
        closeModal
    }));

    const submit = async () => {
      const data = await formApiRef.current.validate()
      setLoading(true)
      try {
        await http.post(apis.fashionShowApplyApi,{
          design_id: detailData.id,
          // model: 1, 
          apply_context: data.apply_context, 
        })
        closeModal()
        props.onSuccess && props.onSuccess();
        Toast.success("提交成功! 等待审批中...")
      } finally {
        setLoading(false)
      }
    }


    return (
      <>
        <Modal 
          visible={isModalOpen} 
          onCancel={() => {
            if(loading) return 
            setIsModalOpen(false)
          }}
          title='申请走秀动画'
          footer={
            <>
              <Button loading={loading} onClick={() => setIsModalOpen(false)}>取消</Button>
              <Button loading={loading} type="primary" onClick={submit}>确定</Button>
            </>
          }
        >
          <Form
            getFormApi={(formApi) => (formApiRef.current = formApi)}
            autoComplete="off"
            style={{ width: '100%' }}
          >
            {({ formState, values, formApi }) => (
              <>
                <Form.Input
                  field="name"
                  label="名字"
                  initValue={detailData.name}
                  disabled
                  // style={{ width: '100%' }}
                  placeholder="请输入"
                />
                <Form.TextArea
                  field="apply_context"
                  label="申请备注"
                  style={{ width: '100%' }}
                  placeholder="请输入"
                  rules={[{ required: true, message: '请填写走秀动画备注信息！' }]}
                />
              </>
            )}
          </Form>
        </Modal>
      </>
    )
}

export default Index