import { BrowserRouter, Route, Routes } from 'react-router-dom';
import OnlyDesign from '../pages/design/show_only';
import Design from '../pages/design';
import H5design from '../pages/design/show_h5';
import Login from '../pages/login';
import AutoLogin from '../pages/auto_login';
import PubDesign from '../pages/pub_design';
import VipDesign from '../pages/vip_design';
import Xieyi from '../pages/xieyi';
import KeepAlive, {
  useActivate,
  useUnactivate,
  useAliveController,
} from 'react-activation';
import { AliveScope } from 'react-activation';

const R = () => {
  return (
    <AliveScope>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PubDesign />} />
          <Route path="/vip/design" element={<VipDesign />} />
          <Route path="/design" element={<Design />} />
          <Route path="/onlydesign" element={<OnlyDesign />} />
          <Route path="/h5design" element={<H5design />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/auto" element={<AutoLogin />} />
          <Route path="/xieyi" element={<Xieyi />} />
        </Routes>
      </BrowserRouter>
    </AliveScope>
  );
};

export default R;
