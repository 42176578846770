import styles from './index.module.css';
import {
  Input,
  Empty,
  Divider,
  InputNumber,
  Button,
  Slider,
  Switch,
} from '@douyinfe/semi-ui';
import { IconCrossStroked, IconTick } from '@douyinfe/semi-icons';
import {
  IllustrationNoContent,
  IllustrationNoContentDark,
} from '@douyinfe/semi-illustrations';
import Animate from '../../../components/animate';
import C from '../context';
import { useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { TYPES } from '../left_menu';
import Codes from '../codes';
import { cloneDeep } from 'lodash';
import { SketchPicker } from 'react-color';
import * as cache from '../../../lib/cache';
import { EDIT_MODE } from '../defined';
import md5 from 'md5';

let firstLoad = false;
const offsetNum = 26;

const Index = (props) => {
  const ic = useContext(C);
  const attrDiv = useRef();

  const setDisplacementScale = (v) => {
    if (!ic.curCode) {
      const fabricAttr = cloneDeep(ic.fabricAttr || {});
      let cs = ic?.meshs;
      for (let i = 0; i < cs.length; i++) {
        let item = cs[i];
        fabricAttr[item].displacementScale = v;
        ic.threeClient.setDisplacementMapAttr(
          item,
          v,
          ic?.fabricAttr[item]?.displacementBias
        );
      }
      fabricAttr[''].displacementScale = v;
      ic.setFabricAttr(fabricAttr);
    } else {
      ic.threeClient.setDisplacementMapAttr(
        ic.curCode,
        v,
        ic?.fabricAttr[ic.curCode]?.displacementBias
      );
      setFabricAttr('displacementScale', v);
    }
  };

  const setDisplacementBias = (v) => {
    if (!ic.curCode) {
      const fabricAttr = cloneDeep(ic.fabricAttr || {});
      let cs = ic?.meshs;
      for (let i = 0; i < cs.length; i++) {
        let item = cs[i];
        ic.threeClient.setDisplacementMapAttr(
          item,
          ic?.fabricAttr[item]?.displacementScale,
          v
        );
        fabricAttr[item].displacementBias = v;
      }
      fabricAttr[''].displacementBias = v;
      ic.setFabricAttr(fabricAttr);
    } else {
      ic.threeClient.setDisplacementMapAttr(
        ic.curCode,
        ic?.fabricAttr[ic.curCode]?.displacementScale,
        v
      );
      setFabricAttr('displacementBias', v);
    }
  };

  const setMapOffsetX = (v) => {
    if (!ic.curCode) {
      const fabricAttr = cloneDeep(ic.fabricAttr || {});
      let cs = ic?.meshs;
      for (let i = 0; i < cs.length; i++) {
        let item = cs;
        fabricAttr[item].offsetX = v;
        ic.threeClient.setMapOffset(
          item,
          v,
          ic?.fabricAttr[item]?.offsetY || 0
        );
      }
      fabricAttr[''].offsetX = v;
      ic.setFabricAttr(fabricAttr);
    } else {
      ic.threeClient.setMapOffset(
        ic.curCode,
        v,
        ic?.fabricAttr[ic.curCode]?.offsetY || 0
      );
      setFabricAttr('offsetX', v);
    }
  };

  const setMapOffsetY = (v) => {
    if (!ic.curCode) {
      const fabricAttr = cloneDeep(ic.fabricAttr || {});
      let cs = ic?.meshs;
      for (let i = 0; i < cs.length; i++) {
        let item = cs[i];
        ic.threeClient.setMapOffset(
          item,
          ic?.fabricAttr[item]?.offsetX || 0,
          v
        );
        fabricAttr[item].offsetY = v;
      }
      fabricAttr[''].offsetY = v;
      ic.setFabricAttr(fabricAttr);
    } else {
      ic.threeClient.setMapOffset(
        ic.curCode,
        ic?.fabricAttr[ic.curCode]?.offsetX || 0,
        v
      );
      setFabricAttr('offsetY', v);
    }
  };

  const setFabricAttr = (key, v) => {
    const fabricAttr = cloneDeep(ic.fabricAttr || {});
    if (!fabricAttr[ic.curCode]) {
      fabricAttr[ic.curCode] = {};
    }
    fabricAttr[ic.curCode][key] = v;
    ic.setFabricAttr(fabricAttr);
  };

  const setMapRepeat = (v) => {
    if (!ic.curCode) {
      const fabricAttr = cloneDeep(ic.fabricAttr || {});
      let cs = ic?.meshs;
      for (let i = 0; i < cs.length; i++) {
        let item = cs[i];
        ic.threeClient.setMapRepeat(item, v + offsetNum, v + offsetNum);
        fabricAttr[item].repeatNum = v + offsetNum;
        fabricAttr[item].repeatNumY = v + offsetNum;
      }
      fabricAttr[''].repeatNum = v + offsetNum;
      ic.setFabricAttr(fabricAttr);
    } else {
      ic.threeClient.setMapRepeat(ic.curCode, v + offsetNum, v + offsetNum);
      setFabricAttr('repeatNum', v + offsetNum);
    }
  };

  const setMapRepeatY = (v) => {
    if (!ic.curCode) {
      const fabricAttr = cloneDeep(ic.fabricAttr || {});
      let cs = ic?.meshs;
      for (let i = 0; i < cs.length; i++) {
        let item = cs[i];
        ic.threeClient.setMapRepeat(item, ic?.fabricAttr[item]?.repeatNum, v);
        fabricAttr[item].repeatNumY = v;
      }
      fabricAttr[''].repeatNumY = v;
      ic.setFabricAttr(fabricAttr);
    } else {
      ic.threeClient.setMapRepeat(
        ic.curCode,
        ic?.fabricAttr[ic.curCode]?.repeatNum,
        v
      );
      setFabricAttr('repeatNumY', v);
    }
  };

  const setColor = (v) => {
    if (!ic.curCode) {
      const fabricAttr = cloneDeep(ic.fabricAttr || {});
      let cs = ic?.meshs;
      for (let i = 0; i < cs.length; i++) {
        let item = cs[i];
        ic.threeClient.setMaterialColor(item, v.hex);
        fabricAttr[item].color = v.hex;
      }
      fabricAttr[''].color = v.hex;
      ic.setFabricAttr(fabricAttr);
    } else {
      ic.threeClient.setMaterialColor(ic.curCode, v.hex);
      setFabricAttr('color', v.hex);
    }
  };

  const editDisabled = () => {
    if (ic.threeClient && ic.threeClient.hasMap) {
      return !ic?.threeClient?.hasMap(ic.curCode);
    }
    return true;
  };

  const setRepeat = (v) => {
    if (!ic.curCode) {
      const fabricAttr = cloneDeep(ic.fabricAttr || {});
      let cs = ic?.meshs;
      for (let i = 0; i < cs.length; i++) {
        let item = cs[i];
        ic.threeClient.openRepeat(item, v);
        fabricAttr[item].repeat = v;
      }
      fabricAttr[''].repeat = v;
      ic.setFabricAttr(fabricAttr);
    } else {
      ic.threeClient.openRepeat(ic.curCode, v);
      setFabricAttr('repeat', v);
    }
  };

  const txtShow = () => {
    if (ic.curMenu === TYPES.AFABRIC) {
      return '辅料';
    }
    if (ic.curMenu === TYPES.FINISHED) {
      return '成品面料';
    }
    return '自定义面料';
  };

  const setTransparent = (v) => {
    if (!ic.curCode) {
      const fabricAttr = cloneDeep(ic.fabricAttr || {});
      let cs = ic?.meshs;
      for (let i = 0; i < cs.length; i++) {
        let item = cs[i];
        ic.threeClient.setMaterialTransparent(item, v);
        fabricAttr[item].transparent = v;
      }
      fabricAttr[''].transparent = v;
      ic.setFabricAttr(fabricAttr);
    } else {
      ic.threeClient.setMaterialTransparent(ic.curCode, v);
      setFabricAttr('transparent', v);
    }
  };

  const confirm = () => {
    let attrData = cloneDeep(cache.get(cache.ATTR_DATA));
    if (
      md5(JSON.stringify(attrData.fabric)) !==
      md5(JSON.stringify(ic.fabricAttr))
    ) {
      attrData.fabric = ic.fabricAttr;
      ic.setSteps(attrData);
    }
    ic.onMenuContentClose(TYPES.FABRIC);
  };

  useImperativeHandle(props.action, () => ({
    confirm,
  }));

  const cancel = async (e) => {
    e.preventDefault();
    let attrData = cache.get(cache.ATTR_DATA);
    if (
      md5(JSON.stringify(attrData.fabric)) !==
      md5(JSON.stringify(ic.fabricAttr))
    ) {
      ic.setLoading(true);
      ic.setLoadingTxt('加载中...');
      //回退面料数据
      ic.setFabricAttr(attrData.fabric);
      await ic.fabricLoader(attrData.fabric);
      ic.setLoading(false);
    }
    ic.onMenuContentClose(TYPES.FABRIC);
  };

  return (
    <Animate
      showType="flex"
      show={
        ic.curMenu === TYPES.FABRIC ||
        ic.curMenu === TYPES.AFABRIC ||
        ic.curMenu === TYPES.FINISHED
      }
      style={{
        position: 'fixed',
        width: 213,
        right: 50,
        top: 60,
        bottom: 0,
        margin: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{ height: '96%', overflow: 'hidden' }}>
        <div
          style={{
            color: '#333333',
            fontSize: 14,
            padding: '5px 8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            style={{ height: 20, width: 20 }}
            onClick={cancel}
            icon={<IconCrossStroked style={{ fontSize: 12 }} />}
          ></Button>
          <div>编辑{txtShow()}</div>
          <Button
            style={{ height: 20, width: 20 }}
            onClick={confirm}
            icon={<IconTick style={{ fontSize: 12 }} />}
          ></Button>
        </div>
        <div
          style={{
            padding: '12px 8px',
            overflow: 'auto',
            // height: "calc(50% - 26px - 60px)"
          }}
        >
          <div
            style={{ display: ic.curMenu === TYPES.AFABRIC ? 'none' : 'block' }}
          >
            <span>部位选择</span>
            <Divider margin="12px" />
            <div
              className="hideScroll"
              ref={attrDiv}
              style={{ maxHeight: 200, overflow: 'auto' }}
            >
              <Codes isVip={props.isVip} />
            </div>
            <Divider margin="12px" align="center">
              编辑
            </Divider>
          </div>
          <div
            className="hideScroll"
            style={{
              height:
                document.body.clientHeight -
                (attrDiv?.current?.clientHeight || 0) -
                document.body.clientHeight * 0.04 -
                190,
              overflow: 'auto',
            }}
          >
            {!editDisabled() ? (
              <>
                <div>
                  <div style={{ fontSize: 12 }}>平铺模式：</div>
                  <div style={{ padding: '4px 13px' }}>
                    <Switch
                      style={{
                        background: props.isVip ? ic?.fabricAttr[ic.curCode]?.repeat?'#282735':'#A89B9B' : '',
                      }}
                      checked={ic?.fabricAttr[ic.curCode]?.repeat}
                      onChange={setRepeat}
                    />
                  </div>
                </div>
                <div>
                  <div style={{ fontSize: 12 }}>透明模式：</div>
                  <div style={{ padding: '4px 13px' }}>
                    <Switch
                  style={{
                    background: props.isVip ? ic?.fabricAttr[ic.curCode]?.transparent?'#282735':'#A89B9B' : '',
                  }}
                      checked={ic?.fabricAttr[ic.curCode]?.transparent}
                      onChange={setTransparent}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: ic?.fabricAttr[ic.curCode]?.repeat
                      ? 'block'
                      : 'none',
                  }}
                >
                  <div style={{ fontSize: 12 }}>缩放：</div>
                  <div>
                    <Slider
                      className={props.isVip ? styles.vipSlider : ''}
                      marks={{
                        0: '0',
                        10: '10',
                        20: '20',
                        30: '30',
                        '-10': '-10',
                        '-20': '-20',
                        '-30': '-30',
                      }}
                      value={ic?.fabricAttr[ic.curCode]?.repeatNum - offsetNum}
                      min={-30}
                      max={30}
                      onChange={setMapRepeat}
                    />
                  </div>
                </div>
                <div style={{ display: 'none' }}>
                  <div style={{ fontSize: 12 }}>Y轴缩放：</div>
                  <div>
                    <Slider
                      className={props.isVip ? styles.vipSlider : ''}
                      value={ic?.fabricAttr[ic.curCode]?.repeatNumY}
                      min={-30}
                      max={30}
                      onChange={setMapRepeatY}
                    />
                  </div>
                </div>
                <div style={{ display: 'none' }}>
                  <div style={{ fontSize: 12 }}>X轴平移：</div>
                  <div>
                    <Slider
                      className={props.isVip ? styles.vipSlider : ''}
                      value={ic?.fabricAttr[ic.curCode]?.offsetX}
                      step={0.1}
                      min={0}
                      max={1}
                      onChange={setMapOffsetX}
                    />
                  </div>
                </div>
                <div style={{ display: 'none' }}>
                  <div style={{ fontSize: 12 }}>Y轴平移：</div>
                  <div>
                    <Slider
                      className={props.isVip ? styles.vipSlider : ''}
                      value={ic?.fabricAttr[ic.curCode]?.offsetY}
                      step={0.1}
                      min={0}
                      max={1}
                      onChange={setMapOffsetY}
                    />
                  </div>
                </div>
                <div style={{ display: 'none' }}>
                  <div style={{ fontSize: 12 }}>置换贴图的影响程度：</div>
                  <div>
                    <Slider
                      className={props.isVip ? styles.vipSlider : ''}
                      value={ic?.fabricAttr[ic.curCode]?.displacementScale}
                      step={0.000001}
                      min={0}
                      max={10}
                      onChange={setDisplacementScale}
                    />
                    <InputNumber
                      onChange={(v) => setDisplacementScale(v)}
                      style={{ width: 150, paddingLeft: 13 }}
                      step={0.000001}
                      value={ic?.fabricAttr[ic.curCode]?.displacementScale}
                      min={0}
                      max={10}
                    />
                  </div>
                </div>
                <div style={{ marginTop: 12, display: 'none' }}>
                  <div style={{ fontSize: 12 }}>置换贴图的顶点偏移量：</div>
                  <div>
                    <Slider
                      className={props.isVip ? styles.vipSlider : ''}
                      value={ic?.fabricAttr[ic.curCode]?.displacementBias}
                      step={0.000001}
                      min={0}
                      max={10}
                      onChange={setDisplacementBias}
                    />
                    <InputNumber
                      onChange={(v) => setDisplacementBias(v)}
                      style={{ width: 150, paddingLeft: 13 }}
                      step={0.000001}
                      value={ic?.fabricAttr[ic.curCode]?.displacementBias}
                      min={0}
                      max={10}
                    />
                  </div>
                </div>
                <div style={{ marginTop: 12 }}>
                  <div style={{ fontSize: 12 }}>颜色：</div>
                  <div style={{ padding: '4px 13px' }}>
                    <SketchPicker
                      disableAlpha
                      color={ic?.fabricAttr[ic.curCode]?.color}
                      style={{ background: 'none', width: 'auto' }}
                      onChange={setColor}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{ textAlign: 'center', fontSize: 12, paddingTop: 20 }}
              >
                先在左侧添加{txtShow()}
              </div>
            )}
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default Index;
