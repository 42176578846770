import React, { useEffect, useRef, useState } from 'react';
import Top from './top';
import Fabric from './fabric';
// import PatternAttr from './pattern/index_attr';
import LightAttr from './light/index_attr';
// import PrintAttr from './print/index_attr';
// import Pattern from './pattern';
// import Bg from './bg';
// import Part from './part';
// import BgAttr from './bg/index_attr';
import Client3D from '../../lib/three';
import Context from './context';
import { Spin, Toast } from '@douyinfe/semi-ui';
import * as tool from '../../lib/tool';
import ClientFabric from '../../lib/fabric';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

// const tempFs = {};

const Index = () => {
  const l = useLocation();
  const params = new URLSearchParams(l.search);
  const fabrciAction = useRef();
  // const canvasAction = useRef();
  // const printAction = useRef();
  const bgAction = useRef();
  // const leftMenuAction = useRef();
  // const bgAttrAction = useRef();
  // const canvasAttrAction = useRef();
  const topAction = useRef();
  const partAction = useRef();
  const lightAction = useRef();
  // const [curEditMode, setCurEditMode] = useState(0);
  const [threeClient, setThreeClient] = useState({});
  const [fabricClient, setFabricClient] = useState({});
  const [action3D, setAction3D] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState('加载中');
  const [update, setUpdate] = useState(new Date().getTime());
  const [fabricAttr, setFabricAttr] = useState({});
  const [canvasAttr, setCanvasAttr] = useState({});
  const [lightAttr, setLightAttr] = useState({});
  const [bgAttr, setBgAttr] = useState({});
  const [itemsAttr, setItemsAttr] = useState({});
  const [meshs] = useState([]);
  const [walkVideoUrl, setWalkVideoUrl] = useState('');

  const canvasRef = useRef();
  useEffect(() => {
    const lightType = params.get('light_type')
    const pathJson = params.get('path_json') || 'files/1f00658799c24b054f84481ec39cdee9.json'
    const modelUrl = params.get('model_url')
    const videoUrl = params.get('video_url')
    setWalkVideoUrl(videoUrl ? process.env.REACT_APP_OSS_URL + videoUrl : '')
    if (!pathJson) {
      Toast.error('缺少有效的模型数据！');
      return; 
    }
  
    const fabricClient = new ClientFabric();
    setFabricClient(fabricClient);

    setLoadingTxt('加载中');
    setLoading(true);

    let client;
    //如果有设计文件，优先使用设计文件模型
    let resource = {};
    const fetchData = async () => {
      if (pathJson) {
        try {
          resource = await axios.get(
            process.env.REACT_APP_OSS_URL + pathJson
          );
          resource = resource.data;
        } catch (error) {
          Toast.error('加载资源文件出错');
          return;
        }
        //初始化3DClient
        client = new Client3D({
          canvasDom: canvasRef?.current,
          canvasWidth: document.body.clientWidth,
          canvasHeight: document.body.clientHeight,
          light_type: lightType,
        });
        setThreeClient(client);
        client.start();
        client.loadModel(
          process.env.REACT_APP_OSS_URL + (modelUrl ? modelUrl : resource.model_url),
          () => {},
          '',
          ''
        ).then(async (action3D) => {
          client.camera.position.z = 320
          client.cachePosition.cz = 320
          setAction3D(action3D);
          // 初始化所有fabrci句柄和3 D句柄
          let codes = [];
          for (let i = 0; i < client?.modelObject?.children?.length; i++) {
            codes.push(client?.modelObject?.children[i].name);
          }
          
          
          setLoadingTxt('加载设计中');
          //清洗fabricAttr
          let fr = fabrciAction.current?.fullData(
            resource?.fabricAttr || {},
            codes
          );
          setFabricAttr(fr);
          await fabrciAction.current?.loader(fr);

          setCanvasAttr({
            canvas_width: 512,
            canvas_data: [],
          })
          setUpdate(new Date());
          //添加阴影
          client.addShadow(client.modelObject.position.y);
          //加载背景数据
          let bgFullData = bgAction.current?.fullData(resource?.bgAttr || {});
          setBgAttr(bgFullData);
          await bgAction.current?.loader(bgFullData);
          //加载部件数据
          let partFullData = partAction.current?.fullData(
            resource?.itemsAttr || {},
            codes
          );
          setItemsAttr(partFullData);
          await partAction.current?.loader(partFullData);
          //加载灯光数据
          const lightInitAttrData = lightAction.current.fullData({ lightType })
          setLightAttr(lightInitAttrData)
          await lightAction.current.loader({ lightType })
          setLoading(false);
        })
      }
    }
    fetchData()
    return () => {
      client?.destroy();
      fabricClient?.destroy();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Context.Provider
      value={{
        // curEditMode,
        // setCurEditMode,
        threeClient,
        action3D,
        setLoadingTxt,
        setLoading,
        // setCurCode,
        // curCode,
        fabricAttr,
        setFabricAttr,
        setCanvasAttr,
        canvasAttr,
        update,
        setUpdate,
        // fclients: tempFs,
        // // controPosition,
        // // curActivityObject,
        fabricLoader: fabrciAction?.current?.loader,
        // canvasLoader: canvasAction?.current?.loader,
        // bgLoader: bgAction?.current?.loader,
        // partLoader: partAction?.current?.loader,
        // slideData: topAction?.current?.slideData,
        meshs,
        fabricClient,
        bgAttr,
        setBgAttr,
        // canvasAttrApi: canvasAttrAction.current,
        // bgAttrApi: bgAttrAction.current,
        // partAttrApi: partAction.current,
        // leftMenuApi: leftMenuAction.current,
        // params,
        // // designData,
        // // resourceData,
        itemsAttr,
        setItemsAttr,
        lightAttr,
        setLightAttr,
        walkVideoUrl,
      }}
    >
      <Spin spinning={loading} tip={loadingTxt}>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
            fontWeight: 500,
          }}
        >
          <div
            style={{
              display: tool.isFullScreen() ? 'block' : 'none',
              position: 'fixed',
              width: 50,
              height: 50,
              background: '#cccccc',
              borderRadius: '50%',
              textAlign: 'center',
              lineHeight: '50px',
              color: '#FFFFFF',
              left: 0,
              right: 0,
              margin: 'auto',
              top: 50,
            }}
            onClick={() => tool.exitFullscreen()}
          >
            退出
          </div>
          <span style={{ display: tool.isFullScreen() ? 'none' : 'flex' }}>
            <Top action={topAction} display={'none'} showH5 />
            {/* <Bg action={bgAction} /> */}
            {/* <Part action={partAction} /> */}
            {/* <BgAttr action={bgAttrAction} /> */}
            {/* <Pattern action={canvasAction} /> */}
            {/* <PatternAttr action={canvasAttrAction} /> */}
            <Fabric action={fabrciAction} display={'none'} />
            <LightAttr action={lightAction} />
            {/* <PrintAttr action={printAction} /> */}
          </span>
          <canvas className="displayAnimate" ref={canvasRef}></canvas>
        </div>
      </Spin>
    </Context.Provider>
  );
};

export default Index;
