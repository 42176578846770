var cache = {}

export const set = (k,v) => {
    cache[k] = v
}

export const get = (k) => {
    return cache[k]
}

// export const FABRIC_ATTR_DATA = "FABRIC_ATTR_DATA"
// export const PATTERN_ATTR_DATA = "PATTERN_ATTR_DATA"
export const ATTR_DATA = "ATTR_DATA"

export const STEPS = "STEPS"
export const STEP_NUM  = "STEP_NUM"

export const getStepNum = () => {
    let stepNum = get(STEP_NUM)
    if(stepNum === undefined){
        return -1
    }
    return stepNum
}

export const clearAllCache = () => {
    cache = {}
}