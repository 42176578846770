import styles from './index.module.css';
import C from '../context';
import { useContext, useImperativeHandle } from 'react';

export const TYPES = {
  FABRIC: 1,
  PATTERN: 2,
  DRAW: 3,
  BAG: 4,
  PART: 5,
  AFABRIC: 6,
  LIGHT: 7,
  PRINT: 8,
  MODEL: 9,
  FINISHED: 10,
};

const MENUS = [
  {
    icon: '&#xe630;',
    name: '成品面料',
    key: TYPES.FINISHED,
  },
  {
    icon: '&#xe630;',
    name: '自定义面料',
    key: TYPES.FABRIC,
  },
  {
    icon: '&#xe651;',
    name: '辅料',
    key: TYPES.AFABRIC,
  },
  {
    icon: '&#xe6e9;',
    name: '定位花',
    key: TYPES.PATTERN,
  },
  {
    icon: '&#xe6e1;',
    name: '涂鸦',
    key: TYPES.DRAW,
  },
  {
    icon: '&#xe609;',
    name: '背景',
    key: TYPES.BAG,
  },
  {
    icon: '&#xe627;',
    name: '部件',
    key: TYPES.PART,
  },
];

const Index = (props) => {
  const ic = useContext(C);

  const getFontSize = (v) => {
    if (v.key === TYPES.FABRIC) {
      return 19;
    }
    if (v.key === TYPES.PART) {
      return 38;
    }
    return 22;
  };

  const choiceMenu = (e, v) => {
    e.preventDefault();
    if (ic?.curCode) {
      ic.fclients[ic?.curCode]?.discardActiveObject();
    }

    if (ic.curMenu === v.key) {
      return;
    }
    if (ic.curMenu && v.key !== '') {
      // Toast.info("请先完成当前编辑")
      if (
        ic.curMenu === TYPES.FABRIC ||
        ic.curMenu === TYPES.AFABRIC ||
        ic.curMenu === TYPES.FINISHED
      ) {
        ic?.fabrciAttrApi?.confirm();
      }
      if (ic.curMenu === TYPES.PATTERN || ic.curMenu === TYPES.DRAW) {
        ic?.canvasAttrApi?.confirm();
      }
      if (ic.curMenu === TYPES.BAG) {
        ic.bgAttrApi.confirm();
      }
      if (ic.curMenu === TYPES.PART) {
        ic.partAttrApi.confirm();
      }
      // return
    }
    if (v.key === TYPES.DRAW) {
      //开启绘画模式
      for (let code in ic.fclients) {
        // ic.fclients[code].setPencilBrush(5)
        let fclient = ic.fclients[code];
        fclient[ic?.curBrush](
          ic?.brushSize,
          `rgba(${ic?.curColor.r},${ic?.curColor.g},${ic?.curColor.b},${ic?.curColor.a})`
        );
      }
    } else {
      for (let code in ic.fclients) {
        ic?.fclients[code]?.cancelBrush();
      }
    }
    ic.setCurMenu(v.key);
  };

  useImperativeHandle(props.action, () => ({
    choiceMenu,
  }));

  return (
    <div className={styles.box} style={{ display: props?.display === 'none' ? 'none' : 'block', }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <div>
          {MENUS.map((v) => (
            <div
              key={v.key}
              style={{
                color: ic.curMenu === v.key ? props.isVip ? '#7600ea' : '#0064fa' : '',
                display:
                  ic?.userInfo?.role === 5 && v.name == '部件'
                    ? 'none'
                    : 'block',
              }}
              className={styles.boxItem}
              onClick={(e) => choiceMenu(e, v)}
            >
              <div
                style={{ marginBottom: v.key === TYPES.PART && -3 }}
                className={styles.boxItemIconBox}
              >
                <i
                  style={{ fontSize: getFontSize(v) }}
                  className="iconfont"
                  dangerouslySetInnerHTML={{ __html: v.icon }}
                ></i>
              </div>
              <div className={styles.boxItemTxt}>{v.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
