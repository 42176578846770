import {
  Input,
  Tabs,
  TabPane,
  Notification,
  Button,
  Spin,
  Modal,
  Form,
  Banner,
  Toast,
} from '@douyinfe/semi-ui';
import { IconUpload, IconSearch, IconTick } from '@douyinfe/semi-icons';
import Animate from '../../../components/animate';
import C from '../context';
import {
  useContext,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { TYPES } from '../left_menu';
import { EDIT_MODE } from '../defined';
import { cloneDeep } from 'lodash';
import * as apis from '../../../lib/api';
import http from '../../../lib/http';
import Tab from '../../../components/tab';
import Upload from '../../../components/upload';
import FabricParam from '../../../components/fabric_param';
import * as tool from '../../../lib/tool';
import InfiniteScroll from 'react-infinite-scroller';

let dataLoaded = false;

const cacheData = {
  category: [],
  pubCategory: [],
  categoryTab: '2',
  curCategory: 0,
  data: { list: [] },
  search: { page: 1, page_size: 20, status: 1, category_id: 0, isPub: '1' },
};

const Index = (props) => {
  const ic = useContext(C);
  const [category, setCategory] = useState(cacheData.category);
  const [selfCategory, setSelfCategory] = useState([]);
  const [secondCategory, setSecondCategory] = useState([]);
  const [threeCategory, setThreeCategory] = useState([]);
  const [categoryTab, setCategoryTab] = useState(cacheData.categoryTab);
  const [curCategory, setCurCategory] = useState(0);
  const [curSecconCategory, setCurSecconCategory] = useState(0);
  const [curThreeCategory, setCurThreeCategory] = useState(0);
  const [data, setData] = useState(cacheData.data);
  const [search, setSearch] = useState(cacheData.search);
  const [loading, setLoading] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const categoryDiv = useRef();
  const formApiRef = useRef();
  const scrollParentRef = useRef();

  const categoryChange = async (v, level = 'category_id') => {
    if (scrollParentRef.current) {
      scrollParentRef.current.scrollTop = 0;
    }
    let cpSearch = cloneDeep(search);
    cpSearch.page = 1;
    cpSearch[level] = v;
    if (level === 'category_id') {
      cpSearch.second_category_id = 0;
      cpSearch.three_category_id = 0;
      setCurSecconCategory(0);
      setCurThreeCategory(0);
      setThreeCategory([]);
      http
        .post(apis.PATTERN_CATEGORY_LIST, {
          status: 1,
          super_id: v,
          level: 2,
          is_pub: parseInt(categoryTab),
        })
        .then((r) => {
          setSecondCategory(r);
        });
    }
    if (level === 'second_category_id') {
      cpSearch.three_category_id = 0;
      setCurThreeCategory(0);
      http
        .post(apis.PATTERN_CATEGORY_LIST, {
          status: 1,
          super_id: v,
          level: 3,
          is_pub: parseInt(categoryTab),
        })
        .then((r) => {
          setThreeCategory(r);
        });
    }
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    await loadData(cacheData.search, true);
    if (level === 'category_id') {
      setCurCategory(v);
    }
    if (level === 'second_category_id') {
      setCurSecconCategory(v);
    }
    if (level === 'three_category_id') {
      setCurThreeCategory(v);
    }
    setTimeout(() => {
      ic.setUpdate(new Date().getTime());
    }, 100);
  };

  const loader = async (attr, fs = ic.fclients) => {
    ic.fabricClient.clearAll();
    for (let i = 0; i < attr.canvas_data.length; i++) {
      let item = attr.canvas_data[i];
      fs[item.code]?.loadDataFromJson(item.data);
    }
  };

  useImperativeHandle(props.action, () => ({
    loader,
    fullData,
  }));

  //初始化面料数据
  const fullData = (fabricAttr = {}) => {
    let cs = ic?.threeClient?.modelObject?.children;
    const obj = {};
    for (let i = 0; i < cs.length; i++) {
      let item = cs[i];
      obj[item.name] = {
        offsetX: fabricAttr[item.name]?.offsetX || 0,
        offsetY: fabricAttr[item.name]?.offsetY || 0,
        repeat: fabricAttr[item.name]?.repeat,
        transparent: fabricAttr[item.name]?.transparent || false,
        repeatNum: fabricAttr[item.name]?.repeatNum || 10,
        repeatNumY: fabricAttr[item.name]?.repeatNumY || 10,
        color: fabricAttr[item.name]?.color || '#FFFFFF',
        displacementScale: fabricAttr[item.name]?.displacementScale || 0,
        displacementBias: fabricAttr[item.name]?.displacementBias || 0,
        maps: fabricAttr[item.name]?.maps || [],
      };
      obj[''] = {
        offsetX: fabricAttr[item.name]?.offsetX || 0,
        offsetY: fabricAttr[item.name]?.offsetY || 0,
        repeat: fabricAttr[item.name]?.repeat,
        transparent: fabricAttr[item.name]?.transparent || false,
        repeatNum: fabricAttr[item.name]?.repeatNum || 10,
        repeatNumY: fabricAttr[item.name]?.repeatNumY || 10,
        displacementScale: fabricAttr[item.name]?.displacementScale || 0,
        displacementBias: fabricAttr[item.name]?.displacementBias || 0,
        color: fabricAttr[item.name]?.color || '#FFFFFF',
      };
      if (
        obj[item.name].repeat === undefined ||
        obj[item.name].repeat === true
      ) {
        obj[item.name].repeat = true;
      } else {
        obj[item.name].repeat = false;
      }
      if (obj[''].repeat === undefined || obj[''].repeat === true) {
        obj[''].repeat = true;
      } else {
        obj[''].repeat = false;
      }
    }
    return obj;
  };

  const choiceFabric = async (v) => {
    if (ic.curCode) {
      ic.setLoading(true);
      ic.setLoadingTxt('定位花加载中...');
      console.log('choiceFabric fclients', ic.fclients);
      console.log('curCode', ic.curCode);
      if (!ic.fclients[ic.curCode]) {
        Toast.info('数据异常，请刷新重试');
        return
      }
      await ic.fclients[ic.curCode]?.addImageById(
        process.env.REACT_APP_OSS_URL + v.path,
        256
      );
      ic.setLoading(false);
    } else {
      Toast.info('请选择一个部位');
    }
  };

  const loadData = async (s, refresh = false) => {
    setLoading(true);
    try {
      const d = await http.post(apis.PATTERN_LIST, s);
      if (refresh) {
        setData(d);
        cacheData.data = d;
      } else {
        let cpData = cloneDeep(data);
        cpData.list = [...cpData.list, ...d.list];
        setData(cpData);
        cacheData.data = cpData;
      }
    } catch (error) {}
    setLoading(false);
  };

  const tabChange = async (v) => {
    if (scrollParentRef.current) {
      scrollParentRef.current.scrollTop = 0;
    }
    setCurCategory(-1);
    setCurSecconCategory(0);
    setCurThreeCategory(0);
    setSecondCategory([]);
    setThreeCategory([]);
    setCategoryTab(v);
    const s = {
      page: 1,
      page_size: 20,
      status: 1,
      category_id: 0,
      second_category_id: 0,
      three_category_id: 0,
      name: search.name,
      is_pub: parseInt(v),
    };
    setSearch(s);
    cacheData.search = s;
    await loadData(cacheData.search, true);
    //获取分类
    const cdata = await http.post(apis.PATTERN_CATEGORY_LIST, {
      status: 1,
      is_pub: parseInt(v),
    });
    setCategory(tool.handleLevelData(cdata));
    setTimeout(() => {
      ic.setUpdate(new Date().getTime());
    }, 100);
  };

  const onInputSearch = async (val) => {
    let cpSearch = cloneDeep(search);
    cpSearch.page = 1;
    cpSearch.name = val;
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    await loadData(cacheData.search, true);
  };

  const loadMore = async () => {
    let cpSearch = cloneDeep(search);
    cpSearch.page++;
    setSearch(cpSearch);
    cacheData.search = cpSearch;
    await loadData(cacheData.search, false);
  };

  useEffect(() => {
    ic.setUpdate(new Date().getTime());
    if (ic.curMenu === TYPES.PATTERN) {
      dataLoaded = true;
      // http.post(apis.PATTERN_PUB_CATEGORY_LIST,{page: 1,page_size: 100,status: 1}).then(r => {
      //     r?.list.unshift({id: 0, name: "全部"})
      //     setPubCategory(r?.list)
      // })
      tabChange('1');
      http
        .post(apis.PATTERN_CATEGORY_LIST, { status: 1, is_pub: 2 })
        .then((cdata) => {
          setSelfCategory(tool.handleLevelData(cdata));
        });
    }
  }, [ic.curMenu]);

  const handleOk = async () => {
    setLoading(true);
    try {
      const data = await formApiRef.current.validate();
      await http.post(apis.PATTERN_CREATE, {
        name: data.name,
        path: data.path,
        tags: data?.tags?.join(),
        category_id: data.category_id[0] ? data.category_id[0] : 0,
        second_category_id: data.category_id[1] ? data.category_id[1] : 0,
        three_category_id: data.category_id[2] ? data.category_id[2] : 0,
      });
      setUploadVisible(false);
      Toast.success('新建成功');
      tabChange('2');
      choiceFabric({ path: data.path });
    } catch (error) {
      //console.log(error)
    }
    setLoading(false);
  };

  const handleCancel = async () => {
    setUploadVisible(false);
  };

  const readyUpload = () => {
    setUploadVisible(true);
  };

  const categoryDivHeight = () => {
    setInterval(() => {
      console.log(categoryDiv?.current?.clientHeight);
    }, 100);
  };

  return (
    <Animate
      showType="flex"
      show={ic.curMenu === TYPES.PATTERN}
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'fixed',
        width: 300,
        left: 70,
        top: 60,
        bottom: 0,
      }}
    >
      <Modal
        title="定位花上传"
        visible={uploadVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeOnEsc={true}
      >
        <div
          className="hideScroll"
          style={{ maxHeight: '50vh', overflow: 'auto' }}
        >
          <Banner
            type="warning"
            description="上传的定位花都将默认存入私库！！！"
          />
          <Form
            getFormApi={(formApi) => (formApiRef.current = formApi)}
            autoComplete="off"
            style={{ width: '100%' }}
          >
            {({ formState, values, formApi }) => (
              <>
                <Form.Input
                  field="name"
                  label="定位花名称"
                  style={{ width: '100%' }}
                  placeholder="请输入"
                />
                <Form.Cascader
                  field="category_id"
                  label="所属分类"
                  showClear
                  style={{ width: '100%' }}
                  treeData={selfCategory}
                  placeholder="请选择"
                  rules={[{ required: true, message: '请选择' }]}
                />
                <Form.Select
                  label="标签"
                  placeholder="请输入"
                  field="tags"
                  style={{ width: '100%' }}
                  optionList={[]}
                  allowCreate={true}
                  multiple={true}
                  filter={true}
                />
                <Upload
                  field="path"
                  label="定位花"
                  rules={[{ required: true, message: '请上传' }]}
                />
              </>
            )}
          </Form>
        </div>
      </Modal>
      <div style={{ height: '96%', overflow: 'hidden' }}>
        <Spin spinning={loading}>
          <div
            style={{
              color: '#333333',
              fontSize: 14,
              padding: '0px 8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>定位花库</div>
          </div>
          <div
            style={{
              padding: '12px 8px',
            }}
          >
            <div>
              <Input
                onChange={onInputSearch}
                placeholder="搜索"
                suffix={<IconSearch />}
                showClear
                className={props.isVip?'semi-always-dark':''} 
                style={
                  {
                    background:props.isVip?'#282735':'',
                   color:props.isVip? "#6D6A78":'',
                  }
                }
              ></Input>
            </div>
            <div ref={categoryDiv}>
              <Tabs type="line" value={categoryTab} onChange={tabChange}>
                <TabPane tab="公库" itemKey="1">
                  <div style={{ height: 12 }}></div>
                  <div style={{ fontSize: 12 }}>一级分类</div>
                  <div style={{ height: 12 }}></div>
                  <Tab
                    isVip={props.isVip}
                    data={[{ id: -1, name: '全部', super_id: 0 }, ...category]}
                    value={curCategory}
                    onChange={(v) => categoryChange(v, 'category_id')}
                  />
                  {secondCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>二级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...secondCategory,
                        ]}
                        value={curSecconCategory}
                        onChange={(v) =>
                          categoryChange(v, 'second_category_id')
                        }
                      />
                    </>
                  )}
                  {threeCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>三级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...threeCategory,
                        ]}
                        value={curThreeCategory}
                        onChange={(v) => categoryChange(v, 'three_category_id')}
                      />
                    </>
                  )}
                </TabPane>
                <TabPane tab="私库" itemKey="2">
                  <div style={{ height: 12 }}></div>
                  <div style={{ fontSize: 12 }}>一级分类</div>
                  <div style={{ height: 12 }}></div>
                  <Tab
                    isVip={props.isVip}
                    data={[{ id: -1, name: '全部', super_id: 0 }, ...category]}
                    value={curCategory}
                    onChange={(v) => categoryChange(v, 'category_id')}
                  />
                  {secondCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>二级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...secondCategory,
                        ]}
                        value={curSecconCategory}
                        onChange={(v) =>
                          categoryChange(v, 'second_category_id')
                        }
                      />
                    </>
                  )}
                  {threeCategory.length !== 0 && (
                    <>
                      <div style={{ fontSize: 12 }}>三级分类</div>
                      <div style={{ height: 12 }}></div>
                      <Tab
                        isVip={props.isVip}
                        data={[
                          { id: 0, name: '全部', super_id: 0 },
                          ...threeCategory,
                        ]}
                        value={curThreeCategory}
                        onChange={(v) => categoryChange(v, 'three_category_id')}
                      />
                    </>
                  )}
                </TabPane>
              </Tabs>
            </div>
            <div>
              <div
                className="hideScroll"
                style={{
                  height:
                    document.body.clientHeight -
                    (categoryDiv?.current?.clientHeight || 0) -
                    document.body.clientHeight * 0.04 -
                    162,
                  overflow: 'auto',
                  position: 'relative',
                }}
              >
                {!data.list || (data.list && data.list.length === 0) ? (
                  <div
                    style={{ textAlign: 'center', paddingTop: 5, fontSize: 12 }}
                  >
                    暂无定位花~
                  </div>
                ) : (
                  <InfiniteScroll
                    // initialLoad={false} // 不让它进入直接加载
                    // pageStart={1} // 设置初始化请求的页数
                    loadMore={() => {
                      if (!loading) {
                        loadMore();
                      }
                    }}
                    useWindow={false}
                    hasMore={data?.total !== data?.list?.length}
                    getScrollParent={() => scrollParentRef.current}
                    loader={
                      <div style={{ textAlign: 'center' }} key={0}>
                        加载中...
                      </div>
                    }
                  >
                    {data?.list?.map((v, k) => (
                      <div
                        key={v.id}
                        style={{
                          cursor: 'pointer',
                          display: 'inline-block',
                          marginRight: (k + 1) % 4 === 0 ? 0 : 5,
                          marginBottom: 5,
                          // float: "left",
                          // width: 62
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          choiceFabric(v);
                        }}
                      >
                        <img
                          style={{
                            width: 65,
                            height: 65,
                            border: 'solid 1px rgba(255,255,255,0.8)',
                            borderRadius: 5,
                          }}
                          src={
                            process.env.REACT_APP_OSS_URL +
                            v.path +
                            '?x-oss-process=image/resize,w_100'
                          }
                        />
                      </div>
                    ))}
                    {data.list.length === data.total && (
                      <div
                        style={{
                          textAlign: 'center',
                          paddingTop: 5,
                          fontSize: 12,
                        }}
                      >
                        没有更多了~
                      </div>
                    )}
                  </InfiniteScroll>
                )}
              </div>
              <>
                <div style={{ height: 8 }}></div>
                <Button
                  onClick={readyUpload}
                  style={{
                    // position: "absolute",
                    width: '100%',
                    bottom: -30,
                    left: 0,
                    fontSize: 12,
                    background: props.isVip ? '#282735' : '',
                  }}
                >
                  上传定位花
                </Button>
              </>
            </div>
          </div>
        </Spin>
      </div>
    </Animate>
  );
};

export default Index;
